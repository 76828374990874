import { Component, OnInit } from '@angular/core';
import { DialogRef } from '@ngneat/dialog';

interface AnnouncementItem {
  namespace: string;
  imageUrl: string;
  endDate?: Date;
}

@Component({
  selector: 'app-new-feature-announcement',
  templateUrl: './new-feature-announcement.component.html',
  styleUrls: ['./new-feature-announcement.component.scss']
})
export class NewFeatureAnnouncementComponent implements OnInit {
  data: AnnouncementItem;

  constructor(public dialogRef: DialogRef) {
    this.data = dialogRef.data;
  }

  ngOnInit(): void {}
}
