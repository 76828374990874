import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { ProfileFacade } from '@app/core/root-store/profile-store/profile.facade';
import { ScopeFacade } from '@app/core/root-store/scope-store/scope.facade';
import { ScopeService } from '@app/core/services/scope.service';
import { IScope } from '@app/shared/models/common/scope.model';
import { Observable, of } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { CommonRoutes } from '../constants/routes/common-routes.constant';

@Injectable({
  providedIn: 'root'
})
export class CustomerGuard implements CanActivate {
  constructor(
    private profileFacade: ProfileFacade,
    private scopeFacade: ScopeFacade,
    private scopeService: ScopeService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.profileFacade.getProfileLoaded$.pipe(
      filter((isProfileLoaded: boolean) => isProfileLoaded),
      switchMap(() => this.scopeService.localScopeId$),
      switchMap(() => this.scopeFacade.scopeStoreLoaded$),
      filter((isScopeLoaded: boolean) => isScopeLoaded),
      switchMap(() => this.scopeFacade.getScope$),
      switchMap((scope: IScope) => {
        if (!!route.params.scopeId && route.params.scopeId !== scope.id) {
          this.scopeFacade.getScope(route.params.scopeId);
          return this.scopeFacade.scopeStoreLoaded$.pipe(
            filter((isScopeLoaded: boolean) => isScopeLoaded),
            take(1)
          );
        }
        return of(true);
      }),
      catchError(() => {
        if (!route.params.scopeId) {
          this.router.navigate([CommonRoutes.ABSOLUTE_SCOPE]);
          return of(false);
        } else {
          this.scopeFacade.getScope(route.params.scopeId);
          return this.scopeFacade.scopeStoreLoaded$.pipe(
            filter((isScopeLoaded: boolean) => isScopeLoaded),
            take(1)
          );
        }
      }),
      take(1)
    );
  }
}
