import { NgModule } from '@angular/core';

import { BreadcrumbComponent } from './breadcrumb.component';
import { BreadcrumbItemComponent } from './breadcrumb-item.component';

@NgModule({
  imports: [],
  exports: [BreadcrumbComponent, BreadcrumbItemComponent],
  declarations: [BreadcrumbComponent, BreadcrumbItemComponent],
  providers: []
})
export class BreadcrumbModule {}
