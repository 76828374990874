import { Injectable } from '@angular/core';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { IScope } from '@app/shared/models/common/scope.model';
import { map } from 'rxjs/operators';
import { Customer } from '@app/shared/models/customer/customer.model';
import { ContactService } from '@app/core/services/contact.service';
import { Utils } from '@app/shared/utils/utils';

@Injectable({
  providedIn: 'root',
})
export class ScopeService {
  private readonly scopeIdFlag = 'scopeId';
  private readonly _apiUrl = environment.api + '/customers';

  constructor(private http: HttpClient, private contactService: ContactService) {}

  getScope(customerId): Observable<IScope> {
    const customer$ = this.http.get<Customer>(`${this._apiUrl}/${customerId}`);
    const contact$ = this.contactService.getContactRoles(customerId);
    return combineLatest([customer$, contact$]).pipe(
      map(([customer, contact]) => ({
        id: customer.id,
        label: customer.label,
        isActive: customer.isActive,
        hasChildren: customer.hasChildren,
        logoPath: customer.styling ? customer.styling.logoPath : null,
        color: customer.styling ? customer.styling.primaryBrandColor : null,
        invitesConfiguration: customer.invitesConfiguration,
        roles: contact.roles,
        models: customer.models,
        usingNewDomain: customer.usingNewDomain,
      }))
    );
  }

  rememberLocalScope(customerId: string, autoSelected: boolean): void {
    if (this.lastScopeId) {
      this.clearLocalScope();
    }
    localStorage.setItem(this.scopeIdFlag, JSON.stringify({ customerId, autoSelected }));
  }

  clearLocalScope(): void {
    localStorage.removeItem(this.scopeIdFlag);
  }

  get localScopeId$(): Observable<{ customerId: string; autoSelected: boolean }> {
    if (!this.lastScopeId) {
      return throwError('No session ID found');
    }

    return of(this.lastScopeId);
  }

  checkOldScopeRememberImplementation(localStorageData: string): boolean {
    return typeof localStorageData === 'string' && Utils.isUUID(localStorageData);
  }

  get lastScopeId(): { customerId: string; autoSelected: boolean } {
    const scopeData = localStorage.getItem(this.scopeIdFlag);
    try {
      return JSON.parse(scopeData);
    } catch {
      if (this.checkOldScopeRememberImplementation(scopeData)) {
        return { customerId: scopeData, autoSelected: null };
      }
      return null;
    }
  }
}
