import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'ins-card',
  template: ` <ng-content></ng-content> `,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.grid-item]': 'gridItem',
    '[class.hoverable]': 'hoverable',
    '[class.selected]': 'selected',
    '[class.horizontal]': 'horizontal',
    '[class.card-disabled]': 'disabled',
  },
  styleUrls: ['ins-card.component.scss'],
})
export class InsCardComponent {
  @HostBinding('class')
  componentClass = 'ins-card';

  _gridItem: boolean;
  @Input()
  set gridItem(value: any) {
    const newValue = coerceBooleanProperty(value);
    if (newValue) {
      this._gridItem = newValue;
    }
  }
  get gridItem() {
    return this._gridItem;
  }
  _hoverable: boolean;
  @Input()
  set hoverable(value: any) {
    const newValue = coerceBooleanProperty(value);
    if (newValue) {
      this._hoverable = newValue;
    }
  }
  get hoverable() {
    return this._hoverable;
  }

  _disabled: boolean;
  @Input()
  set disabled(value: any) {
    const newValue = coerceBooleanProperty(value);
    if (newValue) {
      this._disabled = newValue;
    }
  }
  get disabled() {
    return this._disabled;
  }

  _horizontal: boolean;
  @Input()
  set horizontal(value: any) {
    const newValue = coerceBooleanProperty(value);
    if (newValue) {
      this._horizontal = newValue;
    }
  }
  get horizontal() {
    return this._horizontal;
  }

  @Input()
  selected: boolean;
}
