import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-logo-upload-preview',
  templateUrl: './logo-upload-preview.component.html',
  styleUrls: ['./logo-upload-preview.component.scss']
})
export class LogoUploadPreviewComponent {
  @Input()
  file: File;
  @Output()
  removeFile = new EventEmitter<void>();
  @Output()
  newFile = new EventEmitter<void>();
}
