import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'progress.component.html'
})
export class ProgressComponent {
  @Input()
  value = 0;
  @Input()
  showDot = true;
}
