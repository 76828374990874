import { NgModule, Provider } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthRedirectComponent } from '@app/core/auth/auth-redirect/auth-redirect.component';
import { CallbackComponent } from '@app/core/auth/auth-callback/callback.component';
import { CoreModule } from '@app/core/core.module';
import { SharedModule } from '@app/shared/shared.module';
import { loggerProviders } from 'utils';
import { AuthModule } from 'ins-auth';
import { environment } from '@env/environment';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from '@app/core/interceptors/token.interceptor';
import { MessageFormatTranspiler } from '@ngneat/transloco-messageformat';
import { MocksInterceptor } from '@app/core/interceptors/mocks.interceptor';
import { TRANSLOCO_TRANSPILER, TranslocoService } from '@ngneat/transloco';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ScopeInterceptor } from '@app/core/interceptors/scope.interceptor';
import { DialogModule } from '@ngneat/dialog';
import { SELECT_REFRESH_LIST_HOOK } from '@app/core/tokens/select-refresh-list-hook.token';
import { Observable } from 'rxjs';
import { ImpersonationInterceptor } from '@app/core/interceptors/impersonation.interceptor';

const interceptors: Provider[] = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ScopeInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ImpersonationInterceptor,
    multi: true,
  },
];

if (environment.mocks) {
  interceptors.push({
    provide: HTTP_INTERCEPTORS,
    useClass: MocksInterceptor,
    multi: true,
  });
}

@NgModule({
  declarations: [AppComponent, AuthRedirectComponent, CallbackComponent],
  imports: [
    CoreModule,
    AuthModule.forRoot({
      auth: environment.auth,
      logs: !environment.production,
    }),
    DialogModule.forRoot({ closeButton: false }),
    SharedModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  bootstrap: [AppComponent],
  providers: [
    loggerProviders,
    ...interceptors,
    {
      provide: TRANSLOCO_TRANSPILER,
      useClass: MessageFormatTranspiler,
    },
    {
      provide: SELECT_REFRESH_LIST_HOOK,
      deps: [TranslocoService],
      useFactory(translocoService: TranslocoService): Observable<string> {
        return translocoService.langChanges$;
      },
    },
  ],
})
export class AppModule {}
