import { isPast } from 'date-fns';
import { CustomerProduct, UserProduct } from '@app/modules/product-management/core/models/customer-product.model';

export abstract class ProductUtils {
  static hasPermissions(correspondingProduct: CustomerProduct | UserProduct, isAdmin = false): boolean {
    if (isAdmin) {
      return true;
    }

    if (correspondingProduct) {
      if ('isDemo' in correspondingProduct && correspondingProduct.isDemo) {
        return !isPast(new Date(correspondingProduct?.expiresAt));
      } else {
        return correspondingProduct.isActive;
      }
    }
  }
}
