import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'app-checkbox',
  template: `
    <span
      class="ins-checkbox"
      [class.ins-checkbox-checked]="checked && !intermediate"
      [class.ins-checkbox-intermediate]="intermediate"
      [class.disabled]="disabled"
    >
      <input
        class="ins-checkbox-input"
        type="checkbox"
        (ngModelChange)="checkedChange($event)"
        [ngModel]="checked"
        [checked]="checked"
        (click)="$event.stopPropagation()"
        [disabled]="disabled"
      />
      <span class="ins-checkbox-inner"></span>
    </span>
    <span>
      <ng-content></ng-content>
    </span>
  `,
  styleUrls: ['checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true
    }
  ],
  host: {
    '(click)': 'hostClick($event)'
  }
})
export class CheckboxComponent implements ControlValueAccessor {
  @Output()
  readonly onChange = new EventEmitter<boolean>();
  @Input()
  checked: boolean | null = false;
  @Input()
  intermediate: boolean | null = false;

  constructor(private cd: ChangeDetectorRef) {}

  private _disabled: boolean;
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    if (newValue !== this._disabled) {
      this._disabled = newValue;
      this.cd.markForCheck();
    }
  }

  private _noPadding = false;
  @Input()
  get noPadding() {
    return this._noPadding;
  }

  set noPadding(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    if (newValue !== this._noPadding) {
      this._noPadding = newValue;
      this.cd.markForCheck();
    }
  }

  onTouched: () => {};
  _controlValueAccessorChangeFn: (value: any) => void = () => {};

  hostClick(event) {
    event.preventDefault();
    this.checkedChange(!this.checked);
  }

  checkedChange(checked: boolean) {
    if (!this._disabled) {
      this.checked = checked;
      this._controlValueAccessorChangeFn(checked);
      this.onChange.emit(checked);
    }
  }

  registerOnChange(fn: any): void {
    this._controlValueAccessorChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cd.markForCheck();
  }

  writeValue(value: any): void {
    this.checked = value;
    this.cd.markForCheck();
  }
}
