import { NgModule } from '@angular/core';
import * as fromScope from './reducers/scope.reducer';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ScopeEffects } from '@app/core/root-store/scope-store/effects/scope.effects';
import { ScopeFacade } from '@app/core/root-store/scope-store/scope.facade';

@NgModule({
  imports: [
    StoreModule.forFeature(fromScope.scopeFeatureKey, fromScope.reducer),
    EffectsModule.forFeature([ScopeEffects])
  ],
  providers: [ScopeEffects, ScopeFacade],
  exports: []
})
export class ScopeStoreModule {}
