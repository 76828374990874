import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'join'
})
export class JoinPipe implements PipeTransform {
  transform(value: any[], by: string = ' '): string {
    return value ? value.join(by) : null;
  }
}
