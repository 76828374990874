import { DateRange } from '@app/shared/components/datepickerV2/core/interfaces/datepicker.interface';
import { CommonConstants } from '@app/core/constants/common';
import { Ranges } from '@app/shared/components/datepickerV2/core/constants/datepicker.enum';
import { DateConstants } from '@app/core/constants/date';

export class DatepickerConstants {
  static readonly TRANSLATION_PREFIX = 'common.datepicker.';
  static readonly RANGE_PREFIX = this.TRANSLATION_PREFIX + 'range.';
  static readonly DEFAULT_PLACEHOLDER = '';
  static readonly DEFAULT_RANGE_PLACEHOLDER = '-/-';
  static readonly MANUAL_INPUT_PLACEHOLDER = 'D / M / YYYY';
  static readonly MANUAL_INPUT_ID = {
    dateFrom: 'manualDateInput',
    dateTo: 'manualDateInputMax',
  };

  static readonly RANGES: DateRange[] = [
    {
      value: [new Date(CommonConstants.START_DATE_STRING), new Date()],
      label: '',
      key: Ranges.ALL_TIME,
    },
    {
      value: [new Date(), new Date()],
      key: Ranges.TODAY,
      label: '',
    },
    {
      value: [
        new Date(new Date().setDate(new Date().getDate() - 1)),
        new Date(new Date().setDate(new Date().getDate() - 1)),
      ],
      key: Ranges.YESTERDAY,
      label: '',
    },
    {
      value: [
        new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 1)),
        new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 7)),
      ],
      key: Ranges.THIS_WEEK,
      label: '',
    },
    {
      value: [new Date(new Date().setDate(new Date().getDate() - 7)), new Date()],
      key: Ranges.LAST_WEEK,
      label: '',
    },
    {
      value: [
        new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
      ],
      key: Ranges.THIS_MONTH,
      label: '',
    },
    {
      value: [new Date(new Date().setMonth(new Date().getMonth() - 1)), new Date()],
      key: Ranges.LAST_MONTH,
      label: '',
    },
    {
      value: [new Date(new Date().getFullYear(), 0, 1), new Date(new Date().getFullYear(), 11, 31)],
      key: Ranges.THIS_YEAR,
      label: '',
    },
    {
      value: [new Date(new Date().setFullYear(new Date().getFullYear() - 1)), new Date()],
      key: Ranges.LAST_YEAR,
      label: '',
    },
  ];

  static readonly DATEPICKER_COMMON_CONFIG = {
    containerClass: 'theme-blue',
    isAnimated: true,
    adaptivePosition: true,
    selectFromOtherMonth: true,
    customTodayClass: 'border',
  };

  static readonly DATEPICKER_CONFIG = {
    ...this.DATEPICKER_COMMON_CONFIG,
    showWeekNumbers: false,
    dateInputFormat: 'MMM D, Y',
  };

  static readonly RANGEPICKER_CONFIG = {
    ...this.DATEPICKER_COMMON_CONFIG,
    displayMonths: 1,
    selectWeekDateRange: true,
    rangeInputFormat: DateConstants.SHORT_DATE,
    rangeSeparator: ' / ',
    ranges: this.RANGES,
  };
}
