import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';

export interface ReadFile {
  fileName: string;
  file: string;
}

@Injectable({
  providedIn: 'root'
})
export class FileReaderService {
  readFiles(files: File[]): Observable<ReadFile[]> {
    return forkJoin(files.map(file => this.readFile(file)));
  }

  readFile(file: File): Observable<ReadFile> {
    return new Observable<ReadFile>(obs => {
      if (!(file instanceof Blob)) {
        obs.error(new Error('`blob` must be an instance of File or Blob.'));
        return;
      }

      const reader = new FileReader();

      reader.onerror = err => obs.error(err);
      reader.onabort = err => obs.error(err);
      reader.onload = () =>
        obs.next({
          file: reader.result as string,
          fileName: file.name
        });
      reader.onloadend = () => obs.complete();

      return reader.readAsDataURL(file);
    });
  }
}
