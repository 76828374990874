import { inject, InjectionToken } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';
import { Utils } from '@app/shared/utils/utils';

export const SCOPE_ID = new InjectionToken<Observable<string>>('Get scope id from URI', {
  providedIn: 'root',
  factory: () => scopeIdFactory(inject(Router))
});

function scopeIdFactory(router: Router): Observable<string | null> {
  return router.events.pipe(
    startWith({ url: router.url }),
    filter(event => event instanceof NavigationEnd || !!(event as NavigationEnd)?.url),
    distinctUntilChanged((prev: NavigationEnd, curr: NavigationEnd) => prev.id === curr.id),
    map((event: NavigationEnd) => {
      if (event?.url) {
        const urlArray = event.url.slice(1).split('/');
        const scopeId = urlArray?.[0];

        return Utils.isUUID(scopeId) ? scopeId : null;
      }
      return null;
    })
  );
}
