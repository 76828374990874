import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ProgressModule } from '@app/shared/components/progress/progress.module';
import { DragDropFileUploadDirective } from '@app/shared/directives/drag-drop-file-upload.directive';
import { FileIconPipe } from '@app/shared/modules/file-uploader/core/pipes/file-icon.pipe';
import { FileUploaderComponent } from '@app/shared/modules/file-uploader/file-uploader.component';
import { TranslocoModule } from '@ngneat/transloco';
import { AliasModule } from '@app/shared/directives/alias/alias.module';
import { LoaderModule } from '@app/shared/components/loader/loader.module';
import { BlobPreviewModule } from '@app/shared/components/ins-blob/blob-preview.module';

@NgModule({
  declarations: [FileUploaderComponent, DragDropFileUploadDirective, FileIconPipe],
  imports: [CommonModule, TranslocoModule, ProgressModule, AliasModule, LoaderModule, BlobPreviewModule],
  exports: [FileUploaderComponent],
})
export class FileUploaderModule {}
