import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ExportTypes } from '@app/dashboard/publication-options/core/enums/export-types.enum';
import { ComponentTemplateHostDirective } from '@app/shared/directives/component-template-host.directive';
import { ISurvey } from '@app/shared/models/survey/survey.model';
import { MetadataInterface } from '@app/shared/modules/metadata/interface/metadata.interface';
import { DialogRef } from '@ngneat/dialog';
import { JsonEditorOptions } from 'ang-jsoneditor';
import { IFeedback } from '@app/dashboard/publication-options/models/feedback.interface';
import { catchError, switchMap, take, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, EMPTY, of, throwError } from 'rxjs';
import { HttpStatusCode } from '@angular/common/http';
import { FileReaderService } from '@app/dashboard/customer/core/services/filereader.service';
import { FileUploaderComponent } from '@app/shared/modules/file-uploader/file-uploader.component';
import { ResponseService } from '@app/core/services/response.service';
import { FeedbackCompanyService } from '@app/dashboard/publication-options/core/services/feedback-company.service';
import { ScriptGeneratorComponent } from '@app/shared/components/exporting-modal/script-generator/script-generator.component';
import { ScopeFacade } from '@app/core/root-store/scope-store/scope.facade';
import { ResponsesExcelRequestPayload } from '@app/dashboard/response-detail/core/interfaces/responses-excel-payload.interface';
import { format } from 'date-fns';
import { DateConstants } from '@app/core/constants/date';
import { NotificationService } from '@app/core/toastr/notification.service';
import { TranslocoService } from '@ngneat/transloco';
import { ResponseListService } from '@app/dashboard/responses/core/services/response-list.service';
import { CommonConstants } from '@app/core/constants/common';

@Component({
  selector: 'app-exporting-modal',
  templateUrl: './exporting-modal.component.html',
  styleUrls: ['./exporting-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class ExportingModalComponent implements OnInit {
  readonly EXPORT_TYPES = ExportTypes;

  readonly queryParamGroup = this.responseListService.serviceParamGroup;
  buttonLoading$ = new BehaviorSubject(false);
  formLoading$ = new BehaviorSubject(false);

  @ViewChild('logoUploader')
  uploadComponent: FileUploaderComponent;

  @ViewChild(ComponentTemplateHostDirective, { static: true })
  formContainer: ComponentTemplateHostDirective;
  form: UntypedFormGroup;
  seaForm: UntypedFormGroup;
  titleKey: string;
  descriptionKey: string;
  descriptionNote: string;
  type: ExportTypes;
  surveys: ISurvey[];
  metadata: MetadataInterface[];
  editingFeedback: boolean;
  jsonEditorOptions: JsonEditorOptions;

  scriptGenerator: ScriptGeneratorComponent;
  // TODO: temporary stub
  jsonData = {
    parentTest: [
      {
        childTest: 'childTest',
        listTest: [
          {
            test: 'test',
          },
        ],
      },
    ],
  };

  constructor(
    private feedbackCompanyService: FeedbackCompanyService,
    private dialogRef: DialogRef,
    private fb: UntypedFormBuilder,
    private fileReader: FileReaderService,
    private responseListService: ResponseListService,
    private readonly scopeFacade: ScopeFacade,
    private readonly responsesService: ResponseService,
    private readonly notificationService: NotificationService,
    private readonly translocoService: TranslocoService
  ) {
    this.jsonEditorOptions = new JsonEditorOptions();
    this.jsonEditorOptions.mode = 'code';
    this.jsonEditorOptions.statusBar = false;
    this.jsonEditorOptions.navigationBar = false;
    this.jsonEditorOptions.mainMenuBar = false;
  }

  ngOnInit(): void {
    this.titleKey = this.dialogRef.data.titleKey;
    this.descriptionKey = this.dialogRef.data.descriptionKey;
    this.descriptionNote = this.dialogRef.data.descriptionNote;
    this.type = this.dialogRef.data.type;
    this.surveys = this.dialogRef.data.surveys;
    this.metadata = this.dialogRef.data.metadata;
    this.form = this.fb.group({
      surveys: [[], Validators.required],
      score: [[1, 10]],
      date: [[new Date(CommonConstants.START_DATE_STRING), new Date()]],
      metadata: [[]],
    });
    this.seaForm = this.fb.group({
      clientId: ['', Validators.required],
      clientSecret: ['', Validators.required],
      surveys: [[], Validators.required],
    });
    switch (this.type) {
      case ExportTypes.JSON:
        this.form.addControl('jsonLocation', this.fb.control('', Validators.required));
        this.form.addControl('jsonValue', this.fb.control(this.jsonData, Validators.required));
        break;
      case ExportTypes.SEA:
        this.initSEAForm();
        break;
      default:
        break;
    }
    this.form.controls.surveys.setValue(this.getSurveyValues(this.queryParamGroup.value.surveyid));
    this.form.controls.score.setValue(this.queryParamGroup.value.score);
    if (this.queryParamGroup.value.date.length !== 0) {
      this.form.controls.date.setValue(this.queryParamGroup.value.date);
    }
  }

  private initSEAForm(): void {
    this.formLoading$.next(true);
    this.feedbackCompanyService
      .getFeedback()
      .pipe(
        take(1),
        tap((feedback) => {
          this.seaForm.patchValue({
            ...feedback,
            surveys: feedback.surveys.map((s) => s.id),
          });
          this.formLoading$.next(false);
          this.editingFeedback = true;
        }),
        catchError((err) => {
          if (err.status === HttpStatusCode.NotFound) {
            this.formLoading$.next(false);
            this.editingFeedback = false;
            return EMPTY;
          }
          return throwError(err);
        })
      )
      .subscribe();
  }

  setScore(scoreType: 'min' | 'max', value: number | unknown): void {
    const [minScore, maxScore] = this.form.controls.score.value as number[];
    if (scoreType === 'min') {
      this.form.controls.score.setValue([value, maxScore]);
    }
    if (scoreType === 'max') {
      this.form.controls.score.setValue([minScore, value]);
    }
  }

  copyInputText(inputElement: HTMLInputElement): void {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  }

  close(): void {
    this.dialogRef.close();
  }

  handleAddFeedback(feedback: IFeedback): void {
    of(this.editingFeedback)
      .pipe(
        untilDestroyed(this),
        tap(() => this.buttonLoading$.next(true)),
        switchMap((editing) =>
          editing
            ? this.feedbackCompanyService.updateFeedback(feedback)
            : this.feedbackCompanyService.addFeedback(feedback)
        ),
        tap(() => this.close())
      )
      .subscribe();
  }

  removeFeedback(): void {
    this.feedbackCompanyService
      .deleteFeedback()
      .pipe(
        untilDestroyed(this),
        tap(() => this.close())
      )
      .subscribe();
  }

  exportExcelResponses(): void {
    this.scopeFacade.getScope$
      .pipe(
        take(1),
        switchMap((value) => {
          const { id } = value;
          const payload: Partial<ResponsesExcelRequestPayload> = this.createDownloadExcelPayload(this.form.value);

          return this.responsesService.getResponsesExcelReport(id, payload);
        })
      )
      .subscribe(() => {
        this.notificationService.success(
          this.translocoService.translate('publication-options.toast.send-excel.title'),
          this.translocoService.translate('publication-options.toast.send-excel.text')
        );
        this.close();
      });
  }

  private createDownloadExcelPayload(formValues: Record<string, any>): Partial<ResponsesExcelRequestPayload> {
    const payload: Partial<ResponsesExcelRequestPayload> = {};
    const { score, date, surveys = [], metadata = [] } = formValues;
    const startEndDateArrayLength = 2;

    if (surveys.length) {
      payload.surveyIds = surveys.map(({ id }) => id);
    }
    if (metadata.length) {
      payload.metadata = metadata.map(({ inputLabel }) => inputLabel);
    }
    if (score?.[0]) {
      payload.scoreMin = score[0];
    }

    if (score?.[1]) {
      payload.scoreMax = score[1];
    }

    if (Array.isArray(date) && date.length === startEndDateArrayLength) {
      const [startDate, endDate] = date;
      payload.dateCreatedMin = format(startDate, DateConstants.STRING_DATE);
      payload.dateCreatedMax = format(endDate, DateConstants.STRING_DATE);
    }

    return payload;
  }

  private getSurveyValues(surveyId: string): ISurvey[] {
    return this.surveys.filter((s) => s.id === surveyId);
  }
}
