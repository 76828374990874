import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromReducers from './responses.reducer';
import * as fromActions from './responses.actions';
import * as fromSelectors from './responses.selectors';
import { IResponseParams } from '@app/dashboard/responses/core/models/responses-params.interface';
import { IResponse } from '@app/shared/models/responses/responses.model';
import { UpdateStr } from '@ngrx/entity/src/models';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ResponsesFacade {
  selectResponses$ = this.store.select(fromSelectors.selectAllResponses);
  selectCounters$ = this.store.select(fromSelectors.selectResponseCounters);
  selectLoading$ = this.store.select(fromSelectors.selectLoading);
  selectCustomerLabelsLoading = this.store.select(fromSelectors.selectCustomerLabelsLoading);
  selectResponseMeta$ = this.store.select(fromSelectors.selectResponseMeta);
  selectSelectedResponseIds$ = this.store.select(fromSelectors.selectSelectedResponseIds);
  readyForPublishIds$ = this.store.select(fromSelectors.readyForPublishIds);
  showTranslations$ = this.store.select(fromSelectors.selectShowTranslations);
  selectQueryParams$ = this.store.select(fromSelectors.selectQueryParams);
  customerLabels$ = this.store.pipe(
    select(fromSelectors.selectCustomerLabels),
    filter(value => Boolean(Array.isArray(value) && value.length)),
    map(value =>
      value.map(label => ({
        ...label,
        label: label.name,
        value: label.id
      }))
    )
  );
  allCustomerLabels$ = this.store.pipe(select(fromSelectors.selectCustomerLabels));


  constructor(private store: Store<fromReducers.State>) {
  }

  getResponses(params: IResponseParams = {}): void {
    this.store.dispatch(fromActions.loadResponses({ params }));
  }

  getNextResponses(params: object = {}): void {
    this.store.dispatch(fromActions.loadNextResponses({ params }));
  }

  getPreviousResponses(params: object = {}): void {
    this.store.dispatch(fromActions.loadPreviousResponses({ params }));
  }


  getCustomerLabels(): void {
    this.store.dispatch(fromActions.loadCustomerLabels());
  }

  getCounters(): void {
    this.store.dispatch(fromActions.loadCounters());
  }

  selectResponses(selectedIds: string[]): void {
    this.store.dispatch(fromActions.selectResponses({ selectedIds }));
  }

  updateResponses(updates: UpdateStr<IResponse>[]): void {
    this.store.dispatch(fromActions.updateResponses({ updates }));
  }

  updateResponse(update: UpdateStr<IResponse>): void {
    this.updateResponses([update]);
  }

  deleteResponses(): void {
    this.store.dispatch(fromActions.deleteResponses());
  }

  showTranslations(show: boolean): void {
    this.store.dispatch(fromActions.showTranslations({ show }));
  }

  reset(): void {
    this.store.dispatch(fromActions.resetStore());
  }
}
