import { Inject, Injectable } from '@angular/core';
import { Log, User as OidcUser, UserManager, UserSettings } from 'oidc-client';
import { from, Observable, of, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ModuleSettingsModel } from '../models/module-settings.model';
import { ITokenModel } from '../+state/auth.models';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private manager = new UserManager(this.settings.auth);

  constructor(@Inject('authSettings') private settings: ModuleSettingsModel) {
    if (this.settings.logs) {
      Log.logger = console;
    }
  }

  authorize(appState: object): Observable<any> {
    return from(this.manager.signinRedirect({ state: appState }));
  }

  redirectCallback(): Observable<UserSettings> {
    this.clearSession();
    return from(this.manager.signinRedirectCallback()) as Observable<UserSettings>;
  }

  getOidcUser(): Observable<ITokenModel> {
    return from(this.manager.getUser()).pipe(
      switchMap(user => {
        if (!user || user.expired) {
          return throwError('User invalid');
        }

        return of(new TokenModel(user).get());
      })
    );
  }

  registerSilentRenewEvents(callback: () => any): void {
    this.manager.events.addAccessTokenExpiring(callback);
  }

  signInSilent(): Observable<ITokenModel> {
    return from(this.manager.signinSilent()).pipe(
      switchMap(user => {
        if (!user) {
          return throwError('Token renewal failed');
        }
        return of(new TokenModel(user).get());
      })
    );
  }

  logout(): Promise<any> {
    return this.manager.signoutRedirect();
  }

  clearSession(): void {
    sessionStorage.clear();
    this.manager.clearStaleState();
  }
}

class TokenModel {
  constructor(private user: OidcUser) {}

  get(): ITokenModel {
    return {
      access_token: this.user.access_token,
      refresh_token: this.user.refresh_token,
      expires_at: this.user.expires_at,
      token_type: this.user.token_type,
      state: this.user.state
    };
  }
}
