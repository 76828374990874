<ins-card fxFlex>
  <ins-card-head>
    <ins-card-title>{{ title }}</ins-card-title>
  </ins-card-head>
  <ins-card-content class="count__container">
    <div>
      <div class="count__number">
        {{ count }}
      </div>
      <span class="count__text">
        {{ subtitle }}
      </span>
    </div>
    <div class="count__image">
      <i class="la la-{{ icon }}"></i>
    </div>
  </ins-card-content>
</ins-card>
