import {
  TRANSLOCO_CONFIG,
  TRANSLOCO_LOADER,
  translocoConfig,
  TranslocoLoader,
  TranslocoModule
} from '@ngneat/transloco';
import { Injectable, NgModule } from '@angular/core';
import { environment } from '@env/environment';
import { TranslocoMessageFormatModule } from '@ngneat/transloco-messageformat';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor() {}

  getTranslation(lang: string): Promise<any> {
    return import(`../assets/i18n/${lang}.json`).then(res => res.default);
  }
}

@NgModule({
  imports: [TranslocoModule, TranslocoMessageFormatModule],
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: ['nl_NL', 'en_GB', 'nl_LOC'],
        defaultLang: 'nl_NL',
        fallbackLang: 'en_GB',
        reRenderOnLangChange: true,
        prodMode: environment.production
      })
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader }
  ]
})
export class TranslocoRootModule {}
