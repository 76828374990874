<input
  type="file"
  #fileInput
  [attr.id]="fileInputId"
  [multiple]="multi"
  [accept]="allowedExtensions"
  (change)="onUploaded($event.target.files)"
  class="ins-file-uploader-input"
/>

<ng-container *ngIf="!(files && files?.length) || disablePreview; else fileUploaded">
  <div class="ins-file-uploader__dropzone" insDragDropFileUpload (fileDropped)="onDropped($event)">
    <i class="la la-{{ icon }}"></i>
    <div class="ins-file-uploader__container">
      <div class="ins-file-uploader__text">
        {{ 'common.file-uploader.text' | transloco: { isMulti: multi } }}
      </div>
      <div class="ins-file-uploader__separator"></div>
      <div class="ins-file-uploader__link">
        <label [attr.for]="fileInputId">{{ 'common.file-uploader.label' | transloco }}</label>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #fileUploaded>
  <ng-container *ngIf="previewTemplate; else defaultUploadedTemplate">
    <ng-container *ngTemplateOutlet="previewTemplate; context: { $implicit: files }"></ng-container>
  </ng-container>
</ng-template>
<ng-template #defaultUploadedTemplate>
  <div *ngFor="let file of files; let i = index" class="ins-file-uploader__file">
    <ng-container *insAlias="filesProgress[file.name] | async as fileStatus">
      <insBlob
        *ngIf="file && file?.type?.includes('image') && !disableImagePreview; else fileIcon"
        [blob]="file"
      ></insBlob>
      <ng-template #fileIcon>
        <i class="la la-{{ file.name | fileIcon }}"></i>
      </ng-template>
      <div class="ins-file-uploader__container ins-file-uploader__container--extended">
        <div class="ins-file-uploader__filename">
          <i class="la la-trash" (click)="removeUploadedFile(i)"></i> {{ file.name }}
        </div>
        <app-progress-bar
          [showDot]="false"
          [value]="formType === 'form-part' ? fileStatus?.progress || 0 : 100"
        ></app-progress-bar>
      </div>
      <ng-container *ngIf="formType === 'form-part'; else uploadSuccess">
        <div [ngSwitch]="fileStatus?.status">
          <i *ngSwitchCase="'ok'" class="la la-check-circle"></i>
          <i *ngSwitchCase="'error'" class="la la-times-circle error-icon"></i>
          <loader *ngSwitchDefault size="s"></loader>
        </div>
      </ng-container>
      <ng-template #uploadSuccess>
        <i class="la la-check-circle"></i>
      </ng-template>
    </ng-container>
  </div>
</ng-template>
