import { ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { RootStoreModule } from '@app/core/root-store/root-store.module';
import { BrowserModule } from '@angular/platform-browser';
import { SidebarModule } from '@app/core/layout/main-layout/sidebar/sidebar.module';
import { environment } from '@env/environment';
import { SentryErrorHandler } from '@app/core/handlers/sentry.handler';
import localeNl from '@angular/common/locales/nl';
import { registerLocaleData } from '@angular/common';
import { HeaderModule } from '@app/core/layout/main-layout/header/header.module';
import { HttpClientModule } from '@angular/common/http';
import { UtilsModule } from 'utils';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { SuccessToastComponent } from '@app/core/toastr/success/success-toast.component';
import { ErrorToastComponent } from '@app/core/toastr/error/error-toast.component';
import { InfoToastComponent } from '@app/core/toastr/info/info-toast.component';
import { UploadApiToken } from '@app/shared/modules/upload/upload-api-service.token';
import { ImageUploadService } from '@app/core/services/image-upload.service';
import { TranslocoRootModule } from '@app/transloco-root.module';
import { CustomDialogModule } from '@app/core/layout/modal/custom-dialog/custom-dialog.module';
import { ButtonModule } from '@app/shared/components/button/button.module';
import { NewFeatureAnnouncementModule } from '@app/core/layout/modal/new-feature-announcement/new-feature-announcement.module';
registerLocaleData(localeNl, 'nl_NL');

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    UtilsModule.forRoot(environment),
    RootStoreModule,
    SidebarModule,
    HeaderModule,
    ToastrModule.forRoot({
      closeButton: true,
      progressAnimation: 'decreasing',
      progressBar: true,
      positionClass: 'toast-top-right',
      iconClasses: {
        success: ''
      }
    }),
    TranslocoRootModule,
    CustomDialogModule,
    NewFeatureAnnouncementModule,
    ButtonModule
  ],
  exports: [SidebarModule, HeaderModule, TranslocoRootModule, CustomDialogModule],
  declarations: [SuccessToastComponent, ErrorToastComponent, InfoToastComponent],
  providers: [
    environment.production ? { provide: ErrorHandler, useClass: SentryErrorHandler } : [],
    { provide: UploadApiToken, useClass: ImageUploadService }
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
