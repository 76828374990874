import { QueryList } from '@angular/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

export function itemQueryList$<T>(queryList: QueryList<T>): Observable<ReadonlyArray<T>> {
  return queryList.changes.pipe(
    map(() => getOriginalArrayFromQueryList(queryList)),
    startWith(getOriginalArrayFromQueryList(queryList))
  );
}

export function getOriginalArrayFromQueryList<T>(queryList: QueryList<T>): ReadonlyArray<T> {
  let array: ReadonlyArray<T> = [];

  queryList.find((_item, _index, originalArray) => {
    array = originalArray;

    return true;
  });

  return array;
}
