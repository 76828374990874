import { Injectable } from '@angular/core';
import { ErrorToastComponent } from '@app/core/toastr/error/error-toast.component';
import { InfoToastComponent } from '@app/core/toastr/info/info-toast.component';
import { SuccessToastComponent } from '@app/core/toastr/success/success-toast.component';
import { ActiveToast, ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  constructor(private toastrService: ToastrService) {}

  success(title: string, message: string): void {
    this.toastrService.show(message, title, { toastComponent: SuccessToastComponent, toastClass: 'ins-toast-success' });
  }

  error(title: string, message: string): void {
    this.toastrService.show(message, title, { toastComponent: ErrorToastComponent, toastClass: 'ins-toast-error' });
  }

  info(
    title: string,
    message: string,
    type: 'reload',
    icon: string,
    toastAction: boolean,
    timeOut: number = 0
  ): ActiveToast<ErrorToastComponent> {
    const toast = this.toastrService.show(message, title, {
      disableTimeOut: !timeOut,
      timeOut,
      toastComponent: InfoToastComponent,
      toastClass: 'ins-toast-info',
      closeButton: false,
      progressBar: false,
      tapToDismiss: false,
      enableHtml: true
    });
    toast.toastRef.componentInstance.type = type;
    toast.toastRef.componentInstance.icon = icon;
    toast.toastRef.componentInstance.toastAction = toastAction;
    return toast;
  }

  clearAll(): void {
    this.toastrService.clear();
  }

  clear(id: number): void {
    this.toastrService.clear(id);
  }
}
