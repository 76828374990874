import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';
import { Utils } from '@app/shared/utils/utils';

@Directive({
  selector: '[insDragDropFileUpload]'
})
export class DragDropFileUploadDirective {
  @Output()
  fileDropped = new EventEmitter<File[]>();

  @HostBinding('class.dragged-over')
  private isDraggedOver: boolean;

  @HostListener('dragover', ['$event'])
  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDraggedOver = true;
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDraggedOver = false;
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDraggedOver = false;
    const files = event.dataTransfer.files;
    if (files?.length > 0) {
      const fileArray = Utils.fileListToArray(files);
      this.fileDropped.emit(fileArray);
    }
  }
}
