import { Pipe, PipeTransform } from '@angular/core';
import { InsMatcher } from '@app/shared/types/matcher';
import { Utils } from '@app/shared/utils/utils';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform<T>(items: T[], matcher: InsMatcher<T>, ...args: string[]): T[] {
    if (items && Utils.isPresent(args[0])) {
      return items.filter(item => matcher(item, ...args));
    }
    return items;
  }
}
