import { Pipe, PipeTransform } from '@angular/core';
import { supportedLanguages } from '@app/core/constants/supported-languages';

@Pipe({
  name: 'i18nFlag'
})
export class I18nFlagPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): string {
    const countryObject = supportedLanguages.find(item => item.code.startsWith(value));
    return countryObject?.flagName || countryObject?.code;
  }
}
