import { createAction, props } from '@ngrx/store';
import { IResponse, IResponseListCounters } from '@app/shared/models/responses/responses.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiError } from '@app/shared/models/common/api-error.model';
import { IResponseParams } from '@app/dashboard/responses/core/models/responses-params.interface';
import { IMeta } from '@app/shared/models/common/metadata.model';
import { Update } from '@ngrx/entity';
import { UpdateStr } from '@ngrx/entity/src/models';
import { ActionTypesEnum } from '@app/dashboard/responses/core/+store/actionTypes.enum';
import { ResponseLabelInterface } from '@app/dashboard/response-detail/core/interfaces/response-label.interface';

export const loadResponses = createAction('[Responses] Load Responses', props<{ params: IResponseParams }>());

export const loadNextResponses = createAction('[Responses] Load next Responses', props<{ params: object }>());

export const loadPreviousResponses = createAction('[Responses] Load previous Responses', props<{ params: object }>());

export const loadResponsesSuccess = createAction(
  '[Responses] Load Response Success',
  props<{ responses: IResponse[]; meta: IMeta }>()
);

export const loadNextResponsesSuccess = createAction(
  '[Responses] Load next Response Success',
  props<{ responses: IResponse[]; meta: IMeta }>()
);

export const loadPreviousResponsesSuccess = createAction(
  '[Responses] Load previous Response Success',
  props<{ responses: IResponse[]; meta: IMeta }>()
);


export const loadResponseFailure = createAction(
  '[Responses] Load Response Failure',
  props<{ error: HttpErrorResponse | ApiError }>()
);

export const loadCustomerLabels = createAction(ActionTypesEnum.LOAD_CUSTOMER_LABELS);

export const loadCustomerLabelsSuccess = createAction(
  ActionTypesEnum.LOAD_CUSTOMER_LABELS_SUCCESS,
  props<{ customerLabels: ResponseLabelInterface[] }>()
);

export const loadCustomerLabelsFailed = createAction(
  ActionTypesEnum.LOAD_CUSTOMER_LABELS_FAILURE,
  props<{ error: HttpErrorResponse | ApiError }>()
);

export const loadCounters = createAction('[Responses] Load counters');
export const loadCountersSuccess = createAction(
  '[Responses] Load counters success',
  props<{ counters: IResponseListCounters }>()
);

export const selectResponses = createAction(
  '[Responses] set selected responses to store',
  props<{ selectedIds: string[] }>()
);

export const updateResponses = createAction(
  '[Responses]: update responses',
  props<{ updates: UpdateStr<IResponse>[] }>()
);
export const updateResponsesSuccess = createAction(
  '[Responses]: update responses success',
  props<{ updates: Update<IResponse>[] }>()
);
export const batchCompleted = createAction('[Responses] batch request successfully');
export const fireNotification = createAction(
  '[Notifications]: fire notification',
  props<{ title: string; message: string }>()
);

export const deleteResponses = createAction('[Responses]: Delete responses');
export const deleteResponsesSuccess = createAction('[Responses]: Delete responses success', props<{ ids: string[] }>());

export const showTranslations = createAction('[Responses]: Toggle show translations', props<{ show: boolean }>());

export const resetStore = createAction('[Responses] reset store');
