import { Injectable, Injector } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StaticInjectorService {
  private static injector: Injector = null;
  constructor() {
  }

  static setInjector(injector: Injector) {
    StaticInjectorService.injector = injector;
  }

  static getInjector(): Injector {
    return StaticInjectorService.injector;
  }

}
