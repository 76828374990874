import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectionGroupComponent } from './selection-group.component';
import { SelectionGroupItemDirective } from './directives/selection-group-item.directive';

@NgModule({
  declarations: [SelectionGroupComponent, SelectionGroupItemDirective],
  exports: [SelectionGroupComponent, SelectionGroupItemDirective],
  imports: [CommonModule]
})
export class SelectionGroupModule {}
