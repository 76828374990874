import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import { MenuConfig } from '@app/core/layout/main-layout/sidebar/menu.config';
import { CURRENT_ROUTE, CURRENT_ROUTE_PROVIDER } from '@app/core/providers/current-route.provider';
import { isObservable, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { ScopeFacade } from '@app/core/root-store/scope-store/scope.facade';
import { CommonRoutes } from '@app/core/constants/routes/common-routes.constant';
import { ColorScheme } from '@app/shared/enums/color-scheme.enum';

@Component({
  selector: 'sidebar-item',
  templateUrl: './sidebar-item.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [CURRENT_ROUTE_PROVIDER],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarItemComponent implements OnInit, OnDestroy {
  readonly badgeColor = ColorScheme.SECONDARY;

  @Input()
  item: MenuConfig;
  @Input()
  url: string;

  @HostBinding('class.menu__item--open')
  isOpen = false;
  @HostBinding('class.menu__item')
  componentClass = true;

  isActive = false;

  destroy$ = new Subject<boolean>();

  isUnlocked: boolean;

  constructor(
    private router: Router,
    @Inject(CURRENT_ROUTE) private currentRoute$: Observable<string>,
    private cd: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private scopeFacade: ScopeFacade
  ) {}

  get isParent(): boolean {
    return !!this.item.children;
  }

  get isAsync(): boolean {
    return isObservable(this.item.children);
  }

  get translatable(): boolean {
    return typeof this.item.translatable === 'undefined' || this.item.translatable;
  }

  @HostListener('click', ['$event'])
  stopPropagation(event): void {
    event.stopPropagation();
  }

  ngOnInit(): void {
    this.currentRoute$
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.destroy$),
        withLatestFrom(this.scopeFacade.scopeId$),
        filter(([route]) => !this.cleanUrl(route).split('/').includes(CommonRoutes.LOCKED))
      )
      .subscribe(([route, scopeId]) => {
        const appUrl = this.getAppUrlArray(route);
        const componentUrlArray = [scopeId, this.url];
        const fullItemUrl = this.getUrl(componentUrlArray.join('/'));
        const queryParam = route.split('?')[1];
        const fullItemUrlWithQueryParams = !!queryParam ? `${fullItemUrl}?${queryParam}` : fullItemUrl;

        if (this.routeActive(fullItemUrl) || componentUrlArray.every((part) => appUrl.includes(part))) {
          this.isOpen = this.isParent;
          this.isActive = this.routeActive(fullItemUrlWithQueryParams) && !this.isParent;
        } else {
          this.isOpen = false;
          this.isActive = false;
        }
        this.cd.markForCheck();
      });

    this.isUnlocked = Array.isArray(this.item.children)
      ? this.item.children.some((child) => child.unlocked)
      : this.item.unlocked;
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  openOrRoute(): void {
    if (!this.isUnlocked) {
      this.scopeFacade.scopeId$.pipe(take(1)).subscribe((scopeId) =>
        this.router.navigate([CommonRoutes.ABSOLUTE_LOCKED], {
          skipLocationChange: true,
          replaceUrl: true,
          queryParams: {
            url: [scopeId, this.url].join('/'),
          },
        })
      );
      return;
    }

    if (this.isParent) {
      this.isOpen = !this.isOpen;
      return;
    }

    this.router.navigate([this.url], { queryParams: this.item.params });
  }

  createUrl(url: string, part: string): string {
    if (part && url) {
      return url + '/' + part;
    }

    return url;
  }

  fireAction(event: MouseEvent): void {
    event.stopPropagation();
    if (typeof this.item.sideAction === 'function') {
      this.item.sideAction.call(this);
    }
  }

  private routeActive(url: string | UrlTree): boolean {
    return this.router.isActive(url, {
      paths: 'subset',
      queryParams: 'subset',
      fragment: 'ignored',
      matrixParams: 'ignored',
    });
  }

  private getUrl(url: string): string {
    const urlParam = this.activatedRoute.snapshot.queryParams;
    const isLocked = urlParam && 'url' in urlParam;
    let fullItemUrl = url;

    if (url.endsWith('/')) {
      fullItemUrl = url.substring(0, url.length - 1);
    } else if (isLocked) {
      const lockedUrl = this.router.url.split('?')[0];
      fullItemUrl = `${lockedUrl}?url=${encodeURIComponent('/' + url)}`;
    }
    return fullItemUrl;
  }

  private getAppUrlArray(route: string): ReadonlyArray<string> {
    const urlParam = this.activatedRoute.snapshot.queryParams;

    return urlParam && 'url' in urlParam
      ? this.getUrlFromParam(urlParam.url)
      : this.cleanUrl(route).substring(1).split('/');
  }

  private cleanUrl(url: string): string {
    return url.split('?')[0];
  }

  private getUrlFromParam(value: string): ReadonlyArray<string> {
    return value.split('?')[0].substring(1).split('/');
  }
}
