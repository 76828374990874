import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { UserService } from '@app/core/services/user.service';

@Injectable()
export class ImpersonationInterceptor implements HttpInterceptor {
  constructor(private userService: UserService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const impersonatedUser = this.userService.getImpersonation();

    if (impersonatedUser) {
      return next.handle(req.clone({ setParams: { ['_switch_user']: impersonatedUser } }));
    }
    return next.handle(req);
  }
}
