import { environment } from '@env/environment';
import newExample from './example.json';
import newModel from './model.json';
import newTopic from './topic.json';
import models from './models.json';
import reports from './reports.json';
import reportDetails from './report-details.json';
import rules from './rules.json';
import threshold from './experiences/threshold.json';
import touchpoint from './experiences/touchpoint-timeseries.json';
import survey from './survey.json';

const rulesCopy = JSON.parse(JSON.stringify(rules));

const modelsEndpoint = `${environment.api}/nlp/models`;
const topicsEndpoint = `${environment.api}/nlp/.*/topics`;
const examplesEndpoint = `${environment.api}/nlp/.*/topics/.*/utterance`;
const customerModelsEndpoint = `${environment.api}/nlp/customers/.*/models`;
const reportingEndpoint = `${environment.api}/nlp/.*/reports`;
const reportingDetailsEndpoint = `${environment.api}/nlp/topics/.*/report`;
const rulesEndpoint = `${environment.api}/customers/.*/rules`;
const rulesUpdatingEndpoint = `${environment.api}/customers/.*/rules/.*`;
const ruleCreatingEndpoint = `${environment.api}/customers/.*/rules`;
const surveyGetEndpoint = `${environment.api}/customers/.*/surveys/.*`;

// experiences
const experienceThresholdEndpoint = `${environment.api}/customers/.*/journeys/.*/statistics/threshold`;
const experienceTouchpointEndpoint = `${environment.api}/customers/.*/journeys/.*/statistics/answers/continuous`;

const mocksGetMapping: { [key: string]: { active?: boolean; response: object } } = {};
const mocksPostMapping: { [key: string]: { active?: boolean; getResponse: (...args) => object } } = {};
const mocksPutMapping: { [key: string]: { active?: boolean; getResponse: (...args) => object } } = {};

mocksPostMapping[modelsEndpoint] = {
  getResponse: (requestBodyJson: string) => {
    const requestBody = JSON.parse(requestBodyJson);
    requestBody.id = newModel.id;
    requestBody.modelId = newModel.modelId;
    requestBody.surveys = requestBody.surveyIds.map((surveyId: string) => ({
      id: surveyId
    }));
    return requestBody;
  }
};
mocksPostMapping[topicsEndpoint] = {
  getResponse: (requestBodyJson: string) => {
    const requestBody = JSON.parse(requestBodyJson);
    requestBody.id = newTopic.id;
    requestBody.topicId = newTopic.topicId;
    requestBody.modelId = newTopic.modelId;
    requestBody.topicLabel = requestBody.label;
    return requestBody;
  }
};
mocksPostMapping[examplesEndpoint] = {
  getResponse: (requestBodyJson: string) => {
    const requestBody = JSON.parse(requestBodyJson);
    requestBody.exampleId = newExample.exampleId;
    requestBody.text = requestBody.text;
    return requestBody;
  }
};
mocksPostMapping[ruleCreatingEndpoint] = {
  active: true,
  getResponse: (requestBodyJson: string) => {
    const requestBody = JSON.parse(requestBodyJson);
    requestBody.id = (+rulesCopy[rulesCopy.length - 1].id + 1).toString();
    rulesCopy.push(requestBody);
    return requestBody;
  }
};

mocksPutMapping[rulesUpdatingEndpoint] = {
  active: true,
  getResponse: (requestBodyJson: string) => {
    const requestBody = JSON.parse(requestBodyJson);
    const ruleToUpdate = rulesCopy.find(rule => rule.id === requestBody.id);
    rulesCopy[rulesCopy.indexOf(ruleToUpdate)] = requestBody;
    return requestBody;
  }
};

mocksGetMapping[customerModelsEndpoint] = { response: models };
mocksGetMapping[reportingEndpoint] = { active: true, response: reports };
mocksGetMapping[reportingDetailsEndpoint] = { active: false, response: reportDetails };
mocksGetMapping[rulesEndpoint] = { active: false, response: rulesCopy };
mocksGetMapping[experienceThresholdEndpoint] = { active: false, response: threshold };
mocksGetMapping[experienceTouchpointEndpoint] = { active: false, response: touchpoint };
mocksGetMapping[surveyGetEndpoint] = { active: false, response: survey };

export const getStubResponse = (method: string, url: string, requestBodyJson: string) => {
  let mockMapping;
  switch (method) {
    case 'POST': {
      mockMapping = mocksPostMapping;
      break;
    }
    case 'PUT': {
      mockMapping = mocksPutMapping;
      break;
    }
    case 'GET': {
      mockMapping = mocksGetMapping;
      break;
    }
  }
  if (mockMapping) {
    const mockKey: string = Object.keys(mockMapping).find((key: string) => new RegExp(key).test(url));
    const mock = mockMapping[mockKey];
    if (mock?.active) {
      return mock.response ? mock.response : mock.getResponse(requestBodyJson);
    }
  }
};
