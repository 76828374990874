import { createAction, props } from '@ngrx/store';
import { IContactRoles } from '@app/shared/models/customer/contacts-roles.model';
import { IScope } from '@app/shared/models/common/scope.model';
import { CustomerProduct, UserProduct } from '@app/modules/product-management/core/models/customer-product.model';

export const getScope = createAction('[Scope] get scope', props<{ customerId: string; autoSelected: boolean }>());
export const getScopeSuccess = createAction(
  '[Scope/API] Get scope success',
  props<{ scope: IScope; autoSelected: boolean }>()
);
export const getScopeFailed = createAction('[Scope/API] get scope failed', props<{ error: string }>());

export const getActivatedProducts = createAction('[Scope] get ActivatedProducts', props<{ scope: IScope }>());
export const getActivatedProductsSuccess = createAction(
  '[Scope/API] Get ActivatedProducts success',
  props<{ products: UserProduct[] }>()
);
export const getActivatedProductsFailed = createAction(
  '[Scope/API] get ActivatedProducts failed',
  props<{ error: string }>()
);

export const updateScope = createAction('[Scope]: update app scope', props<{ scope: IScope }>());

export const checkScopeSession = createAction('[Customer] check customer session storage');
export const checkScopeSessionFailed = createAction('[Customer] no customer found in session');

export const unsetScope = createAction('[customer] remove current scope state');
