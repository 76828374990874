import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { LocalStorageLoggedInService } from '../services/local-storage-logged-in.service';
import * as AuthActions from './auth.actions';
import { ITokenModel } from './auth.models';
import { NavigationService } from '../services/navigation.service';

@Injectable()
export class AuthEffects {
  redirectUserToLogin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.redirectToLogin),
        tap(({ state }) => {
          this.authService.authorize(state);
        })
      ),
    { dispatch: false }
  );

  renewToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.renewToken),
      exhaustMap(() =>
        this.authService.signInSilent().pipe(
          map(token => AuthActions.tokenRenewalSuccess({ token })),
          catchError(error => of(AuthActions.tokenRenewalFailed({ error })))
        )
      )
    )
  );

  setAuthToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.getToken),
      exhaustMap(() =>
        this.authService.redirectCallback().pipe(
          map((token: ITokenModel) => {
            if (token?.state?.url) {
              this.navigationService.setRedirectUrl(token.state.url);
            }
            this.localStorageLoggedIn.setItem(true);
            return AuthActions.getTokenSuccess({ token });
          }),
          catchError(error => of(AuthActions.getTokenFailure({ error })))
        )
      )
    )
  );

  loginUnsuccessful$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        AuthActions.checkSessionFailure,
        AuthActions.getTokenFailure,
        AuthActions.tokenRenewalFailed,
        AuthActions.serverRejectedRequest
      ),
      exhaustMap(() => {
        this.localStorageLoggedIn.removeItem();
        this.authService.clearSession();
        return of(AuthActions.redirectToLogin({ state: {} }));
      })
    )
  );

  /**
   * Checks if there is a session in sessionstorage, optimistic check
   * If token is not expired, dispatches checksessionsuccess
   */
  checkTokenSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.checkSession),
      exhaustMap(() =>
        this.authService.getOidcUser().pipe(
          map(token => AuthActions.checkSessionSuccess({ token })),
          catchError(err => of(AuthActions.checkSessionFailure({ err })))
        )
      )
    )
  );

  logOut$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logOut),
        tap(() => {
          this.localStorageLoggedIn.removeItem();
          this.authService.logout();
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private localStorageLoggedIn: LocalStorageLoggedInService,
    private navigationService: NavigationService
  ) {}
}
