<div class="datepicker-container" *ngIf="rangePicker; else datePicker">
  <div class="input-group">
    <span class="input-group-text" (click)="rangepicker.toggle()"><i class="la la-calendar"></i></span>
    <input #rangepicker="bsDaterangepicker"
           (bsValueChange)="rangepickerChange($event)"
           [bsConfig]="constants.RANGEPICKER_CONFIG"
           [bsValue]="date"
           [placeholder]="placeholder || constants.DEFAULT_RANGE_PLACEHOLDER"
           bsDaterangepicker
           class="form-control range-picker-input"
           readonly
           type="text"
           (onShown)="addCustomInput()"
           (onHidden)="rangepickerOnHide()"
    >
  </div>
</div>
<ng-template #datePicker>
  <div class="datepicker-container">
    <div class="input-group">
      <span class="input-group-text" (click)="picker.toggle()"><i class="la la-calendar"></i></span>
      <input #picker="bsDatepicker"
             [bsConfig]="constants.DATEPICKER_CONFIG"
             [placeholder]="placeholder || constants.DEFAULT_PLACEHOLDER"
             [minDate]="minDate"
             (bsValueChange)="datepickerChange($event)"
             [bsValue]="date[0]"
             type="text"
             class="form-control"
             readonly
             bsDatepicker
             (onShown)="addCustomInput()"
             (onHidden)="datepickerOnHide()"
      >
    </div>
  </div>
</ng-template>

