import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toScore'
})
export class ScorePipe implements PipeTransform {
  transform(value: string, fractionDigits: number = 1): number | null {
    if (value) {
      const score = +value;
      return +score.toFixed(fractionDigits);
    }

    return null;
  }
}
