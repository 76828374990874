import { Injector } from '@angular/core';
import { ActionCenterRoutes } from '@app/core/constants/routes/action-center-routes.constant';
import { InsightsRoutes } from '@app/core/constants/routes/insights-routes.constant';
import { InteractionsRoutes } from '@app/core/constants/routes/interactions-routes.constant';
import { ManagementRoutes } from '@app/core/constants/routes/management-routes.constant';
import { SocialRoutes } from '@app/core/constants/routes/social-routes.constant';
import { ResponsesFacade } from '@app/dashboard/responses/core/+store/responses.facade';
import { Roles, UserRoles } from '@app/shared/enums/roles.enum';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { CommonRoutes } from '@app/core/constants/routes/common-routes.constant';

export interface MenuConfig {
  label: string;
  url: string;
  product?: {
    productId?: string;
    isBaseProduct?: boolean;
  };
  href?: string;
  params?: { [key: string]: any };
  icon?: string;
  unlocked?: boolean;
  userRole?: UserRoles[];
  hideForRoles?: Roles[];
  translatable?: boolean;
  badge?: Observable<number>;
  sideAction?: (val?: any) => void;
  children?: MenuConfig[] | Observable<MenuConfig[]>;
  isBeta?: boolean;
  showForRoles?: Roles[];
}

export const createMenuConfig = (injector: Injector): MenuConfig[] => {
  const newResponses$ = injector.get(ResponsesFacade).selectCounters$.pipe(
    filter((counters) => !!counters),
    map(({ newResponses }) => +newResponses)
  );

  // todo: set unlocked default as true
  return [
    {
      label: 'dashboard',
      url: CommonRoutes.HOME,
      icon: 'home',
      unlocked: true,
      hideForRoles: [Roles.ROLE_REPORTING_USER],
      children: [
        {
          label: 'admin-dashboard',
          url: CommonRoutes.ADMIN_DASHBOARD,
          icon: null,
          unlocked: false,
          userRole: [UserRoles.ROLE_ADMIN],
          product: {
            isBaseProduct: true,
          },
        },
      ],
      product: {
        isBaseProduct: true,
      },
    },
    {
      label: 'response-management',
      url: ActionCenterRoutes.RESPONSES,
      hideForRoles: [Roles.ROLE_REPORTING_USER],
      params: { status: 'inbox' },
      icon: 'inbox',
      badge: newResponses$,
      product: {
        productId: 'responses',
      },
    },
    {
      label: 'notifications',
      url: ActionCenterRoutes.NOTIFICATIONS,
      hideForRoles: [Roles.ROLE_REPORTING_USER],
      unlocked: false,
      icon: 'bell',
      isBeta: true,
      product: {
        isBaseProduct: true,
      },
    },
    {
      label: 'actionable-insights',
      url: InsightsRoutes.INSIGHTS,
      hideForRoles: [Roles.ROLE_REPORTING_USER],
      icon: 'lightbulb',
      product: {
        isBaseProduct: true,
      },
      children: [
        {
          label: 'brand-experience',
          url: InsightsRoutes.BRAND_EXPERIENCE,
          icon: null,
          product: {
            productId: 'bx',
          },
          children: [
            {
              label: 'bx-driver',
              url: InsightsRoutes.SERVICE_PRIORITY,
              icon: null,
              product: {
                productId: 'bx',
              },
            },
            {
              label: 'drilldown',
              url: InsightsRoutes.DRILLDOWN,
              icon: null,
              product: {
                productId: 'bx',
              },
            },
            {
              label: 'open-feedback',
              url: InsightsRoutes.OPEN_FEEDBACK,
              icon: null,
              product: {
                productId: 'bx',
              },
            },
          ],
        },
        {
          label: 'customer-experience',
          url: InsightsRoutes.CUSTOMER_EXPERIENCE,
          icon: null,
          product: {
            productId: 'cx',
          },
          children: [
            {
              label: 'cx-channel',
              url: InsightsRoutes.JOURNEY_MAP,
              icon: null,
              product: {
                productId: 'cx',
              },
            },
            {
              label: 'cx-phases',
              url: InsightsRoutes.PHASES_DEEPDIVE,
              icon: null,
              product: {
                productId: 'cx',
              },
            },
            {
              label: 'cx-driver',
              url: InsightsRoutes.CUSTOMER_PRIORITY,
              icon: null,
              product: {
                productId: 'cx',
              },
            },
            {
              label: 'drilldown',
              url: InsightsRoutes.DRILLDOWN,
              icon: null,
              product: {
                productId: 'cx',
              },
            },
            {
              label: 'open-feedback',
              url: InsightsRoutes.OPEN_FEEDBACK,
              icon: null,
              product: {
                productId: 'cx',
              },
            },
          ],
        },
        {
          label: 'service-experience',
          url: InsightsRoutes.SERVICE_EXPERIENCE,
          icon: null,
          product: {
            productId: 'sx',
          },
          children: [
            {
              label: 'sx-onboarding-flow',
              url: InsightsRoutes.ONBOARDING_FLOW,
              icon: null,
              product: {
                productId: 'sx',
              },
            },
            {
              label: 'sx-channel',
              url: InsightsRoutes.JOURNEY_MAP,
              icon: null,
              product: {
                productId: 'sx',
              },
            },
            {
              label: 'sx-driver',
              url: InsightsRoutes.SERVICE_PRIORITY,
              icon: null,
              product: {
                productId: 'sx',
              },
            },
            {
              label: 'drilldown',
              url: InsightsRoutes.DRILLDOWN,
              icon: null,
              product: {
                productId: 'sx',
              },
            },
            {
              label: 'open-feedback',
              url: InsightsRoutes.OPEN_FEEDBACK,
              icon: null,
              product: {
                productId: 'sx',
              },
            },
          ],
        },
        {
          label: 'user-experience',
          url: InsightsRoutes.USER_EXPERIENCE,
          icon: null,
          unlocked: false,
        },
        {
          label: 'employee-experience-experience',
          url: InsightsRoutes.EMPLOYEE_EXPERIENCE,
          icon: null,
          product: {
            productId: 'ex',
          },
          children: [
            {
              label: 'ex-channel',
              url: InsightsRoutes.JOURNEY_MAP,
              icon: null,
              product: {
                productId: 'ex',
              },
            },
            {
              label: 'ex-driver',
              url: InsightsRoutes.EMPLOYEE_PRIORITY,
              icon: null,
              product: {
                productId: 'ex',
              },
            },
            {
              label: 'drilldown',
              url: InsightsRoutes.DRILLDOWN,
              icon: null,
              product: {
                productId: 'ex',
              },
            },
            {
              label: 'open-feedback',
              url: InsightsRoutes.OPEN_FEEDBACK,
              icon: null,
              product: {
                productId: 'ex',
              },
            },
          ],
        },
        {
          label: 'text-analyses',
          url: InsightsRoutes.DEEPLEARNING_TEXT_ANALYSE,
          icon: null,
          product: {
            productId: 'nlp',
          },
        },
        {
          label: 'comparison-tool',
          url: InsightsRoutes.COMPARISON_TOOL,
          icon: null,
          product: {
            isBaseProduct: true,
          },
        },
      ],
    },
    {
      label: 'reporting.label',
      url: InsightsRoutes.REPORTING,
      icon: 'file-alt',
      product: {
        productId: 'reporting',
      },
    },
    {
      label: 'surveys',
      url: InteractionsRoutes.SURVEYS,
      unlocked: true,
      icon: 'clipboard-check',
      hideForRoles: [Roles.ROLE_REPORTING_USER],
      product: {
        productId: 'survey',
      },
    },
    {
      label: 'invites-channels',
      url: InteractionsRoutes.INVITES_CHANNELS,
      icon: 'envelope-open-text',
      hideForRoles: [Roles.ROLE_REPORTING_USER],
      product: {
        productId: 'invites',
      },
    },
    {
      label: 'social-proof',
      url: SocialRoutes.SOCIAL,
      icon: 'check-circle',
      hideForRoles: [Roles.ROLE_REPORTING_USER],
      unlocked: true,
      product: {
        isBaseProduct: true,
      },
      children: [
        {
          label: 'publication',
          url: SocialRoutes.PUBLICATION,
          icon: null,
          unlocked: false,
          userRole: [UserRoles.ROLE_ADMIN],
          product: {
            productId: 'publication',
          },
        },
        {
          label: 'narrowcasting',
          url: InsightsRoutes.NARROWCASTING,
          icon: null,
          unlocked: false,
          product: {
            productId: 'narrowcasting',
          },
        },
      ],
    },
    {
      label: 'management',
      url: ManagementRoutes.MANAGEMENT,
      icon: 'cog',
      unlocked: true,
      product: {
        isBaseProduct: true,
      },
      children: [
        {
          label: 'closed-loop-feedback',
          url: ActionCenterRoutes.CLOSED_LOOP_FEEDBACK,
          hideForRoles: [Roles.ROLE_REPORTING_USER],
          product: {
            productId: 'clf',
          },
        },
        {
          label: 'ruling',
          url: InteractionsRoutes.RULING,
          hideForRoles: [Roles.ROLE_REPORTING_USER],
          product: {
            productId: 'ruling',
          },
        },
        {
          label: 'invites-templates',
          url: ManagementRoutes.INVITES_TEMPLATES,
          unlocked: true,
          icon: null,
          hideForRoles: [Roles.ROLE_REPORTING_USER],
          userRole: [UserRoles.ROLE_ADMIN],
          product: {
            isBaseProduct: true,
          },
        },
        {
          label: 'connectivity',
          url: ManagementRoutes.CONNECTIVITY,
          icon: null,
          unlocked: false,
          hideForRoles: [Roles.ROLE_REPORTING_USER],
          userRole: [UserRoles.ROLE_ADMIN],
          product: {
            isBaseProduct: true,
          },
        },
        {
          label: 'gdpr',
          url: ManagementRoutes.GDPR,
          icon: null,
          unlocked: false,
          userRole: [UserRoles.ROLE_ADMIN],
          product: {
            isBaseProduct: true,
          },
        },
        {
          label: 'customers',
          url: ManagementRoutes.CUSTOMERS,
          icon: null,
          unlocked: true,
          product: {
            isBaseProduct: true,
          },
        },
        {
          label: 'accounts',
          url: ManagementRoutes.ACCOUNTS,
          icon: null,
          unlocked: true,
          hideForRoles: [Roles.ROLE_REPORTING_USER],
          userRole: [UserRoles.ROLE_ADMIN],
          product: {
            isBaseProduct: true,
          },
        },
        {
          label: 'product-management',
          url: ManagementRoutes.PRODUCTS,
          unlocked: true,
          hideForRoles: [Roles.ROLE_REPORTING_USER],
          icon: null,
          userRole: [UserRoles.ROLE_ADMIN],
          product: {
            isBaseProduct: true,
          },
        },
        {
          label: 'demo-panel',
          url: ManagementRoutes.DEMO_PANEL,
          unlocked: true,
          icon: null,
          userRole: [UserRoles.ROLE_ADMIN],
          product: {
            isBaseProduct: true,
          },
        },
      ],
    },
  ];
};
