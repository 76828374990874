import { CommonRoutes } from '@app/core/constants/routes/common-routes.constant';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class NavigationUtils {
  static readonly BASE_ROUTES = [CommonRoutes.SCOPE, CommonRoutes.AUTH];

  static processNavigationUrl(url: string, scopeId$: Observable<string>): Observable<string> {
    if (url && NavigationUtils.BASE_ROUTES.every((route: string) => !url.startsWith(`/${route}`))) {
      return NavigationUtils.processScopeRoutes(url, scopeId$);
    }
  }

  private static processScopeRoutes(url: string, scopeId$: Observable<string>): Observable<string> {
    const firstSegment = url.split('/')[1];
    if (!firstSegment || /^[a-z\-]+$/.test(firstSegment.split('?')[0])) {
      return scopeId$.pipe(map((scopeId: string) => (url !== '/' ? `/${scopeId}${url}` : `/${scopeId}`)));
    }
  }
}
