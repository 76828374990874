export class ActionCenterRoutes {
  static readonly ACTIONS_CENTER = 'action-center';
  static readonly SETTINGS = 'settings';

  static readonly RESPONSES = 'inbox';
  static readonly CLOSED_LOOP_FEEDBACK = 'closed-loop-feedback';
  static readonly NOTIFICATIONS = 'notifications';
  static readonly ABSOLUTE_NOTIFICATION_RULES = `/${ActionCenterRoutes.ACTIONS_CENTER}/${ActionCenterRoutes.SETTINGS}`;

  static readonly ABSOLUTE_RESPONSES = `/${ActionCenterRoutes.RESPONSES}`;

  static readonly RELATIVE_RESPONSES = `${ActionCenterRoutes.ACTIONS_CENTER}/${ActionCenterRoutes.RESPONSES}`;
}
