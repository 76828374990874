import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Injector,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { createMenuConfig, MenuConfig } from '@app/core/layout/main-layout/sidebar/menu.config';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ScopeFacade } from '@app/core/root-store/scope-store/scope.facade';
import { filter } from 'rxjs/operators';
import { CustomerProduct, UserProduct } from '@app/modules/product-management/core/models/customer-product.model';
import { combineLatest } from 'rxjs';
import { ProductUtils } from '@app/shared/utils/product.utils';
import { ProfileFacade } from '@app/core/root-store/profile-store/profile.facade';
import { UserRoles } from '@app/shared/enums/roles.enum';
import { ResponsesFacade } from '@app/dashboard/responses/core/+store/responses.facade';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'aside',
  },
})
export class SidebarComponent implements OnInit {
  menuConfig: MenuConfig[];
  config: PerfectScrollbarConfigInterface = {
    wheelPropagation: true,
  };

  @ViewChild('scrollableContent', { static: false })
  elementView: ElementRef;

  constructor(
    private cd: ChangeDetectorRef,
    private injector: Injector,
    private scopeFacade: ScopeFacade,
    private userFacade: ProfileFacade,
    private responsesFacade: ResponsesFacade
  ) {}

  ngOnInit(): void {
    combineLatest([this.scopeFacade.getScope$, this.scopeFacade.getProducts$, this.userFacade.getUser$])
      .pipe(filter(([_, products]) => !!products?.length))
      .subscribe(([_, products, user]) => {
        const menu = createMenuConfig(this.injector);
        const isAdmin = user.roles.includes(UserRoles.ROLE_ADMIN);
        this.menuConfig = this.traverseMenuTree(menu, products, isAdmin);
        this.cd.markForCheck();
        this.responsesFacade.getCounters();
      });
  }

  private traverseMenuTree(items: MenuConfig[], products: UserProduct[], isAdmin: boolean): MenuConfig[] {
    const productMap: Record<string, CustomerProduct> = products.reduce(
      (acc, val) => ({
        ...acc,
        [val.product.ref]: val,
      }),
      {}
    );

    return items.map((item) => this.traverseTreeItem(item, productMap, isAdmin));
  }

  private traverseTreeItem(
    menuConfig: MenuConfig,
    products: { [key: string]: CustomerProduct },
    isAdmin: boolean
  ): MenuConfig {
    let unlocked = false;

    if (menuConfig?.product) {
      const configProduct = menuConfig.product;
      if (configProduct?.productId) {
        const product = products[configProduct.productId];
        unlocked = ProductUtils.hasPermissions(product, isAdmin);
      }

      if (configProduct?.isBaseProduct) {
        unlocked = configProduct.isBaseProduct;
      }
    }

    return {
      ...menuConfig,
      unlocked,
      children: menuConfig.children
        ? (menuConfig.children as MenuConfig[]).map((item) => this.traverseTreeItem(item, products, isAdmin))
        : null,
    };
  }
}
