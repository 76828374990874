import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'ins-card-title',
  template: `
    <ng-content></ng-content>
  `
})
export class InsCardTitleComponent {
  @HostBinding('class')
  componentClass = 'ins-card__head-title';
}
