import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-icon-action-button',
  template: `
    <button (click)="emitAction()" type="button" class="icon-button">
      <span class="button-icon">
        <i class="icon-item" [class]="icon"></i>
      </span>
    </button>
  `,
  styleUrls: ['./icon-action-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconActionButtonComponent {
  @Input()
  icon: string;

  @Output()
  emitter = new EventEmitter();

  emitAction() {
    this.emitter.emit();
  }
}
