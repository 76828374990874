import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { resizeObserverStrategy } from '@app/shared/observables/resize-observer';
import { coerceElement } from '@angular/cdk/coercion';
import { debounceTime, map, startWith } from 'rxjs/operators';

@Directive({
  selector: '[insElementObserver]',
  exportAs: 'insElementObserver'
})
export class ElementObserverDirective implements OnInit, OnDestroy {
  destroy$ = new Subject();
  boundingClientRect$ = new BehaviorSubject(this.element.nativeElement.getBoundingClientRect());

  @Input()
  debounceTime = 20;
  @Output()
  onElementChanges = new EventEmitter<DOMRect>();

  constructor(public element: ElementRef<HTMLElement>) {}

  ngOnInit(): void {
    this.listener(this.element).subscribe(boundingClientRect => {
      this.boundingClientRect$.next(boundingClientRect);
      this.onElementChanges.emit(boundingClientRect);
    });
  }

  private listener(host: any): Observable<DOMRect> {
    const element = coerceElement<HTMLElement>(host);
    return resizeObserverStrategy(element).pipe(
      startWith(true),
      debounceTime(this.debounceTime),
      map(() => element.getBoundingClientRect())
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
