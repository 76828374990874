import { ChangeDetectorRef, Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ScopeFacade } from '@app/core/root-store/scope-store/scope.facade';
import { ProfileFacade } from '@app/core/root-store/profile-store/profile.facade';

@Directive({
  selector: '[appShowByRoles]',
})
export class ShowByRolesDirective implements OnInit {
  //directive to show an element if the user has any of provided roles
  @Input('appShowByRoles')
  appShowByRoles: string[] = [];

  isVisible = false;

  stop$ = new Subject();

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private scopeFacade: ScopeFacade,
    private readonly profileFacade: ProfileFacade,
    private readonly cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    combineLatest([this.scopeFacade.getContactRoles$, this.profileFacade.getIsAdmin$])
      .pipe(takeUntil(this.stop$))
      .subscribe(([state, isAdmin]) => {
        const isValid =
          isAdmin ||
          !this.appShowByRoles?.length ||
          (Array.isArray(this.appShowByRoles) && state.roles.some((role) => this.appShowByRoles?.includes(role)));
        if (isValid) {
          this.renderTemplate();
        } else {
          this.isVisible = false;

          this.viewContainer.clear();
        }

        this.cdRef.markForCheck();
      });
  }

  private renderTemplate(): void {
    if (!this.isVisible) {
      this.isVisible = true;
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

  ngOnDestroy(): void {
    this.stop$.next(null);
    this.stop$.complete();
  }
}
