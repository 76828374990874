<div cdkOverlayOrigin #trigger="cdkOverlayOrigin" class="wrapper">
  <ng-content></ng-content>
</div>
<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="open"
  [cdkConnectedOverlayWidth]="triggerWidth"
  [cdkConnectedOverlayHasBackdrop]="false"
  [cdkConnectedOverlayOffsetY]="offsetY"
  (overlayOutsideClick)="close($event)"
  (detach)="close()"
  (positionChange)="onPositionChange($event)"
>
  <ng-container *ngTemplateOutlet="content; context: contentContext"></ng-container>
</ng-template>
