import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InsCardComponent } from '@app/shared/components/card/ins-card.component';
import { InsCardHeadComponent } from '@app/shared/components/card/head/ins-card-head.component';
import { InsCardTitleComponent } from '@app/shared/components/card/head/ins-card-title.component';
import { InsCardContentComponent } from '@app/shared/components/card/content/ins-card-content.component';
import { InsCardActionsComponent } from '@app/shared/components/card/head/ins-card-actions.component';
import { InsCardFooterComponent } from '@app/shared/components/card/head/ins-card-footer.component';
import { InsCardDescriptionComponent } from '@app/shared/components/card/head/ins-card-description.component';
import { FlexModule } from '@angular/flex-layout';

const COMPONENTS = [
  InsCardComponent,
  InsCardHeadComponent,
  InsCardTitleComponent,
  InsCardDescriptionComponent,
  InsCardContentComponent,
  InsCardActionsComponent,
  InsCardFooterComponent
];

@NgModule({
  imports: [CommonModule, FlexModule],
  exports: [...COMPONENTS],
  declarations: [...COMPONENTS],
  providers: []
})
export class InsCardModule {}
