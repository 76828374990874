import { NgModule } from '@angular/core';

import { FormGroupComponent } from './form-group.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule],
  exports: [FormGroupComponent],
  declarations: [FormGroupComponent],
  providers: []
})
export class FormGroupModule {}
