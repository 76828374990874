type Params = {
  [key: string]: any;
};

export interface InitConfigObject {
  namespace?: string;
  surveyId: string;
  trigger: SurveyRenderType | SurveyRenderType[];
  secondsOnSite: number;
  targetElement: string;
  metadata: Params;
  session: SurveySession;
  triggerConfig: Partial<TriggerConfig>;
  showChance: number;
  tracking: boolean;
  trackingSettings: TrackingSettings;
  scrollDepthPercentage: number;
  transitions: boolean;
}

export interface TrackingSettings<T = boolean> {
  pageUrl: T;
  pageTitle: T;
  referrer: T;
}

export interface SurveySession {
  type: 'soft' | 'hard' | 'none';
  daysValid?: string;
}

export interface TriggerConfig {
  visible: boolean;
  color: string;
  backgroundColor: string;
  text: string;
  icon: string;
  borderRadius: number;
}

export interface surveyStateData {
  surveyBaseUrl: string;
  iframeContainerClass: string;
  iframeElementClass: string;
  iframeTriggerClass: string;
  iframeInjectedClass: string;
  spinnerClass: string;
  secondsOnSite: number;
  triggerButtonText: string;
  buttonAttributes: {
    'aria-label': string;
    tabindex: string;
    role: string;
  };
}

export enum SurveyRenderType {
  RENDER_ON_SITE = 'onSite',
  RENDER_MOUSE_OUT = 'mouseOut',
  RENDER_SURVEY_MANUALLY = 'manually',
  RENDER_INSIDE_TARGET_ELEMENT = 'inject',
  SCROLL_DEPTH = 'scrollDepth',
}

export enum SurveySessionTypes {
  SOFT = 'soft',
  HARD = 'hard',
}

export interface RenderSurveyOptions {
  renderOverride?: boolean;
  dontValidate?: boolean;
}
