import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ExportTypes } from '@app/dashboard/publication-options/core/enums/export-types.enum';
import { BehaviorSubject } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ISurvey } from '@app/shared/models/survey/survey.model';
import { MetadataInterface } from '@app/shared/modules/metadata/interface/metadata.interface';
import { JsonEditorOptions } from 'ang-jsoneditor';
import {
  InitConfigObject,
  SurveyRenderType,
  SurveySession,
  SurveySessionTypes,
  TrackingSettings,
  TriggerConfig,
} from '@app/dashboard/publication-options/core/interfaces/script-generator.interfaces';
import { FileSaverService } from 'ngx-filesaver';
import { SelectComponent } from '@app/shared/components/select/select.component';
import { Constants } from '@app/dashboard/publication-options/core/constants/constants';
import { Params } from '@angular/router';
import { ExportingModalComponent } from '@app/shared/components/exporting-modal/exporting-modal.component';

@Component({
  selector: 'app-script-generator',
  templateUrl: './script-generator.component.html',
  styleUrls: ['./script-generator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class ScriptGeneratorComponent implements OnInit {
  readonly EXPORT_TYPES = ExportTypes;

  buttonLoading$ = new BehaviorSubject(false);
  formLoading$ = new BehaviorSubject(false);
  initConfig: Partial<InitConfigObject>;
  trackingSettings: TrackingSettings;
  surveySession: SurveySession;
  triggerConfig: TriggerConfig;

  scriptForm: UntypedFormGroup;
  type: ExportTypes;
  surveys: ISurvey[];
  metadata: MetadataInterface[];
  renderTypes: SurveyRenderType[];
  sessionTypes = Object.values(SurveySessionTypes);
  trackingSettingsOptions: string[];
  jsonScriptOptions: JsonEditorOptions;
  showSecondsOnSite = false;
  showTargetElement = false;
  showCustomBtnSelector = false;
  showScrollDepth = false;
  showSessions = false;

  @ViewChild('trackingSettings')
  trackingSettingsComp: SelectComponent;

  scriptPrefix: string = Constants.SCRIPT_GENERATOR_PREFIX;
  script: string[];

  constructor(
    private exportingModal: ExportingModalComponent,
    private fb: UntypedFormBuilder,
    private fileSaverService: FileSaverService
  ) {}

  ngOnInit(): void {
    this.surveys = this.exportingModal.surveys;
    this.metadata = this.exportingModal.metadata;

    this.renderTypes = Object.values(SurveyRenderType);

    this.trackingSettingsOptions = ['Page Url', 'Page Title', 'Referrer'];
    this.scriptForm = this.fb.group({
      surveyId: [null, Validators.required], //surveyID
      surveyRenderType: [[]], //surveyTrigger type
      trackingSettings: [[]], //trackingSettings
      chance: ['', [Validators.min(0), Validators.pattern('^[0-9]*$')]], //chance of seeing a pop up -> 100 = 1% 0 = 0%
      metadata: [], //metadata
      metadataValue: [], //metadata value
      triggerConfig: [], //button styling
      buttonVisibility: [true],
      session: [null], //session
      buttonText: [],
      buttonColor: ['#2B353A'],
      buttonBorder: [10, [Validators.min(0), Validators.max(100), Validators.pattern('^[0-9]*$')]],
      logo: [],
      buttonTextColor: ['#ffffff'],
      secondsOnSite: ['', [Validators.min(0), Validators.pattern('^[0-9]*$')]], //only for the onSite trigger
      targetElement: [''], //only for the inject trigger
      customBtnSelector: [''], // only for the custom button selector
      scrollDepth: ['', [Validators.min(0), Validators.max(100), Validators.pattern('^[0-9]*$')]], //only for scrollDepthTrigger
      tracking: [true], //enable/disable tracking
    });

    this.scriptForm.valueChanges.pipe().subscribe(() => {
      this.triggerConfig = {
        visible: this.scriptForm.value.buttonVisibility,
        color: this.scriptForm.value.buttonTextColor,
        backgroundColor: this.scriptForm.value.buttonColor,
        text: this.scriptForm.value.buttonText,
        icon: this.scriptForm.value.logo,
        borderRadius: this.scriptForm.value.buttonBorder,
      };
      this.trackingSettings = {
        pageUrl: this.scriptForm.value.trackingSettings.includes('Page Url'),
        pageTitle: this.scriptForm.value.trackingSettings.includes('Page Title'),
        referrer: this.scriptForm.value.trackingSettings.includes('Referrer'),
      };
      this.showSecondsOnSite = this.scriptForm.value.surveyRenderType.includes(SurveyRenderType.RENDER_ON_SITE);
      this.showTargetElement = this.scriptForm.value.surveyRenderType.includes(
        SurveyRenderType.RENDER_INSIDE_TARGET_ELEMENT
      );

      this.showCustomBtnSelector = this.scriptForm.value.surveyRenderType.includes(
        SurveyRenderType.RENDER_SURVEY_MANUALLY
      );
      this.showSessions =
        this.scriptForm.value.surveyRenderType?.length &&
        !this.scriptForm.value.surveyRenderType.includes(SurveyRenderType.RENDER_SURVEY_MANUALLY) &&
        !this.scriptForm.value.surveyRenderType.includes(SurveyRenderType.RENDER_INSIDE_TARGET_ELEMENT);

      this.showScrollDepth = this.scriptForm.value.surveyRenderType.includes(SurveyRenderType.SCROLL_DEPTH);
      this.trackingSettingsComp.setDisabledState(!this.scriptForm.value.tracking);
      if (!this.scriptForm.value.metadata) {
        this.scriptForm.value.metadataValue = '';
      }
      if (this.scriptForm.value.surveyId) {
        this.initializeInitObject();
        this.script = (
          this.scriptPrefix +
          this.stringify(this.initConfig, 1) +
          ');\n' +
          '    });\n' +
          '    document.head.appendChild(a);\n' +
          '})();'
        ).split('\n');
        this.formatScript();
      } else {
        this.script = null;
      }
    });
  }

  initializeInitObject(): void {
    this.initConfig = {
      surveyId: this.scriptForm.value.surveyId.flight[0].id,
      trigger: this.scriptForm.value.surveyRenderType,
      tracking: this.scriptForm.value.tracking,
      triggerConfig: {
        visible: false,
      },
    };
    if (this.scriptForm.value.buttonVisibility) {
      Object.assign(this.initConfig, { triggerConfig: this.triggerConfig });
    }
    if (this.scriptForm.value.tracking) {
      Object.assign(this.initConfig, { trackingSettings: this.trackingSettings });
    }
    if (this.showSecondsOnSite) {
      Object.assign(this.initConfig, { secondsOnSite: Number(this.scriptForm.value.secondsOnSite) });
    }
    if (this.showTargetElement) {
      Object.assign(this.initConfig, { targetElement: this.scriptForm.value.targetElement });
    }
    if (this.showCustomBtnSelector) {
      Object.assign(this.initConfig, { customBtnSelector: this.scriptForm.value.customBtnSelector });
    }
    if (this.showScrollDepth) {
      Object.assign(this.initConfig, { scrollDepthPercentage: Number(this.scriptForm.value.scrollDepth) });
    }
    if (this.scriptForm.value.chance !== '') {
      Object.assign(this.initConfig, { showChance: Math.max(0, Number(this.scriptForm.value.chance) - 1) });
    }
    if (this.scriptForm.value.metadata) {
      Object.assign(this.initConfig, { metadata: this.setMetadataParams() });
    }
    if (this.scriptForm.value.session) {
      Object.assign(this.initConfig, { session: { type: String(this.scriptForm.value.session) } });
    }
  }

  formatScript(): void {
    for (let i = 0; i < this.script.length - 1; i++) {
      this.script[i] += '\n';
    }
  }

  close(): void {
    this.exportingModal.close();
  }

  setBackground($event: { url: string }): void {
    this.scriptForm.controls.logo.setValue($event[0].url);
  }

  removeLogo(): void {
    if (this.scriptForm.value.logo !== null) {
      this.scriptForm.controls.logo.setValue(null);
    }
  }

  getBackgroundColor() {
    return this.scriptForm.controls.buttonColor.value;
  }

  getTextColor() {
    return this.scriptForm.controls.buttonTextColor.value;
  }

  getButtonText() {
    return this.scriptForm.controls.buttonText.value;
  }

  getButtonBorder() {
    return this.scriptForm.controls.buttonBorder.value;
  }

  getLogo() {
    return this.scriptForm.controls.logo.value;
  }

  getButtonVisibility() {
    return this.scriptForm.controls.buttonVisibility.value;
  }

  stringify(obj_from_json, depth: number): string {
    let recursionDepth = depth;
    ++recursionDepth;
    if (
      obj_from_json === null ||
      obj_from_json === undefined ||
      typeof obj_from_json !== 'object' ||
      Array.isArray(obj_from_json)
    ) {
      return JSON.stringify(obj_from_json);
    }
    let indentation = '';
    for (let i = 0; i < recursionDepth; i++) {
      indentation += '    ';
    }
    const props = Object.keys(obj_from_json)
      .map((key) => `${indentation}${this.formatKey(key)}:${this.stringify(obj_from_json[key], recursionDepth)}`)
      .join(',\n    ');
    return `{\n    ${props}\n` + indentation + '}';
  }

  writeAndDownload(): void {
    const blob = new Blob(this.script);
    this.fileSaverService.save(blob, 'script.js');
  }

  setMetadataParams(): Params {
    return this.scriptForm.value.metadata
      ? { [this.scriptForm.value.metadata.inputLabel]: this.scriptForm.value.metadataValue }
      : this.scriptForm.value.metadata;
  }

  formatKey(key: string): string {
    return key.includes(' ') ? "['" + key + "']" : key;
  }
}
