import { NgModule } from '@angular/core';

import { ButtonComponent } from './button.component';
import { CommonModule } from '@angular/common';
import { InsButtonComponent } from './button-new.component';

@NgModule({
  imports: [CommonModule],
  exports: [ButtonComponent, InsButtonComponent],
  declarations: [ButtonComponent, InsButtonComponent],
  providers: []
})
export class ButtonModule {}
