import { Component, HostBinding, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ins-card-actions',
  template: `
    <ng-content></ng-content>
  `,
  encapsulation: ViewEncapsulation.None
})
export class InsCardActionsComponent implements OnInit {
  @HostBinding('class')
  class = 'ins-card__head-toolbar';
  constructor() {}

  ngOnInit() {}
}
