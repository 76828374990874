import { Component, EventEmitter, Host, Input, Optional, Output } from '@angular/core';
import { CogDropdownComponent } from '@app/shared/components/cog-dropdown/cog-dropdown.component';

@Component({
  selector: 'app-cog-item',
  template: `
    <li class="menuitem-icon" role="menuitem" (click)="click()">
      <a class="dropdown-item" [class.disabled]="isDisabled">
        <ng-content></ng-content>
      </a>
    </li>
  `
})
export class CogItemComponent {
  @Input()
  isDisabled = false;

  @Output()
  onClick: EventEmitter<void> = new EventEmitter<void>();

  constructor(@Optional() @Host() private host: CogDropdownComponent | null) {}

  click(): void {
    if (this.isDisabled) {
      return;
    }
    this.closeParent();

    this.onClick.emit();
  }

  private closeParent(): void {
    if (this.host) {
      this.host.close();
    }
  }
}
