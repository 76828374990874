<app-modal *ngIf="type !== EXPORT_TYPES.SCRIPT" [title]="titleKey | transloco" (onClose)="close()">
  <ng-container modal-content>
    <p class="exporting-modal__description">
      {{ descriptionKey | transloco }}
    </p>
    <p class="exporting-modal__note">
      {{ descriptionNote | transloco }}
    </p>
    <form [formGroup]="form" *ngIf="type !== EXPORT_TYPES.SEA">
      <div class="form-group">
        <label class="exporting-modal__label" for="surveys">
          {{ 'publication-options.modal.survey.label' | transloco }}
        </label>
        <app-select
          id="surveys"
          formControlName="surveys"
          [multi]="true"
          [placeholder]="'publication-options.modal.survey.placeholder' | transloco"
        >
          <app-option *ngFor="let survey of surveys" [multi]="true" [value]="survey">
            {{ survey.label }}
          </app-option>
        </app-select>
      </div>
      <div class="form-group">
        <label class="exporting-modal__label">
          {{ 'publication-options.modal.score.label' | transloco }}
        </label>
        <app-score-slider formControlName="score" min="1" max="10"></app-score-slider>
      </div>
      <div class="form-group">
        <label class="exporting-modal__label" for="date">
          {{ 'publication-options.modal.date.label' | transloco }}
        </label>
        <ins-datepicker-v2 id="date" formControlName="date"></ins-datepicker-v2>
      </div>
      <div class="form-group">
        <label class="exporting-modal__label" for="metadata">
          {{ 'publication-options.modal.metadata.label' | transloco }}
        </label>
        <app-select
          id="metadata"
          formControlName="metadata"
          [multi]="true"
          [placeholder]="'publication-options.modal.metadata.placeholder' | transloco"
        >
          <!-- TODO: add grouping -->
          <app-option *ngFor="let item of metadata" [value]="item">
            {{ item.outputLabel }}
          </app-option>
        </app-select>
      </div>
      <ng-container *ngIf="type === EXPORT_TYPES.JSON">
        <div class="form-group">
          <label class="exporting-modal__label" for="jsonLocation">
            {{ 'publication-options.modal.json-location.label' | transloco }}
          </label>
          <div class="exporting-modal__copy">
            <input id="jsonLocation" class="form-control" type="text" formControlName="jsonLocation" #linkInput />
            <i class="la la-copy la-copy--bordered" (click)="copyInputText(linkInput)"></i>
          </div>
        </div>
        <div class="form-group">
          <json-editor formControlName="jsonValue" [options]="jsonEditorOptions"></json-editor>
        </div>
      </ng-container>
    </form>

    <form [formGroup]="seaForm" *ngIf="type === EXPORT_TYPES.SEA">
      <ng-container *ngIf="!(formLoading$ | async); else loading">
        <div class="form-group">
          <label class="exporting-modal__label" for="client-id">
            {{ 'publication-options.modal.survey.client-id-label' | transloco }}
          </label>
          <input
            id="client-id"
            type="text"
            class="form-control"
            formControlName="clientId"
            [placeholder]="'publication-options.modal.survey.client-id-placeholder' | transloco"
          />
        </div>
        <div class="form-group">
          <label class="exporting-modal__label" for="client-secret">
            {{ 'publication-options.modal.survey.client-secret-label' | transloco }}
          </label>
          <input
            id="client-secret"
            type="text"
            class="form-control"
            formControlName="clientSecret"
            [placeholder]="'publication-options.modal.survey.client-secret-placeholder' | transloco"
          />
        </div>
        <div class="form-group">
          <label class="exporting-modal__label" for="seaSurveys">
            {{ 'publication-options.modal.survey.sea-label' | transloco }}
          </label>
          <app-select
            id="seaSurveys"
            formControlName="surveys"
            [multi]="true"
            [placeholder]="'publication-options.modal.survey.sea-placeholder' | transloco"
          >
            <app-option *ngFor="let survey of surveys" [value]="survey.id">
              {{ survey.label }}
            </app-option>
          </app-select>
        </div>
      </ng-container>
      <ng-template #loading>
        <loader></loader>
      </ng-template>
    </form>
  </ng-container>
  <ng-container modal-actions>
    <ng-container *ngIf="type === EXPORT_TYPES.EXCEL || EXPORT_TYPES.SEA">
      <button ins-button styling="link" (click)="close()">
        {{ 'publication-options.modal.btn.cancel' | transloco }}
      </button>
      <button [disabled]="form.invalid" (click)="exportExcelResponses()" ins-button *ngIf="type === EXPORT_TYPES.EXCEL">
        {{ 'publication-options.modal.btn.generate' | transloco }}
      </button>
    </ng-container>
    <ng-container *ngIf="type === EXPORT_TYPES.SEA" [formGroup]="seaForm">
      <button
        class="trash"
        ins-button-icon
        icon="trash"
        styling="secondary"
        size="m"
        (click)="removeFeedback()"
      ></button>
      <button
        ins-button
        type="submit"
        [loading]="buttonLoading$ | async"
        (click)="handleAddFeedback(seaForm.value)"
        [disabled]="seaForm.invalid"
      >
        {{ 'publication-options.modal.btn.sea' | transloco }}
      </button>
    </ng-container>
    <ng-container *ngIf="type === EXPORT_TYPES.JSON">
      <div class="exporting-modal__btns" fxLayout fxLayoutAlign="end center">
        <button ins-button (click)="close()">
          {{ 'publication-options.modal.btn.close' | transloco }}
        </button>
      </div>
    </ng-container>
  </ng-container>
</app-modal>

<app-script-generator *ngIf="type === EXPORT_TYPES.SCRIPT"></app-script-generator>
