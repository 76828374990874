import { NgModule } from '@angular/core';

import { InsBlobComponent } from './ins-blob.component';
import { CommonModule } from '@angular/common';
import { LoaderModule } from '@app/shared/components/loader/loader.module';

@NgModule({
  imports: [CommonModule, LoaderModule],
  exports: [InsBlobComponent],
  declarations: [InsBlobComponent],
  providers: []
})
export class BlobPreviewModule {}
