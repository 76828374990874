import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '@app/shared/utils/utils';

@Pipe({
  name: 'isDefined'
})
export class IsDefinedPipe implements PipeTransform {
  transform<T>(value: T): boolean {
    return Utils.isPresent(value);
  }
}
