import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef, EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnChanges,
  Optional, Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { DATA_LIST_HOST } from '@app/core/tokens/data-list-host.token';
import { DataListHost } from '@app/core/interfaces/data-list-host.interface';
import { Subject } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OPTION_GROUP_HOST } from '@app/core/tokens/option-group-host.token';
import { OptionGroupHost } from '@app/core/interfaces/option-group-host.interface';

@Component({
  selector: 'app-option',
  templateUrl: './option.component.html',
  styleUrls: ['../styles/_option.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '(click)': 'haltDisabledEvents($event)'
  }
})
@UntilDestroy()
export class OptionComponent<T = unknown> implements OnChanges, AfterViewChecked {
  changes = new Subject();
  groupLabel: string;
  previousTextContent: string;

  @Input()
  label?: string;
  @Input()
  value?: T;
  @Input()
  context: any;
  @Input()
  templateChangeCheck = false;
  @Input()
  actionIcon?: string;

  @Output()
  action = new EventEmitter<void>();

  _template: TemplateRef<unknown>;
  @Input()
  set template(tmpl: TemplateRef<unknown>) {
    if (tmpl) {
      this._template = tmpl;
    }
  }
  get template(): TemplateRef<unknown> {
    return this._template;
  }

  _ref: ElementRef;
  @ViewChild('ref', { read: ElementRef })
  set ref(ref: ElementRef) {
    if (ref) {
      this._ref = ref;
      this.changes.next(null);
    }
  }
  get ref(): ElementRef {
    return this._ref;
  }

  @HostBinding('class.is-disabled')
  _disabled: boolean;
  @Input()
  set disabled(value: any) {
    const newValue = coerceBooleanProperty(value);
    if (this._disabled !== newValue) {
      this._disabled = newValue;
    }
  }
  get disabled(): boolean {
    return this._disabled;
  }

  constructor(
    private element: ElementRef,
    private cdRef: ChangeDetectorRef,
    @Optional() @Inject(OPTION_GROUP_HOST) private groupHost: OptionGroupHost
  ) {
    if (this.groupHost) {
      this.groupLabel = this.groupHost.label;
    }
  }

  get viewValue(): string {
    const nativeElement = this.getHostElement() || this.element.nativeElement;
    return (nativeElement?.textContent || '').trim();
  }

  haltDisabledEvents(event: Event): void {
    if (this.disabled) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }

  ngOnChanges(): void {
    this.changes.next(null);
  }

  ngAfterViewChecked(): void {
    if (this.templateChangeCheck) {
      const templateTextContent = this.ref?.nativeElement?.textContent;

      if (this.previousTextContent !== templateTextContent) {
        this.previousTextContent = templateTextContent;
        setTimeout(() => this.changes.next(null));
      }
    }
  }

  private getHostElement(): HTMLElement {
    return this.element.nativeElement;
  }
}
