import { environment } from '@env/environment';

export abstract class Constants {
  static readonly API_DOCS_URL = 'https://developer.insocial.nl/';
  static readonly SCRIPT_GENERATOR_PREFIX = '(function() {\n' +
    '    var a = document.createElement(\'script\');\n' +
    '    a.src = \'' + environment.script_generator.version + '\';\n' +
    '    a.integrity = \'' + environment.script_generator.integrity + '\';\n' +
    '    a.async = \'true\';\n' +
    '    a.crossOrigin = \'anonymous\';\n' +
    '    a.addEventListener(\'load\', function() {\n' +
    '        surveyLoader.init(';
}
