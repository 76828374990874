import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { LanguageService } from '@app/core/services/language.service';
import { TranslocoService } from '@ngneat/transloco';
import { take } from 'rxjs/operators';
import { isValidDate } from 'rxjs/internal/util/isDate';
import { DatepickerConstants } from '@app/shared/components/datepickerV2/core/constants/datepicker.constants';
import { isArray } from 'ngx-bootstrap/chronos';
import { DateIndex, Ranges } from '@app/shared/components/datepickerV2/core/constants/datepicker.enum';
import { KeyCodes } from '@app/shared/enums/key-codes.enum';
import { Subject } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'ins-datepicker-v2',
  templateUrl: 'datepickerV2.component.html',
  styleUrls: ['datepickerV2.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DatepickerV2Component,
      multi: true,
    },
  ],
})
export class DatepickerV2Component implements ControlValueAccessor, OnInit {
  protected readonly constants = DatepickerConstants;
  private _date: Date[] = [];

  @ViewChild('rangepicker') rngpicker;
  @ViewChild('picker') pckr;

  @Input()
  rangePicker = true;

  @Input()
  minDate: Date;

  @Input()
  placeholder: string;

  rangepickerOnHide$ = new Subject<void>();
  datepickerOnHide$ = new Subject<void>();

  @Input()
  set date(value) {
    const valid = this.rangePicker
      ? isArray(value) && value.length > 1 && isValidDate(value[0]) && isValidDate(value[1])
      : isArray(value) && isValidDate(value[0]);

    if (valid || (isArray(value) && value.length === 0)) {
      this._date = value;
      this.onChange(this.rangePicker ? value : value[0] || null);
      this.onTouched(this.rangePicker ? value : value[0] || null);
    }
  }

  get date() {
    return this._date;
  }

  @Output()
  dateChange = new EventEmitter<Date | Date[]>();

  constructor(
    private cdRef: ChangeDetectorRef,
    private localService: BsLocaleService,
    private languageService: LanguageService,
    private translocoService: TranslocoService
  ) {}

  ngOnInit(): void {
    this.languageService.currentLang$.pipe(untilDestroyed(this)).subscribe((locale) => this.localService.use(locale));
    this.translocoService.langChanges$.subscribe(() => {
      for (const range of DatepickerConstants.RANGES) {
        this.translocoService
          .selectTranslate(DatepickerConstants.RANGE_PREFIX + range.key)
          .pipe(take(1))
          .subscribe((translation) => {
            range.label = translation;
          });
      }
    });
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(date: Date | Date[]): void {
    if (Array.isArray(date)) {
      this.date = date;
    } else if (date) {
      this.date = [date];
    } else {
      this.date = [];
    }
    this.cdRef.markForCheck();
  }

  protected rangepickerOnHide() {
    this.rangepickerOnHide$.next();
  }

  protected datepickerOnHide() {
    this.datepickerOnHide$.next();
  }

  protected rangepickerChange(range: Date[]) {
    this.date = range;
  }

  protected datepickerChange(date: Date) {
    this.date = date ? [date] : [];
  }

  protected addCustomInput() {
    const dpContainer = document.querySelector('.bs-datepicker-container');
    const manualInputContainer = document.createElement('div');
    manualInputContainer.classList.add('mt-3');
    const formGroup = this.createFormGroup();
    const input = this.createDateInput(
      DatepickerConstants.MANUAL_INPUT_ID.dateFrom,
      DatepickerConstants.MANUAL_INPUT_PLACEHOLDER
    );
    input.value = this.getDateString(DateIndex.FROM);
    formGroup.appendChild(input);
    manualInputContainer.appendChild(formGroup);

    if (this.rangePicker) {
      const formGroupTo = this.createFormGroup();
      const labelFrom = this.createDateLabel(
        this.translocoService.translate(DatepickerConstants.TRANSLATION_PREFIX + 'label.from'),
        DatepickerConstants.MANUAL_INPUT_ID.dateFrom
      );
      const labelTo = this.createDateLabel(
        this.translocoService.translate(DatepickerConstants.TRANSLATION_PREFIX + 'label.to'),
        DatepickerConstants.MANUAL_INPUT_ID.dateTo
      );
      const inputTo = this.createDateInput(
        DatepickerConstants.MANUAL_INPUT_ID.dateTo,
        DatepickerConstants.MANUAL_INPUT_PLACEHOLDER
      );
      inputTo.value = this.getDateString(DateIndex.TO);

      const rangeInputEvent = (event: KeyboardEvent) => {
        if (event.code === KeyCodes.ENTER || event.code === KeyCodes.NUMPAD_ENTER) {
          this.setDateManualRange(input, inputTo);
        }
      };

      input.addEventListener('keydown', rangeInputEvent);
      inputTo.addEventListener('keydown', rangeInputEvent);

      this.rangepickerOnHide$.pipe(take(1)).subscribe(() => {
        input.removeEventListener('keydown', rangeInputEvent);
        inputTo.removeEventListener('keydown', rangeInputEvent);
      });

      formGroup.prepend(labelFrom);
      formGroupTo.appendChild(labelTo);
      formGroupTo.appendChild(inputTo);
      manualInputContainer.appendChild(formGroupTo);
      manualInputContainer.classList.add('d-flex', 'gap-1');
    } else {
      const btn = document.createElement('button');
      btn.classList.add('btn', 'btn-secondary');
      btn.innerText = this.translocoService.translate(DatepickerConstants.TRANSLATION_PREFIX + 'btn.apply');

      const btnEvent = () => {
        this.setManualDate(input);
      };
      const inputEvent = (event) => {
        if (event.code === KeyCodes.ENTER || event.code === KeyCodes.NUMPAD_ENTER) {
          this.setManualDate(input);
        }
      };

      btn.addEventListener('click', btnEvent);
      input.addEventListener('keydown', inputEvent);

      this.datepickerOnHide$.pipe(take(1)).subscribe(() => {
        btn.removeEventListener('click', btnEvent);
        input.removeEventListener('keydown', inputEvent);
      });

      formGroup.appendChild(input);
      formGroup.appendChild(btn);
    }
    dpContainer.appendChild(manualInputContainer);

    if (!this.rangePicker) {
      const btnTdy = document.createElement('button');
      btnTdy.classList.add('btn', 'btn-secondary', 'w-100', 'p-2', 'mt-2');
      btnTdy.onclick = () => {
        this.date = [new Date()];
        this.pckr.hide();
        this.cdRef.markForCheck();
      };
      btnTdy.innerText = this.translocoService.translate(DatepickerConstants.RANGE_PREFIX + Ranges.TODAY);
      dpContainer.appendChild(btnTdy);
    }
  }

  private createFormGroup() {
    const formGroup = document.createElement('div');
    formGroup.classList.add(this.rangePicker ? 'form-group' : 'input-group', 'mb-0');
    return formGroup;
  }

  private createDateInput(id, placeholder) {
    const input = document.createElement('input');
    input.classList.add('form-control');
    input.style.height = '40px';
    input.placeholder = placeholder;
    input.id = id;
    return input;
  }

  private createDateLabel(text, forId) {
    const label = document.createElement('label');
    label.innerText = text;
    label.classList.add('fw-bold');
    label.setAttribute('for', forId);
    return label;
  }

  private getDateString(index) {
    return this.date && this.date.length > index
      ? `${this.date[index].getDate()} / ${this.date[index].getMonth() + 1} / ${this.date[index].getFullYear()}`
      : '';
  }

  private setDateManualRange(inputMin, inputMax) {
    const startDate = new Date(inputMin.value.trim());
    const endDate = new Date(inputMax.value.trim());
    const startDateIsValid = isValidDate(startDate);
    const endDateIsValid = isValidDate(endDate);

    if (startDateIsValid && endDateIsValid && startDate <= endDate) {
      this.date = [startDate, endDate];
      this.rngpicker.hide();
    } else {
      inputMin.classList.add('is-invalid');
      inputMax.classList.add('is-invalid');

      if (endDateIsValid && !(endDate < startDate)) {
        inputMax.classList.remove('is-invalid');
      }

      if (startDateIsValid && !(endDate < startDate)) {
        inputMin.classList.remove('is-invalid');
      }
    }
    this.cdRef.markForCheck();
  }

  private setManualDate(input) {
    const dateParts = input.value.split('/');
    const date = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);

    if (!isValidDate(date)) {
      input.classList.add('is-invalid');
    } else {
      this.date = [date];
      this.pckr.hide();
    }
    this.cdRef.markForCheck();
  }
}
