export interface SupportedLanguage {
  code: string;
  flagName?: string;
}

export const supportedLanguages: { code: string; translatable?: boolean; flagName?: string }[] = [
  { code: 'nl_NL', translatable: true },
  { code: 'en_GB', translatable: true },
  { code: 'en_CY', flagName: 'en_GB' },
  { code: 'en_US' },
  { code: 'de_DE', translatable: true },
  { code: 'fr_FR', translatable: true },
  { code: 'nl_BE' },
  { code: 'lu_LU', flagName: 'fr_LU' },
  { code: 'fr_BE', flagName: 'fr_FR' },
  { code: 'fr_LU', flagName: 'fr_FR' },
  { code: 'de_LU', flagName: 'de_DE' },
  { code: 'cs_CZ', translatable: true },
  { code: 'hu_HU', translatable: true },
  { code: 'pl_PL', translatable: true },
  { code: 'sk_SK', translatable: true },
  { code: 'ar_EG', translatable: true },
  { code: 'he_IL', translatable: true },
  { code: 'tr_TR', translatable: true },
  { code: 'da_DK', translatable: true },
  { code: 'et_EE', translatable: true },
  { code: 'lv_LV', translatable: true },
  { code: 'lt_LT', translatable: true },
  { code: 'fi_FI', translatable: true },
  { code: 'nb_NO', translatable: true },
  { code: 'sv_SE', translatable: true },
  { code: 'fr_FR', translatable: true },
  { code: 'it_IT', translatable: true },
  { code: 'pt_PT', translatable: true },
  { code: 'es_ES', translatable: true },
  { code: 'uk_UA', translatable: true },
  { code: 'ro_RO', translatable: true },
  { code: 'pap_AW' },
  { code: 'pap_CW' },
  { code: 'el_GR', translatable: true },
  { code: 'is_IS', translatable: true },
  { code: 'ja_JP', translatable: true },
  { code: 'ko_KR', translatable: true },
  { code: 'hr_HR', translatable: true },
];
