import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'app-detail-row',
  template: `
    <div class="form-group row detail">
      <td class="detail__label" [class.detail__label--bold]="bold">
        {{ label || '' }}
        <ins-badge *ngIf="tippyString" [tippy]="tippyString">i </ins-badge>
      </td>
      <div class="detail__content">
        <ng-content> </ng-content>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['detail-row.component.scss'],
})
export class DetailRowComponent {
  @Input()
  label: string;
  @Input()
  tippyString: string;

  private _bold: boolean;
  @Input()
  set bold(value: boolean) {
    const newValue = coerceBooleanProperty(value);

    if (newValue !== this._bold) {
      this._bold = newValue;
    }
  }
  get bold() {
    return this._bold;
  }
}
