import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({ selector: '[insFocus]' })
export class FocusDirective implements AfterViewInit {
  constructor(private host: ElementRef) {}

  ngAfterViewInit() {
    if (this.host) {
      this.host.nativeElement.focus();
    }
  }
}
