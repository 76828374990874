import { NgModule } from '@angular/core';

import { NewFeatureAnnouncementComponent } from './new-feature-announcement.component';
import { ButtonModule } from '@app/shared/components/button/button.module';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  imports: [ButtonModule, TranslocoModule],
  exports: [NewFeatureAnnouncementComponent],
  declarations: [NewFeatureAnnouncementComponent],
  providers: []
})
export class NewFeatureAnnouncementModule {}
