import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Utils } from '@app/shared/utils/utils';
import { UploadApiToken } from '@app/shared/modules/upload/upload-api-service.token';
import { UploadServiceInterface } from '@app/shared/modules/upload/upload-service.model';
import { combineLatest, Observable } from 'rxjs';
import { UploadEvent } from '@app/core/interfaces/image-upload.interface';
import { map } from 'rxjs/operators';
import { FileReaderService } from '@app/dashboard/customer/core/services/filereader.service';

@Component({
  selector: 'ins-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileUploaderComponent,
      multi: true,
    },
  ],
})
export class FileUploaderComponent implements OnDestroy, ControlValueAccessor {
  @Input()
  icon = 'file';
  @Input()
  previewTemplate: TemplateRef<any>;
  @Input()
  formType: 'form-part' | 'base64' | 'file' = 'file';
  @Input()
  filesProgress: Record<string, Observable<UploadEvent>> = {};
  @Input()
  fileInputId = 'fileInput';

  _disableImagePreview = false;
  @Input()
  set disableImagePreview(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    if (newValue !== value) {
      this._disableImagePreview = newValue;
    }
  }

  get disableImagePreview(): boolean {
    return this._disableImagePreview;
  }

  private _disablePreview = false;
  @Input()
  set disablePreview(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    if (newValue !== value) {
      this._disablePreview = newValue;
    }
  }

  get disablePreview(): boolean {
    return this._disablePreview;
  }

  private _multi = false;
  @Input()
  set multi(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    if (newValue !== value) {
      this._multi = newValue;
    }
  }

  get multi(): boolean {
    return this._multi;
  }

  private _allowedExtensions: string;
  @Input()
  set allowedExtensions(extensions: string[] | string) {
    if (Array.isArray(extensions)) {
      this._allowedExtensions = extensions.join(', ');
    } else {
      this._allowedExtensions = extensions;
    }
  }

  get allowedExtensions() {
    return this._allowedExtensions;
  }

  @Output()
  filesChange = new EventEmitter<File | File[]>();
  @Output()
  filePaths = new EventEmitter<any[]>();
  @Output()
  fileRemove = new EventEmitter<any>();

  /**
   * This fires immediately when a file is selected, before it's uploaded
   */
  @Output()
  inputChange = new EventEmitter<void>();

  files: File[] | null = [];

  @ViewChild('fileInput')
  fileInput: ElementRef<HTMLInputElement>;

  onChange = (_: any) => {};
  onTouched = () => {};

  constructor(
    private cdRef: ChangeDetectorRef,
    private fileReader: FileReaderService,
    @Optional() @Inject(UploadApiToken) private uploadApiService: UploadServiceInterface
  ) {}

  onDropped(files: File[]): void {
    this.handleFileUpload(files);
  }

  onUploaded(files: File[]): void {
    this.inputChange.emit();
    this.handleFileUpload(files);
  }

  private handleFileUpload(files: File[]): void {
    if (files instanceof FileList) {
      files = Utils.fileListToArray(files);
    }
    const filteredFiles = files.map((file) => {
      const fileExtension = Utils.getFileExtension(file.name);
      if (!this.allowedExtensions || this.allowedExtensions.includes(fileExtension.toLowerCase())) {
        return file;
      }
    });

    if (filteredFiles.length) {
      this.files = filteredFiles;
      if (this.formType === 'form-part') {
        this.serverUpload(filteredFiles);
      } else if (this.formType === 'base64') {
        this.base64Upload(filteredFiles);
      } else {
        this.emitChangeEvent(filteredFiles);
      }
    }
  }

  serverUpload(files: File[]): void {
    this.uploadToServer(files).subscribe((result) => {
      if (result) {
        this.emitChangeEvent(files);
      }
    });
  }

  base64Upload(files: File[]): void {
    this.fileReader.readFiles(files).subscribe((base64Files) => {
      this.emitChangeEvent(base64Files);
    });
  }

  removeUploadedFile(index?: number): void {
    if (this.fileInput?.nativeElement) {
      this.fileInput.nativeElement.value = '';
    }
    if (index === null || index === undefined) {
      this.resetUploadedFiles();
    } else {
      this.files?.splice(index, 1);
    }

    this.fileRemove.emit(index);
    this.emitChangeEvent(this.files);
    this.cdRef.markForCheck();
  }

  openUploadDialog(): void {
    this.fileInput.nativeElement.click();
  }

  resetUploadedFiles(): void {
    if (this.fileInput) {
      this.fileInput.nativeElement.value = '';
    }
    this.files = null;
    this.onChange(null);
    this.cdRef.markForCheck();
  }

  writeValue(value: null): void {
    this.resetUploadedFiles();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private uploadToServer(files: File[]): Observable<object[] | null> {
    this.filesProgress = this.uploadApiService.upload(files);
    const results$ = Object.keys(this.filesProgress).map((key) => this.filesProgress[key]);
    return combineLatest(results$).pipe(
      map((results) => {
        const allCompleted = results.every((result) => result.status === 'ok');
        if (allCompleted) {
          const mappedResults = results.map(({ result }) => result);
          this.filePaths.emit(mappedResults);
          return mappedResults;
        }
        return null;
      })
    );
  }

  private emitChangeEvent(files: any[]): void {
    if (this._multi) {
      this.onChange(this.files);
      this.filesChange.emit(this.files);
    } else {
      const uploadedFile = this.files ? this.files[0] : null;
      this.onChange(uploadedFile);
      this.filesChange.emit(uploadedFile);
    }
    this.cdRef.markForCheck();
  }

  ngOnDestroy(): void {
    this.resetUploadedFiles();
  }
}
