import { ChangeDetectionStrategy, Component, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@Component({
  selector: 'app-color-input',
  template: `
    <div class="color-row">
      <div class="color-row__item">
        <input
          type="text"
          (blur)="onTouched()"
          [formControl]="hexControl"
          [placeholder]="'components.color-input.placeholder' | transloco"
          class="form-control"
        />
      </div>
      <div class="color-row__item color-row__item--color">
        <input [formControl]="colorPickerControl" type="color" [value]="color" class="color-row__color-box">
      </div>
    </div>
  `,
  styleUrls: ['color-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColorInputComponent),
      multi: true
    }
  ]
})
@UntilDestroy()
export class ColorInputComponent implements OnInit, ControlValueAccessor {
  hexControl = new UntypedFormControl();
  colorPickerControl = new UntypedFormControl();

  private _color: string;

  get color(): string {
    return this._color;
  }

  set color(color: string) {
    if (this._color !== color) {
      this._color = color;
      this.onChange(color);
    }
  }

  onChange = (_: any) => {
  };
  onTouched = () => {
  };

  ngOnInit(): void {
    this.hexControl.valueChanges.pipe(untilDestroyed(this)).subscribe(hexValue => {
      this.colorPickerControl.setValue(hexValue, {emitEvent: false});
      this.color = hexValue;
    });

    this.colorPickerControl.valueChanges.pipe(untilDestroyed(this)).subscribe(colorPicker => {
      this.hexControl.setValue(colorPicker, {emitEvent: false});
      this.color = colorPicker;
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(color: any): void {
    if (color === null) {
      this.hexControl.reset();
    }

    if (color) {
      this.hexControl.setValue(color);
      this.onChange(color);
    }
  }
}
