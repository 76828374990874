import { format } from 'date-fns';
import { DateConstants } from '@app/core/constants/date';

export abstract class DateUtils {
  static formatDates(dates: Date | Date[], dateFormat = DateConstants.STRING_DATE): string | string[] {
    if (Array.isArray(dates)) {
      if (dates.length) {
        return dates.map((date: Date) => (date ? format(date, dateFormat) : null));
      }
      return [];
    } else if (dates) {
      return format(dates, dateFormat);
    }
    return null;
  }

  static formatLocaleDates(dates: string | string[]): Date | Date[] {
    if (Array.isArray(dates)) {
      if (dates.length && dates.every(date => Boolean(date))) {
        return dates.map((date: string) => (date ? new Date(new Date(date).valueOf() +
          Math.max(0, new Date().getTimezoneOffset()) * 60000) : null));
      }
      return [];
    } else if (dates) {
      return new Date(dates);
    }
    return null;
  }
}
