import { Inject, Injectable } from '@angular/core';
import { UtilsConfigService } from '../config.service';
import { UtilsConfig } from '../../models/models';
import { Logger } from './logger';

const noop = (): any => undefined;

@Injectable({
  providedIn: 'root'
})
export class ConsoleLoggerService implements Logger {

  constructor(@Inject(UtilsConfigService) private config: UtilsConfig) {

  }

  get info() {
    if (this.config.isDebugMode) {
      return console.info.bind(console);
    } else {
      return noop;
    }
  }

  get warn() {
    if (this.config.isDebugMode) {
      return console.warn.bind(console);
    } else {
      return noop;
    }
  }

  get error() {
    if (this.config.isDebugMode) {
      return console.error.bind(console);
    } else {
      return noop;
    }
  }

}
