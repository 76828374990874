import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AUTH_FEATURE_KEY, AuthState, AuthPartialState } from './auth.reducer';

// Lookup the 'Auth' feature state managed by NgRx
export const getAuthState = createFeatureSelector<AuthState>(AUTH_FEATURE_KEY);

export const getTokenLoaded = createSelector(getAuthState, (state: AuthState) => state.loaded);

export const getIsLoggedIn = createSelector(getAuthState, (state: AuthState) => state.loggedIn);

export const getAuthError = createSelector(getAuthState, (state: AuthState) => state.error);

export const getJwtToken = createSelector(getAuthState, (state: AuthState) => {
  if (state.token) {
    return state.token.access_token;
  }
});
