import { InjectionToken, Provider } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, startWith, switchMap } from 'rxjs/operators';

export const CURRENT_ROUTE = new InjectionToken<Observable<string>>('Get step from router data');

export const CURRENT_ROUTE_PROVIDER: Provider[] = [
  {
    provide: CURRENT_ROUTE,
    deps: [Router],
    useFactory: getRouteStepFactory
  }
];

export function getRouteStepFactory(router: Router): Observable<string> {
  return router.events.pipe(
    startWith('start'),
    filter(event => event instanceof NavigationEnd || event === 'start'),
    switchMap(() => of(router.url))
  );
}
