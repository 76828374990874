import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable, ReplaySubject } from 'rxjs';
import { UploadEvent, UploadStatus } from '@app/core/interfaces/image-upload.interface';

@Injectable({
  providedIn: 'root',
})
export class ImageUploadService {
  apiUrl = environment.api;

  constructor(private httpService: HttpClient) {}

  upload(files: File[]): Record<string, Observable<UploadEvent>> {
    const status: Record<string, Observable<UploadEvent>> = {};

    files.forEach((file: File) => {
      const formData = new FormData();
      formData.append('image', file, file.name);
      const result = new ReplaySubject<UploadEvent>();
      status[file.name] = result.asObservable();
      this.httpService
        .post(this.apiUrl + '/customers/{customerId}/uploads', formData, {
          reportProgress: true,
          observe: 'events',
        })
        .subscribe((event) => {
          result.next(this.getStatus(event));
          if (event instanceof HttpResponse) {
            // Close the progress-stream if we get an answer form the API
            // The upload is complete
            result.complete();
          }
        });
    });

    return status;
  }

  getStatus(event: HttpEvent<any>): UploadEvent {
    switch (event.type) {
      case HttpEventType.Sent:
        return {
          progress: 0,
          result: null,
          status: UploadStatus.IN_PROGRESS,
        };
      case HttpEventType.UploadProgress:
        const percentage = Math.round((100 * event.loaded) / (event.total ?? 0));
        return {
          progress: percentage,
          result: null,
          status: UploadStatus.IN_PROGRESS,
        };
      case HttpEventType.Response:
        return {
          progress: 100,
          result: event.body,
          status: UploadStatus.OK,
        };
      default:
        return {
          progress: 100,
          result: null,
          status: UploadStatus.ERROR,
        };
    }
  }
}
