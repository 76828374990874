import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SidebarBrandComponent } from '@app/core/layout/main-layout/sidebar/components/sidebar-brand/sidebar-brand.component';
import { SidebarItemComponent } from '@app/core/layout/main-layout/sidebar/components/sidebar-item/sidebar-item.component';
import { SharedModule } from '@app/shared/shared.module';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { SidebarComponent } from './sidebar.component';

@NgModule({
  imports: [CommonModule, SharedModule, PerfectScrollbarModule, CollapseModule],
  exports: [SidebarComponent],
  declarations: [SidebarComponent, SidebarItemComponent, SidebarBrandComponent],
  providers: []
})
export class SidebarModule {}
