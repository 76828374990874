import { Action, createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import * as ResponsesActions from './responses.actions';
import { IResponse, IResponseListCounters } from '@app/shared/models/responses/responses.model';
import { IMeta } from '@app/shared/models/common/metadata.model';
import { ResponseLabelInterface } from '@app/dashboard/response-detail/core/interfaces/response-label.interface';
import { IResponseParams } from '@app/dashboard/responses/core/models/responses-params.interface';

export const responsesFeatureKey = 'responses';

export interface State extends EntityState<IResponse> {
  selectedResponseIds: string[] | null;
  meta: IMeta;
  counters: IResponseListCounters;
  loading: boolean;
  customerLabels: ResponseLabelInterface[] | null;
  loadingCustomerLabels: boolean | null;
  showTranslations: boolean;
  queryParams: IResponseParams;
  pageNumber: number;
}

export function selectResponseId(a: IResponse): string {
  return a.id;
}

export const adapter = createEntityAdapter<IResponse>({
  selectId: selectResponseId,
  sortComparer: false
});

export const initialState: State = adapter.getInitialState({
  selectedResponseIds: null,
  counters: null,
  meta: null,
  loading: false,
  customerLabels: null,
  loadingCustomerLabels: null,
  showTranslations: true,
  queryParams: null,
  pageNumber: 0
});

const responsesReducer = createReducer(
  initialState,
  on(ResponsesActions.loadResponses, (state, { params }) => ({
    ...state,
    loading: true,
    queryParams: params
  })),
  on(ResponsesActions.loadNextResponses, (state, { params }) => ({
    ...state,
    loading: true,
    queryParams: params as IResponseParams
  })),
  on(ResponsesActions.loadPreviousResponses, (state, { params }) => ({
    ...state,
    loading: true,
    queryParams: params as IResponseParams
  })),
  on(ResponsesActions.loadResponsesSuccess, (state, { responses, meta }) =>
    adapter.setAll(responses, {
      ...state,
      loading: false,
      meta
    })
  ),
  on(ResponsesActions.loadNextResponsesSuccess, (state, { responses, meta }) =>
    adapter.addMany(responses, {
      ...state,
      loading: false,
      meta
    })
  ),
  on(ResponsesActions.loadPreviousResponsesSuccess, (state, { responses, meta }) =>
    adapter.setAll([...responses, ...selectAll(state)], {
      ...state,
      loading: false,
      meta
    })
  ),
  on(ResponsesActions.loadCountersSuccess, (state, { counters }) => ({
    ...state,
    counters
  })),
  on(ResponsesActions.loadCustomerLabels, state => ({
    ...state,
    loadingCustomerLabels: true
  })),
  on(ResponsesActions.loadCustomerLabelsSuccess, (state, { customerLabels }) => ({
    ...state,
    customerLabels,
    loadingCustomerLabels: false
  })),
  on(ResponsesActions.loadCustomerLabelsFailed, (state, { error }) => ({
    ...state,
    loadingCustomerLabels: false
  })),
  on(ResponsesActions.selectResponses, (state, { selectedIds }) => ({
    ...state,
    selectedResponseIds: selectedIds
  })),
  on(ResponsesActions.updateResponsesSuccess, (state, { updates }) => adapter.updateMany(updates, state)),
  on(ResponsesActions.deleteResponsesSuccess, (state, { ids }) => adapter.removeMany(ids, state)),
  on(ResponsesActions.updateResponsesSuccess, ResponsesActions.deleteResponsesSuccess, state => ({
    ...state,
    selectedResponseIds: []
  })),
  on(ResponsesActions.showTranslations, (state, { show }) => ({
    ...state,
    showTranslations: show
  })),
  on(ResponsesActions.resetStore, () => initialState)
);

export const { selectEntities, selectIds, selectAll } = adapter.getSelectors();

export function reducer(state: State | undefined, action: Action) {
  return responsesReducer(state, action);
}
