import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'no-results',
  template: `
    <div class="no-results text-center">
      <i class="la la-question-circle"></i>
      <h2 class="no-results__title">{{ 'no-results.title' | transloco }}</h2>
      <p class="no-results__text">
        {{ 'no-results.message' | transloco }}
      </p>
    </div>
  `,
  styleUrls: ['./no-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoResultsComponent {}
