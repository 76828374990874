import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private readonly _apiUrl = environment.api;

  constructor(private http: HttpClient) {
  }

  get<T>(url: string, params?: HttpParams | Record<string, any>, headers?: Record<string, any>): Observable<T> {
    return this.http
      .get<T>(`${this._apiUrl}${url}`, {
        headers: this.headers(headers),
        params
      })
      .pipe(catchError(this.handleError));
  }

  post<T>(url: string, data: object = {}): Observable<T> {
    return this.http
      .post<T>(`${this._apiUrl}${url}`, JSON.stringify(data), {
        headers: this.headers()
      })
      .pipe(retry(1), catchError(this.handleError));
  }

  put<T>(url: string, data: object = {}): Observable<T> {
    return this.http
      .put<T>(`${this._apiUrl}${url}`, JSON.stringify(data), {
        headers: this.headers()
      })
      .pipe(retry(1), catchError(this.handleError));
  }

  delete<T>(url: string, data?: object): Observable<T> {
    return this.http
      .delete<T>(`${this._apiUrl}${url}`, {
        headers: this.headers(),
        body: data ? JSON.stringify(data) : undefined
      })
      .pipe(retry(1), catchError(this.handleError));
  }

  headers(extra: Record<string, any> = {}): HttpHeaders {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ...extra
    };

    return new HttpHeaders(headers);
  }

  handleError(error) {
    return throwError(error);
  }
}
