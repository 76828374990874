import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { RootFacade } from '@app/core/root-store/root.facade';
import { LanguageService } from '@app/core/services/language.service';
import { environment } from '@env/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthFacade, LocalStorageLoggedInService } from 'ins-auth';
import { combineLatest } from 'rxjs';
import { filter, first, map, pluck, take } from 'rxjs/operators';
import { UpdateService } from './core/services/update.service';
import { NavigationUtils } from './shared/utils/navigation.utils';
import { TranslocoService } from '@ngneat/transloco';
import { TranslocoEvents } from '@ngneat/transloco/lib/types';
import { ScopeFacade } from '@app/core/root-store/scope-store/scope.facade';
import { NavigationService } from '@app/core/services/navigation.service';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  showHeader = false;
  showSidebar = false;
  hideLoader = false;
  fullscreen = false;
  previousUrl: string = null;
  currentUrl: string = null;
  environment = environment;
  created = false;
  appLoaded$ = combineLatest([
    this.rootFacade.appIsLoaded$,
    this.translocoService.events$.pipe(filter((event: TranslocoEvents) => event.type === 'translationLoadSuccess')),
  ]);

  constructor(
    private updateService: UpdateService,
    private localStorageLoggedIn: LocalStorageLoggedInService,
    private translocoService: TranslocoService,
    private languageService: LanguageService,
    private activatedRoute: ActivatedRoute,
    private authFacade: AuthFacade,
    private rootFacade: RootFacade,
    private scopeFacade: ScopeFacade,
    private router: Router,
    private readonly navigationService: NavigationService
  ) {}

  ngOnInit(): void {
    this.updateService.initCheckingForUpdates();
    this.languageService.init();
    this.localStorageLoggedIn
      .getItem()
      .pipe(first())
      .subscribe((isLoggedIn: boolean) => {
        if (isLoggedIn) {
          this.authFacade.checkSession();
        }
      });

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.url;
      this.navigationService.setPreviousUrl(this.previousUrl);
    });

    this.router.events
      .pipe(
        filter((event: Event) => event instanceof NavigationEnd || event instanceof NavigationStart),
        untilDestroyed(this)
      )
      .subscribe((event: Event) => {
        if (event instanceof NavigationStart) {
          if (this.activatedRoute?.firstChild) {
            const firstChildRouteParams = this.activatedRoute.firstChild.params;
            const redirectUrl$ = NavigationUtils.processNavigationUrl(
              event.url,
              combineLatest([this.scopeFacade.scopeId$, firstChildRouteParams.pipe(pluck('scopeId'))]).pipe(
                map((scopeIds: string[]) => scopeIds[0] ?? scopeIds[1]),
                filter((scopeId: string) => !!scopeId)
              )
            );
            if (redirectUrl$) {
              redirectUrl$.pipe(take(1)).subscribe((url: string) => this.router.navigateByUrl(url));
            }
          }
        }
        if (event instanceof NavigationEnd) {
          this.showHeader = this.activatedRoute.firstChild.snapshot.data.showHeader !== false;
          this.showSidebar = this.activatedRoute.firstChild.snapshot.data.showSidebar !== false;
          this.hideLoader = this.activatedRoute.firstChild.snapshot.data.showLoader === false;
          this.fullscreen = !!this.activatedRoute.firstChild.snapshot.data.fullscreen;
        }
      });
  }
}
