import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ScopeFacade } from '@app/core/root-store/scope-store/scope.facade';
import { switchMap, take } from 'rxjs/operators';

@Injectable()
export class ScopeInterceptor implements HttpInterceptor {
  readonly MATCHER = /{\s*(customerId|scopeId)+\s*}/;

  constructor(private scopeFacade: ScopeFacade) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.MATCHER.test(request.url)) {
      return this.scopeFacade.scopeId$.pipe(
        take(1),
        switchMap(scopeId => {
          const modifiedRequest = scopeId
            ? request.clone({
                url: request.url.replace(this.MATCHER, scopeId)
              })
            : request;

          return next.handle(modifiedRequest);
        })
      );
    }

    return next.handle(request);
  }
}
