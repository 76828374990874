import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-add-button',
  template: `
    <button type="button" class="add-button" (click)="emitAddBtn()">
      <span class="button-icon">
        <i class="la la-plus"></i>
      </span>
      <span class="button-label">
        {{ title }}
      </span>
    </button>
  `,
  styleUrls: ['./add-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddButtonComponent {
  @Input()
  title: string;

  @Output()
  emitter = new EventEmitter();

  emitAddBtn(): void {
    this.emitter.emit();
  }
}
