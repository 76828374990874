import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

export function queryPaginated<T>(http: HttpClient, baseUrl: string, filters: object): Observable<T> {
  let params = new HttpParams();
  const url = baseUrl;
  if (filters && Object.values(filters).length !== 0) {
    Object.keys(filters)
      .sort()
      .forEach(key => {
        const value = filters[key];
        if (value !== null && value !== undefined) {
          params = params.set(key, value.toString());
        }
      });
  }

  return http.get<T>(url, {
    params
  });
}
