import { Action, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromProfile from '../state/profile-state';
import * as fromProfileReducer from './user.reducer';

export const reducers = (state: fromProfile.State | undefined, action: Action) =>
  fromProfileReducer.reducer(state, action);

const getUserStore = createFeatureSelector<fromProfile.State>('user');

export const getUserInfo = createSelector(getUserStore, fromProfileReducer.getCustomer);

export const userInfoStoreLoading = createSelector(getUserStore, fromProfileReducer.getLoading);

export const userStoreLoaded = createSelector(getUserStore, (state) => state.loaded);

export const getUserRole = createSelector(getUserStore, (state) => {
  if (state && state.user) {
    return state.user.roles;
  }
  return [];
});

export const isAdmin = createSelector(getUserRole, (state: string[]) => state?.includes('ROLE_ADMIN'));
