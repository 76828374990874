import { Directive, forwardRef, HostBinding, Input } from '@angular/core';
import { OPTION_GROUP_HOST } from '@app/core/tokens/option-group-host.token';

@Directive({
  selector: 'ins-opt-group',
  host: {
    role: 'group'
  },
  providers: [
    {
      provide: OPTION_GROUP_HOST,
      useExisting: forwardRef(() => OptgroupDirective)
    }
  ]
})
export class OptgroupDirective {
  @Input()
  label: string | number = '';
}
