import { IUser } from '@app/shared/models/accounts/user.model';
export interface State {
  user: IUser | null;
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  user: null,
  loaded: false,
  loading: false
};
