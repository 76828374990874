import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabDirective } from '@app/shared/components/tabs/tab.component';
import { TabGroupComponent } from '@app/shared/components/tabs/tab-group.component';

@NgModule({
  imports: [CommonModule],
  exports: [TabDirective, TabGroupComponent],
  declarations: [TabDirective, TabGroupComponent],
  providers: []
})
export class InsTabsModule {}
