<app-modal class="script-modal" [title]="'Pop-up script generator'" (onClose)="close()">
  <ng-container modal-body>
    <form [formGroup]="scriptForm" class="script-modal__form">
      <div class="script-modal__parameters">
        <label class="script-modal__label" for="surveys">
          {{ 'publication-options.modal.script.survey' | transloco }}
        </label>
        <app-select
          id="surveys"
          formControlName="surveyId"
          [placeholder]="'publication-options.modal.script.survey-placeholder' | transloco"
        >
          <app-option *ngFor="let survey of surveys" [multi] [value]="survey">
            {{ survey.label }}
          </app-option>
        </app-select>
      </div>
      <div class="script-modal__parameters__switches">
        <div>
          <label class="script-modal__label" for="buttonVisibility">
            {{ 'publication-options.modal.script.button-visibility' | transloco }}
          </label>
          <app-switch id="buttonVisibility" formControlName="buttonVisibility"></app-switch>
        </div>
        <div>
          <label class="script-modal__label" for="tracking">
            {{ 'publication-options.modal.script.tracking' | transloco }}
          </label>
          <app-switch id="tracking" formControlName="tracking"></app-switch>
        </div>
      </div>
      <div class="script-modal__parameters__metadata">
        <div>
          <label class="script-modal__label" for="metadata">
            {{ 'publication-options.modal.script.metadata' | transloco }}
          </label>
          <app-select
            id="metadata"
            formControlName="metadata"
            [placeholder]="'publication-options.modal.script.metadata-placeholder' | transloco"
          >
            <app-option *ngFor="let item of metadata" [value]="item">
              {{ item.outputLabel }}
            </app-option>
          </app-select>
        </div>
        <div *ngIf="scriptForm?.value.metadata">
          <label class="script-modal__label" for="metadataValue">
            {{ 'publication-options.modal.script.metadata-value' | transloco }}
          </label>
          <input
            id="metadataValue"
            type="text"
            class="form-control"
            formControlName="metadataValue"
            [placeholder]="'publication-options.modal.script.metadata-value-placeholder' | transloco"
          />
        </div>
      </div>
      <div class="script-modal__parameters">
        <label class="script-modal__label" for="buttonText">
          {{ 'publication-options.modal.script.button-text' | transloco }}
        </label>
        <input
          id="buttonText"
          type="text"
          class="form-control"
          formControlName="buttonText"
          [placeholder]="'publication-options.modal.script.button-text-placeholder' | transloco"
        />
      </div>
      <div class="script-modal__parameters">
        <label class="script-modal__label" for="renderTypes">
          {{ 'publication-options.modal.script.render-type' | transloco }}
        </label>
        <app-select
          id="renderTypes"
          formControlName="surveyRenderType"
          [multi]="true"
          [placeholder]="'publication-options.modal.script.render-type-placeholder' | transloco"
        >
          <app-option *ngFor="let type of renderTypes" [multi] [value]="type">
            {{ type }}
          </app-option>
        </app-select>
      </div>
      <div class="script-modal__parameters">
        <label class="script-modal__label" for="buttonColor">
          {{ 'publication-options.modal.script.background-color' | transloco }}
        </label>
        <app-color-input id="buttonColor" formControlName="buttonColor"></app-color-input>
      </div>
      <div class="script-modal__parameters" *ngIf="showSessions">
        <label class="script-modal__label" for="sessionTypes">
          {{ 'publication-options.modal.script.session-type' | transloco }}
        </label>
        <app-select
          id="sessionTypes"
          formControlName="session"
          [placeholder]="'publication-options.modal.script.session-type-placeholder' | transloco"
        >
          <app-option *ngFor="let type of sessionTypes" [value]="type">
            {{ type }}
          </app-option>
        </app-select>
      </div>
      <div class="script-modal__parameters">
        <label class="script-modal__label" for="trackingSettings">
          {{ 'publication-options.modal.script.tracking-settings' | transloco }}
        </label>
        <app-select
          #trackingSettings
          id="trackingSettings"
          formControlName="trackingSettings"
          [multi]="true"
          [placeholder]="'publication-options.modal.script.tracking-settings-placeholder' | transloco"
        >
          <app-option *ngFor="let setting of trackingSettingsOptions" [multi] [value]="setting">
            {{ setting }}
          </app-option>
        </app-select>
      </div>

      <div class="script-modal__parameters">
        <label class="script-modal__label" for="buttonBorder">
          {{ 'publication-options.modal.script.border-radius' | transloco }}
        </label>
        <input
          id="buttonBorder"
          type="text"
          class="form-control"
          formControlName="buttonBorder"
          [placeholder]="'publication-options.modal.script.border-radius-placeholder' | transloco"
        />
      </div>
      <ng-container *ngIf="showTargetElement">
        <div class="script-modal__parameters">
          <label class="script-modal__label" for="targetElement">
            {{ 'publication-options.modal.script.target-element' | transloco }}
          </label>
          <input
            id="targetElement"
            type="text"
            class="form-control"
            formControlName="targetElement"
            [placeholder]="'publication-options.modal.script.target-element-placeholder' | transloco"
            [readOnly]="!showTargetElement"
          />
        </div>
      </ng-container>
      <ng-container *ngIf="showCustomBtnSelector">
        <div class="script-modal__parameters">
          <label class="script-modal__label" for="targetElement">
            {{ 'publication-options.modal.script.custom-btn-selector' | transloco }}
          </label>
          <input
            id="customBtnSelector"
            type="text"
            class="form-control"
            formControlName="customBtnSelector"
            [placeholder]="'publication-options.modal.script.custom-btn-selector-placeholder' | transloco"
            [readOnly]="!showCustomBtnSelector"
          />
        </div>
      </ng-container>
      <div class="script-modal__parameters">
        <label class="script-modal__label" for="buttonTextColor">
          {{ 'publication-options.modal.script.text-color' | transloco }}
        </label>
        <app-color-input id="buttonTextColor" formControlName="buttonTextColor"></app-color-input>
      </div>
      <div class="script-modal__parameters">
        <label class="script-modal__label" for="surveyRenderDelay">
          {{ 'publication-options.modal.script.seconds-on-site' | transloco }}
        </label>
        <input
          id="surveyRenderDelay"
          type="text"
          class="form-control"
          formControlName="secondsOnSite"
          [placeholder]="'publication-options.modal.script.seconds-placeholder' | transloco"
          [readOnly]="!showSecondsOnSite"
        />
      </div>

      <div class="script-modal__parameters">
        <label class="script-modal__label" for="chance">
          {{ 'publication-options.modal.script.chance' | transloco }}
        </label>
        <input
          id="chance"
          type="text"
          class="form-control"
          formControlName="chance"
          [readonly]="true"
          [placeholder]="'publication-options.modal.script.chance-placeholder' | transloco"
        />
      </div>
      <div class="script-modal__parameters">
        <label class="script-modal__label" for="scrollDepth">
          {{ 'publication-options.modal.script.scroll-depth' | transloco }}
        </label>
        <input
          id="scrollDepth"
          type="text"
          class="form-control"
          formControlName="scrollDepth"
          [placeholder]="'publication-options.modal.script.scroll-depth-placeholder' | transloco"
          [readOnly]="!showScrollDepth"
        />
      </div>

      <div class="script-modal__parameters__logo">
        <label class="script-modal__label" for="buttonIcon">
          {{ 'publication-options.modal.script.icon' | transloco }}
        </label>

        <div id="buttonIcon" class="upload-container">
          <ins-file-uploader
            (filePaths)="setBackground($event)"
            (fileRemove)="removeLogo()"
            fileInputId="bgFileInput"
            formType="form-part"
            icon="picture-o"
            [allowedExtensions]="['.jpeg, .jpg, .png, .svg']"
          ></ins-file-uploader>
        </div>
      </div>
      <div class="script-modal__parameters__script">
        <label class="script-modal__label">
          {{ 'publication-options.modal.script.generated-script' | transloco }}
        </label>
        <pre class="line-numbers"><code><i
          *ngFor="let line of script"> {{line}} </i></code></pre>
      </div>

      <div class="script-modal__parameters__button">
        <label class="script-modal__label" for="buttonPreview">
          {{ 'publication-options.modal.script.button-example' | transloco }}
        </label>
        <div class="button-preview-container" id="buttonPreview">
          <button
            class="button-preview"
            [style.background-color]="getBackgroundColor()"
            [style.color]="getTextColor()"
            [style.border-radius.px]="getButtonBorder()"
            [hidden]="!getButtonVisibility()"
            ins-button
          >
            <img [hidden]="!getLogo()" [src]="getLogo()" />
            {{ getButtonText() }}
          </button>
        </div>
      </div>
    </form>
  </ng-container>

  <ng-container modal-actions>
    <button (click)="close()" ins-button [styling]="'link'">
      {{ 'publication-options.modal.script.cancel' | transloco }}
    </button>
    <div class="script-modal__btns" fxLayout fxLayoutAlign="end center">
      <button (click)="writeAndDownload()" [disabled]="scriptForm.invalid" ins-button>
        {{ 'publication-options.modal.script.download' | transloco }}
      </button>
    </div>
  </ng-container>
</app-modal>
