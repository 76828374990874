import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonGroupComponent } from '@app/shared/components/button-group/button-group.component';

@NgModule({
  imports: [CommonModule],
  exports: [ButtonGroupComponent],
  declarations: [ButtonGroupComponent],
  providers: []
})
export class ButtonGroupModule {}
