import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ElementObserverDirective } from '@app/shared/directives/element-observer/element-observer.directive';

@NgModule({
  declarations: [ElementObserverDirective],
  exports: [ElementObserverDirective],
  imports: [CommonModule]
})
export class ElementObserverModule {}
