import { InjectionToken } from '@angular/core';
import { Overlay, ScrollStrategy } from '@angular/cdk/overlay';

export const SELECT_SCROLL_STRATEGY = new InjectionToken<() => ScrollStrategy>('select-scroll-strategy');

export function SELECT_SCROLL_STRATEGY_PROVIDER_FACTORY(overlay: Overlay): () => ScrollStrategy {
  return () => overlay.scrollStrategies.reposition();
}

export const SELECT_SCROLL_STRATEGY_PROVIDER = {
  provide: SELECT_SCROLL_STRATEGY,
  deps: [Overlay],
  useFactory: SELECT_SCROLL_STRATEGY_PROVIDER_FACTORY
};
