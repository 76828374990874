export const environment = {
  production: false,
  isDebugMode: true,
  mocks: false,
  api: 'https://dev-api.insocial.nl/v2',
  trackingId: 'UA-38522545-15',
  i18n: {
    languages: ['en_GB', 'nl_NL', 'nl_LOC'],
    defaultTranslation: 'en_GB',
  },
  supportedLocales: ['nl_NL', 'de_DE', 'en_GB'],
  surveyUrl: 'https://dev-f.insocial.nl/',
  newSurveyUrl: 'https://dev-f.insocial.nl/',
  narrowcastUrl: 'https://dev-viewer.insocial.tv/',
  inviteExampleUrl: 'https://insocial-cloud.s3.eu-central-1.amazonaws.com/docs/example.csv',
  disableNestedDriverFeature: false,
  auth: {
    authority: 'https://dev-api.insocial.nl',
    client_id: 'idtestclient1',
    client_secret: 'secrettestclient1',
    redirect_uri: 'https://dev-app.insocial.nl/#/auth/sign-in/callback',
    post_logout_redirect_uri: 'https://dev-app.insocial.nl/#/auth/sign-in',
    response_type: 'code',
    scope: 'read',
    filterProtocolClaims: true,
    metadata: {
      authorization_endpoint: 'https://dev-api.insocial.nl/oauth/v2/authorize',
      token_endpoint: 'https://dev-api.insocial.nl/oauth/v2/token',
      end_session_endpoint: 'https://dev-api.insocial.nl/oauth/v2/authorize/logout',
    },
  },
  script_generator: {
    version: 'https://web-f.insocial.nl/survey-loader-2.1.4.min.js',
    integrity: 'sha384-GLgYKkEgVxaffqRP943d0D5F6RmQsdjGI/xqLSoWOED+OmyuWURAa2F1mgM6EoGQ',
  },
};
