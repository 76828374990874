export enum DateUnit {
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export class DateConstants {
  static readonly FULL_DATE = 'd MMMM yyyy HH:mm';
  static readonly PAYLOAD_DATE = 'yyyy-mm-ddThh:mm:ss.s+zzzzzz';
  static readonly STRING_DATE = 'yyyy-MM-dd';
  static readonly TEMPLATE_STRING_DATE = 'dd-MM-yyyy';
  static readonly SHORT_DATE = 'MMM D, Y';
  static readonly HOURS_MINUTES = 'HH:mm';
  static readonly DAY_MONTH_DATE = 'dd MM';
  static readonly DATE_UNITS = Object.values(DateUnit);
}
