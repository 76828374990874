import { ChangeDetectorRef, Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { ProfileFacade } from '@app/core/root-store/profile-store/profile.facade';
import { ScopeFacade } from '@app/core/root-store/scope-store/scope.facade';
import { AuthUtils } from '@app/shared/utils/auth.utils';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserRoles } from '@app/shared/enums/roles.enum';

@Directive({
  selector: '[appHasRoles]'
})
export class HasRolesDirective implements OnInit, OnDestroy {
  @Input()
  appHasRoles: string[];
  @Input()
  appHasRolesElseTemplate: TemplateRef<any>;
  @Input()
  appHasRolesUserRoles: UserRoles[];

  stop$ = new Subject();

  isVisible = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private profileFacade: ProfileFacade,
    private scopeFacade: ScopeFacade,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    combineLatest([this.scopeFacade.getContactRoles$, this.profileFacade.getIsAdmin$, this.profileFacade.userRoles$])
      .pipe(takeUntil(this.stop$))
      .subscribe(([state, isAdmin, userRoles]) => {
        const roles = state.roles || [];
        const profileRoles = userRoles || [];

        if (
          (!this.appHasRolesUserRoles && AuthUtils.hasAccess(roles, this.appHasRoles, isAdmin)) ||
          (this.appHasRolesUserRoles && AuthUtils.hasAccess(profileRoles, this.appHasRolesUserRoles, false))
        ) {
          this.renderTemplate();
        } else {
          this.isVisible = false;
          this.viewContainerRef.clear();

          if (this.appHasRolesElseTemplate) {
            this.viewContainerRef.createEmbeddedView(this.appHasRolesElseTemplate);
          }
        }
        this.cd.markForCheck();
      });
  }

  private renderTemplate(): void {
    if (!this.isVisible) {
      this.isVisible = true;
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }

  ngOnDestroy(): void {
    this.stop$.next(null);
    this.stop$.complete();
  }
}
