export class CommonRoutes {
  static readonly HOME = 'home';
  static readonly ADMIN_DASHBOARD = 'admin-dashboard';
  static readonly AUTH = 'auth';
  static readonly SIGN_IN = 'sign-in';
  static readonly CALLBACK = 'callback';
  static readonly UNSUBSCRIBING = 'unsubscribing';

  static readonly SCOPE = 'scope';
  static readonly NOT_FOUND = 'not-found';

  static readonly LOCKED = 'locked';
  static readonly PROFILE = 'profile';

  static readonly DETAILS = 'details';

  static readonly BUILDER = 'builder';
  static readonly CREATE = 'create';
  static readonly EDIT = 'edit';
  static readonly SETUP = 'setup';

  static readonly ABSOLUTE_SCOPE = `/${CommonRoutes.SCOPE}`;
  static readonly ABSOLUTE_SCOPE_NOT_FOUND = `${CommonRoutes.ABSOLUTE_SCOPE}/${CommonRoutes.NOT_FOUND}`;
  static readonly ABSOLUTE_LOCKED = `/${CommonRoutes.LOCKED}`;
  static readonly ABSOLUTE_PROFILE_DETAILS = `/${CommonRoutes.PROFILE}/${CommonRoutes.DETAILS}`;
  static readonly ABSOLUTE_SURVEY_TEMPLATES = `/surveys/templates`;

  static readonly RELATIVE_BUILDER_CREATE = `${CommonRoutes.BUILDER}/${CommonRoutes.CREATE}`;
  static readonly RELATIVE_BUILDER_EDIT = `${CommonRoutes.BUILDER}/${CommonRoutes.EDIT}`;
}
