import { Injectable } from '@angular/core';
import { State } from '@app/core/root-store/profile-store/state/profile-state';
import { select, Store } from '@ngrx/store';
import * as fromSelectors from './reducers';
import * as fromActions from './actions';
import { IUser } from '@app/shared/models/accounts/user.model';

@Injectable()
export class ProfileFacade {
  getUser$ = this.store.pipe(select(fromSelectors.getUserInfo));
  getIsAdmin$ = this.store.pipe(select(fromSelectors.isAdmin));
  userRoles$ = this.store.select(fromSelectors.getUserRole);
  getProfileLoaded$ = this.store.pipe(select(fromSelectors.userStoreLoaded));
  getProfileLoading$ = this.store.pipe(select(fromSelectors.userInfoStoreLoading));

  constructor(private store: Store<State>) {}

  changeLanguage(data: { locale: string; user_id: string }) {
    this.store.dispatch(fromActions.changeUserLanguage(data));
  }

  updateSelf(user: IUser) {
    this.store.dispatch(fromActions.updateSelf({ user }));
  }

  updateUser(user: IUser) {
    this.store.dispatch(fromActions.updateUser({ user }));
  }

  enableTwoFactorAuthentication() {
    this.store.dispatch(fromActions.enableTwoFactorAuth());
  }

  disableTwoFactorAuthentication() {
    this.store.dispatch(fromActions.disableTwoFactorAuth());
  }

  checkTwoFactorAuthentication() {
    this.store.dispatch(fromActions.checkTwoFactorAuth());
  }
}
