import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pluck'
})
export class PluckPipe implements PipeTransform {
  isObject = (input: any): boolean => input !== null && typeof input === 'object';

  // todo: create advanced typing
  transform<T, K extends string>(input: T, key: K): T | any {
    if (!this.isObject(input) || !key) {
      return input;
    }
    return this.getProperty<T, K>(input, key);
  }

  getProperty<T, K extends string>(input: T, key: K): T | any {
    if (key.includes('.')) {
      return key.split('.').reduce((object, _key) => object[_key], input);
    }

    return input[key as string];
  }
}
