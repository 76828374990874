import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  HostBinding,
  Input,
  ViewEncapsulation
} from '@angular/core';
import { InsCardTitleComponent } from '@app/shared/components/card/head/ins-card-title.component';
import { CoerceBooleanProperty } from '@app/core/decorators/coerce-boolean-property';

@Component({
  selector: 'ins-card-head',
  template: `
    <div fxLayout="row" fxLayoutAlign="space-between">
      <ng-content></ng-content>
      <div class="ins-card__head-label" *ngIf="titleComp">
        <ng-content #title select="ins-card-title, [ins-card-title]"></ng-content>
      </div>
      <ng-content select="ins-card-actions, [ins-card-actions]"></ng-content>
      <ng-content #img select="img"></ng-content>
    </div>
    <ng-content select="ins-card-description, [ins-card-description]"></ng-content>
  `,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InsCardHeadComponent {
  showTitle: boolean;

  @HostBinding('class')
  componentClass = 'ins-card__head';

  @ContentChild(InsCardTitleComponent, { static: false })
  titleComp: ElementRef;

  @Input()
  @CoerceBooleanProperty()
  @HostBinding('class.no-border')
  noBorder: boolean;
}
