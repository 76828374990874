import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromResponses from './responses.reducer';

export const selectResponsesState = createFeatureSelector<fromResponses.State>(fromResponses.responsesFeatureKey);

export const selectAllResponses = createSelector(selectResponsesState, fromResponses.selectAll);
export const selectLoading = createSelector(selectResponsesState, state => state.loading);
export const selectCustomerLabelsLoading = createSelector(selectResponsesState, state => state.loadingCustomerLabels);
export const selectResponseMeta = createSelector(selectResponsesState, state => state.meta);
export const selectAllResponsesEntities = createSelector(selectResponsesState, fromResponses.selectEntities);
export const selectResponseCounters = createSelector(selectResponsesState, state => state?.counters);

export const selectSelectedResponseIds = createSelector(selectResponsesState, state => state.selectedResponseIds || []);
export const selectCustomerLabels = createSelector(selectResponsesState, state => state.customerLabels || []);
export const selectShowTranslations = createSelector(selectResponsesState, state => state.showTranslations);
export const selectQueryParams = createSelector(selectResponsesState, state => state.queryParams);


export const selectSelectedResponses = createSelector(
  selectAllResponsesEntities,
  selectSelectedResponseIds,
  (responses, selectedIds) => selectedIds.map(id => responses[id])
);

export const readyForPublishIds = createSelector(
  selectAllResponsesEntities,
  selectSelectedResponseIds,
  (responses, selectedIds) =>
    selectedIds.filter(id => {
      const response = responses[id];
      return !response.isDemo && response.isPublishableRespondent;
    })
);
