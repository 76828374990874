<div class="user-header">
  <div class="user-header__info" aria-controls="dropdown-basic">
    <p *ngIf="user" class="user-header__info-name">{{ user.label }}</p>
    <p *ngIf="scope" [routerLink]="[managementRoutes.ABSOLUTE_CUSTOMERS, scope.id]" class="user-header__info-label">
      {{ scope.label }}
    </p>
  </div>
  <div class="user-header__actions">
    <div class="user-header__top-menu" dropdown>
      <div style="position: relative" dropdownToggle>
        <app-avatar [text]="user?.label" [bgColor]="scope?.color"></app-avatar>
        <span class="menu-arrow menu-arrow--backdrop"></span>
        <span class="menu-arrow"></span>
      </div>
      <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-user dropdown-menu-right">
        <li *ngIf="scopeInUrl" class="menuitem-icon" role="menuitem">
          <a class="dropdown-item" [routerLink]="commonRoutes.ABSOLUTE_PROFILE_DETAILS">
            <i class="la la-user"></i> {{ 'header.user.profile' | transloco }}</a
          >
        </li>
        <li *ngIf="scopeInUrl && scope" class="menuitem-icon" role="menuitem">
          <a class="dropdown-item" [routerLink]="[managementRoutes.ABSOLUTE_CUSTOMERS, scope.id]">
            <i class="la la-user-friends"></i> {{ 'header.user.environment' | transloco }}</a
          >
        </li>
        <ng-container *appHasRoles="[roles.CONTACT_LIST]">
          <li *ngIf="!hideScopeItem || scopeInUrl" class="menuitem-icon" role="menuitem">
            <a class="dropdown-item" [routerLink]="commonRoutes.ABSOLUTE_SCOPE">
              <i class="la la-suitcase"></i> {{ 'header.user.scope' | transloco }}</a
            >
          </li>
        </ng-container>
        <li class="menuitem-icon" role="menuitem">
          <a class="dropdown-item" (click)="logout()">
            <i class="la la-sign-out"></i> {{ 'header.user.logout' | transloco }}</a
          >
        </li>
        <li *ngIf="isImpersonating()" class="menuitem-icon" role="menuitem">
          <a class="dropdown-item" (click)="exitImpersonation()">
            <i class="la la-user-slash"></i> {{ 'header.user.exit-impersonate' | transloco }}</a
          >
        </li>
      </ul>
    </div>
  </div>
</div>
<div class="user-header__language-selector">
  <ul class="language-dropdown" *ngIf="user">
    <li>
      <ins-flag [language]="user.locale"></ins-flag>
    </li>
    <ng-container *ngFor="let language of languages">
      <li *ngIf="language !== user.locale && (language !== 'nl_LOC' || isAdmin)" (click)="changeLang(language)">
        <ins-flag [language]="language"></ins-flag>
      </li>
    </ng-container>
  </ul>
</div>
