import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable()
export class LocalStorageLoggedInService {
  private loggedInFlag = 'isLoggedIn';

  getItem(): Observable<boolean> {
    const data = sessionStorage.getItem(this.loggedInFlag);
    if (data) {
      return of(JSON.parse(data));
    }
    return of(null);
  }

  setItem(data: any): Observable<any> {
    sessionStorage.setItem(this.loggedInFlag, JSON.stringify(data));
    return of(data);
  }

  removeItem() {
    sessionStorage.removeItem(this.loggedInFlag);
    return of(true);
  }
}
