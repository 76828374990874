import { createAction, props } from '@ngrx/store';
import { IUser } from '@app/shared/models/accounts/user.model';

export const loadUserInfo = createAction('[User] Load user info');
export const loadUserInfoSuccess = createAction('[User/API] load user info success', props<{ user: IUser }>());
export const checkIfUserIsComplete = createAction('[User/API] check if user is complete', props<{ user: IUser }>());
export const loadUserInfoFailed = createAction('[User/API] load user info failed', props<{ error: any }>());

export const changeUserLanguage = createAction('[User] change language', props<{ locale: string; user_id: string }>());
export const changeUserLanguageSuccess = createAction('[User] change language Success', props<{ locale: string }>());
export const changeAdminLanguageSuccess = createAction(
  '[User] change admin language Success',
  props<{ locale: string }>()
);
export const changeUserLanguageFailed = createAction('[User/Api] change language failed', props<{ err: any }>());

export const updateUser = createAction('[User] update current user', props<{ user: IUser }>());
export const updateUserSuccess = createAction('[User] update current user success');
export const updateUserFailed = createAction('[User] update current user failed');

export const updateSelf = createAction('[User] update profile', props<{ user: IUser }>());
export const updateSelfSuccess = createAction('[User] update self success', props<{ user: IUser }>());

export const enableTwoFactorAuth = createAction('[User] enable two factor authentication');
export const enableTwoFactorAuthSuccess = createAction('[User] enable two factor authentication success');
export const enableTwoFactorAuthFailed = createAction(
  '[User] enable two factor authentication failed',
  props<{ err: any }>()
);

export const disableTwoFactorAuth = createAction('[User] disable two factor authentication');
export const disableTwoFactorAuthSuccess = createAction('[User] disable two factor authentication success');
export const disableTwoFactorAuthFailed = createAction(
  '[User] disable two factor authentication failed',
  props<{ err: any }>()
);

export const checkTwoFactorAuth = createAction('[User] check two factor authentication');
export const checkTwoFactorAuthSuccess = createAction(
  '[User] check two factor authentication success',
  props<{ enabled: boolean }>()
);
export const checkTwoFactorAuthFailed = createAction(
  '[User] check two factor authentication failed',
  props<{ err: any }>()
);
