export class ManagementRoutes {
  static readonly MANAGEMENT = 'settings';

  static readonly CUSTOMERS = 'customers';
  static readonly CONNECTIVITY = 'connectivity';
  static readonly GDPR = 'gdpr';
  static readonly NEW = 'new';
  static readonly CONNECT = 'connect';
  static readonly PRODUCTS = 'products';
  static readonly INVITES_TEMPLATES = 'invites-templates';
  static readonly DEMO_PANEL = 'demo-panel';

  static readonly ACCOUNTS = 'accounts';
  static readonly ADD = 'add';

  static readonly ABSOLUTE_CUSTOMERS = `/${ManagementRoutes.MANAGEMENT}/${ManagementRoutes.CUSTOMERS}`;
  static readonly ABSOLUTE_CUSTOMERS_NEW = `/${ManagementRoutes.ABSOLUTE_CUSTOMERS}/${ManagementRoutes.NEW}`;
  static readonly ABSOLUTE_ACCOUNTS = `/${ManagementRoutes.MANAGEMENT}/${ManagementRoutes.ACCOUNTS}`;
  static readonly ABSOLUTE_PRODUCTS = `/${ManagementRoutes.MANAGEMENT}/${ManagementRoutes.PRODUCTS}`;
  static readonly ABSOLUTE_INVITES_TEMPLATES = `/${ManagementRoutes.MANAGEMENT}/${ManagementRoutes.INVITES_TEMPLATES}`;
  static readonly ABSOLUTE_DEMO_PANEL = `/${ManagementRoutes.MANAGEMENT}/${ManagementRoutes.DEMO_PANEL}`;
}
