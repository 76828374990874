import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AuthService } from '../services/auth.service';
import * as AuthActions from './auth.actions';
import * as fromAuth from './auth.reducer';
import * as AuthSelectors from './auth.selectors';

@Injectable()
export class AuthFacade {
  // loaded$ = this.store.pipe(select(AuthSelectors.getAuthLoaded));
  getJwtToken$ = this.store.pipe(select(AuthSelectors.getJwtToken));
  isLoggedIn$ = this.store.pipe(select(AuthSelectors.getIsLoggedIn));

  silentSignIn = () => this.store.dispatch(AuthActions.renewToken());

  constructor(private store: Store<fromAuth.AuthPartialState>, private authService: AuthService) {
    this.registerEvents();
  }

  loginComplete() {
    this.store.dispatch(AuthActions.getToken());
  }

  redirectToLogin(state: any = {}) {
    this.store.dispatch(AuthActions.redirectToLogin({ state }));
  }

  checkSession() {
    this.store.dispatch(AuthActions.checkSession());
  }

  logOut() {
    this.store.dispatch(AuthActions.logOut());
  }

  // 401 errors
  httpErrorRejected() {
    this.store.dispatch(AuthActions.serverRejectedRequest());
  }

  registerEvents() {
    this.authService.registerSilentRenewEvents(this.silentSignIn);
  }
}
