import { ChangeDetectionStrategy, Component, Inject, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { SelectItem } from '@app/shared/components/select/select-item.interface';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DATA_LIST_HOST } from '@app/core/tokens/data-list-host.token';
import { DataListHost } from '@app/core/interfaces/data-list-host.interface';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

@Component({
  selector: 'app-option-container',
  templateUrl: './option-container.component.html',
  styleUrls: ['./option-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@UntilDestroy()
export class OptionContainerComponent implements OnChanges {
  private scrolledIndex = 0;
  itemSize = 43;
  maxItemLength = 8;
  @Input()
  listItems: SelectItem<any>[];
  @Input()
  activeValue: any;

  @ViewChild(CdkVirtualScrollViewport, { static: true })
  cdkVirtualScrollViewport: CdkVirtualScrollViewport;

  constructor(@Inject(DATA_LIST_HOST) private host: DataListHost<unknown>) {}

  trackValue(_index: number, option: SelectItem<any>): any {
    return option.key;
  }

  scrolledIndexChange(index: number): void {
    this.scrolledIndex = index;
  }

  scrollToActivatedValue(): void {
    const index = this.listItems.findIndex(item => this.host.compareWith(item.key, this.activeValue));
    if (index < this.scrolledIndex || index >= this.scrolledIndex + this.maxItemLength) {
      this.cdkVirtualScrollViewport.scrollToIndex(index || 0);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { listItems, activeValue } = changes;
    if (listItems || activeValue) {
      this.scrollToActivatedValue();
    }
  }
}
