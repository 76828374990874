import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { IFeedback } from '../../models/feedback.interface';
import { ApiService } from '@app/core/services/_api.service';


@Injectable({
  providedIn: 'root'
})
export class FeedbackCompanyService {
  constructor(private router: Router, private apiService: ApiService) {
  }

  getFeedback(): Observable<IFeedback> {
    return this.apiService.get<IFeedback>('/customers/{customerId}/feedbackCompany');
  }

  addFeedback(feedback: IFeedback): Observable<IFeedback> {
    return this.apiService.post<IFeedback>('/customers/{customerId}/feedbackCompany', feedback);
  }

  updateFeedback(feedback: IFeedback): Observable<IFeedback> {
    return this.apiService.put<IFeedback>('/customers/{customerId}/feedbackCompany', feedback);
  }

  deleteFeedback(): Observable<never> {
    return this.apiService.delete<never>('/customers/{customerId}/feedbackCompany');
  }
}
