<ng-container *ngIf="multi">
  <ng-container (click)="!disabled && checkboxComponent.checkedChange(!checkboxComponent.checked)">
    <app-checkbox #checkboxComponent (onChange)="onClick()" [checked]="selected" [disabled]="disabled"></app-checkbox>
  </ng-container>
</ng-container>

<i *ngIf="!multi && disabled" class="las la-ban"></i>

<span *ngIf="!item?.template; else isTemplate" class="option-text-wrapper"> {{ item.label }} </span>

<i class="la la-{{item.actionIcon}} option-action-icon" (click)="clickOptionAction($event)"></i>
<ng-template #isTemplate>
  <ng-container *ngTemplateOutlet="item.template; context: { $implicit: item.context }"></ng-container>
</ng-template>
