<sidebar-brand> </sidebar-brand>
<div class="menu">
  <div class="menu-wrapper ps" [perfectScrollbar]="config" #scrollableContent>
    <ul class="menu__nav">
      <ng-container *ngFor="let item of menuConfig">
        <sidebar-item [item]="item" [url]="item.url"></sidebar-item>
      </ng-container>
    </ul>
  </div>
</div>
