import { Directive, HostListener, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Directive({
  selector: '[insHover]',
  exportAs: 'insHover'
})
export class HoverDirective {
  private hoverSubject = new BehaviorSubject(false);
  hoverChange$ = this.hoverSubject.asObservable();

  @Output()
  hoverChange = new EventEmitter<boolean>();

  constructor(private cdRef: ChangeDetectorRef) {}

  @HostListener('mouseenter')
  private hoverEnter(): void {
    this.hoverState = true;
  }
  @HostListener('mouseleave')
  private hoverLeave(): void {
    this.hoverState = false;
  }

  set hoverState(hoverState: boolean) {
    const currentValue = this.hoverSubject.value;

    if (currentValue !== hoverState) {
      this.hoverSubject.next(hoverState);
      this.hoverChange.emit(hoverState);
      this.cdRef.markForCheck();
    }
  }
}
