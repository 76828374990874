import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '@app/shared/utils/utils';

@Pipe({
  name: 'split'
})
export class SplitPipe implements PipeTransform {
  transform(value: string, by: string, index?: number): any | any[] {
    if (!value) {
      return null;
    }

    return Utils.isDefined(index) ? value.split(by)[index] : value.split(by);
  }
}
