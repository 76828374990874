import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ColorInputComponent } from './color-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslocoModule],
  exports: [ColorInputComponent],
  declarations: [ColorInputComponent],
  providers: []
})
export class ColorInputModule {}
