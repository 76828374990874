import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FileReaderService, ReadFile } from '@app/dashboard/customer/core/services/filereader.service';

@Component({
  selector: 'insBlob',
  templateUrl: './ins-blob.component.html',
  styleUrls: ['./ins-blob.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InsBlobComponent {
  private _blobOrImage: Observable<ReadFile | string>;
  @Input()
  set blob(blob: File | string) {
    if (blob instanceof File) {
      this._blobOrImage = this.fileReader.readFile(blob);
    } else if (typeof blob === 'string') {
      this._blobOrImage = of(blob);
    }
  }
  get blob$(): Observable<ReadFile | string> {
    return this._blobOrImage;
  }

  constructor(private fileReader: FileReaderService) {}
}
