import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChipListComponent } from './chip-list/chip-list.component';
import { ChipComponent } from './chip/chip.component';

@NgModule({
  declarations: [ChipListComponent, ChipComponent],
  exports: [ChipComponent, ChipListComponent],
  imports: [CommonModule]
})
export class ChipsModule {}
