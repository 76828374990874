import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonRoutes } from '@app/core/constants/routes/common-routes.constant';
import { ScopeFacade } from '@app/core/root-store/scope-store/scope.facade';
import { ScopeService } from '@app/core/services/scope.service';
import { IScope } from '@app/shared/models/common/scope.model';
import { Customer } from '@app/shared/models/customer/customer.model';
import { NavigationUtils } from '@app/shared/utils/navigation.utils';
import { AuthFacade, NavigationService } from 'ins-auth';
import { of, Subscription } from 'rxjs';
import { filter, first, map, switchMap, take } from 'rxjs/operators';
import { CustomerService } from '@app/core/services/customer/customer.service';
import { ProfileFacade } from '@app/core/root-store/profile-store/profile.facade';
import { UntilDestroy } from '@ngneat/until-destroy';

@Component({
  template: `
    <app-loader></app-loader>
  `
})
@UntilDestroy()
export class CallbackComponent implements OnInit, OnDestroy {
  subscription: Subscription;

  constructor(
    private scopeService: ScopeService,
    private navigationService: NavigationService,
    private scopeFacade: ScopeFacade,
    private authFacade: AuthFacade,
    private profileFacade: ProfileFacade,
    private router: Router,
    private customerService: CustomerService
  ) {}

  ngOnInit(): void {
    this.authFacade.loginComplete();
    this.subscription = this.profileFacade.getProfileLoaded$
      .pipe(
        filter(isLoaded => isLoaded),
        switchMap(() => this.customerService.getCustomers()),
        switchMap(({ data }) => {
          const customers = data;
          const localScope = this.scopeService.lastScopeId;
          let scopeId = localScope?.customerId;
          const autoSelected = customers.length === 1;
          if (!scopeId && customers?.length === 1) {
            scopeId = customers[0].id;
          }
          if (scopeId && customers?.length >= 1) {
            this.scopeFacade.getScope(scopeId, autoSelected);
            return this.scopeFacade.scopeStoreLoaded$.pipe(
              filter((isScopeLoaded: boolean) => isScopeLoaded),
              switchMap(() => this.scopeFacade.getScope$),
              first(),
              map((scope: IScope) => [customers, scope.id])
            );
          }
          return of([customers]);
        })
      )
      .subscribe(([customers, scopeId]: [Customer[], string]) => {
        if (customers?.length) {
          const redirectUrl = this.navigationService.getRedirectUrl();
          if (redirectUrl && scopeId) {
            const processedUrl$ = NavigationUtils.processNavigationUrl(redirectUrl, of(scopeId));
            if (processedUrl$) {
              processedUrl$.pipe(take(1)).subscribe((url: string) => {
                this.router.navigateByUrl(url);
              });
              this.navigationService.removeItems();
              return;
            }
          }

          if (redirectUrl && !redirectUrl.startsWith(CommonRoutes.ABSOLUTE_SCOPE) && scopeId) {
            this.router.navigateByUrl(redirectUrl);
            this.navigationService.removeItems();
            return;
          }
          if (scopeId) {
            this.router.navigate(['/', scopeId, CommonRoutes.HOME]);
            return;
          }
          this.router.navigate([CommonRoutes.ABSOLUTE_SCOPE]);
          return;
        } else {
          this.router.navigate([CommonRoutes.ABSOLUTE_SCOPE_NOT_FOUND]);
        }
      });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
