import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CoerceBooleanProperty } from '@app/core/decorators/coerce-boolean-property';
import { InsSizeM, InsSizeS } from '@app/shared/types/size';
import { supportedLanguages } from '@app/core/constants/supported-languages';

@Component({
  selector: 'ins-flag',
  templateUrl: './ins-flag.component.html',
  styleUrls: ['./ins-flag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InsFlagComponent {
  _language: string;
  @Input()
  set language(lang: string) {
    if (!lang.includes('_')) {
      lang = supportedLanguages.find(l => l.code.startsWith(lang)).code;
    }
    this._language = lang;
  }

  @Input()
  @CoerceBooleanProperty()
  showLabel: boolean;

  @Input()
  size: InsSizeS | InsSizeM = 's';
}
