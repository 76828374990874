<ng-container *appHasRoles="null; userRoles: item.userRole">
  <ng-container *appShowByRoles="item.showForRoles">
    <ng-container *appHideByRole="item.hideForRoles">
      <a class="menu__link" [class.menu__link--active]="isActive" (click)="openOrRoute()">
        <ng-container *ngIf="isUnlocked; else showLocked">
          <span *ngIf="item.icon; else showDot" class="menu__link-icon"><i class="la la-{{ item.icon }}"></i></span>
          <ng-template #showDot>
            <i class="menu__link-bullet menu__link-bullet--dot"><span></span></i>
          </ng-template>
        </ng-container>
        <ng-template #showLocked>
          <span class="menu__link-icon" [class.sub-item-locked]="!isParent"><i class="la la-lock"></i></span>
        </ng-template>
        <span class="menu__link-text">
        <ng-container *ngIf="translatable; else noTranslatePipe">
          {{ 'menu.' + item.label | transloco }}
        </ng-container>
        <ng-template #noTranslatePipe> {{ item.label }}</ng-template>
      </span>
        <div class="menu__link-side">
          <ng-container>
            <nz-badge
              *ngIf="item.badge"
              nzStandalone
              [nzCount]="item.badge | async"
              [nzOverflowCount]="999"
              [nzStyle]="{ backgroundColor: badgeColor }"
            ></nz-badge>
            <ins-badge *ngIf="item.isBeta" class="beta-feature">Beta</ins-badge>
          </ng-container>
          <ng-container *ngIf="item.sideAction">
            <i (click)="fireAction($event)" class="la la-plus-circle menu__link-side-action"></i>
          </ng-container>
          <i *ngIf="isParent || item?.href" class="menu__link-icon-right">
            <span *ngIf="isParent && isUnlocked" class="la la-angle-right" [class.la-rotate-90]="isOpen"></span>
            <span *ngIf="item.href" class="la la-external-link-alt"></span>
          </i>
        </div>
      </a>

      <ng-container *ngIf="isParent">
        <div class="menu__submenu" [class.is-open]="isOpen">
          <ul class="menu__subnav">
            <ng-container *ngIf="!isAsync; else asyncLoop">
              <ng-container *ngFor="let subItem of $any(item.children)">
                <ng-container *ngTemplateOutlet="subItemTmpl; context: { subItem: subItem }"></ng-container>
              </ng-container>
            </ng-container>
            <ng-template #asyncLoop>
              <ng-container *ngFor="let subItem of item.children | async">
                <ng-container *ngTemplateOutlet="subItemTmpl; context: { subItem: subItem }"></ng-container>
              </ng-container>
            </ng-template>
          </ul>
        </div>

        <ng-template #subItemTmpl let-subItem="subItem">
          <sidebar-item [url]="createUrl(url, subItem.url)" [item]="subItem"></sidebar-item>
        </ng-template>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
