/* eslint-disable */

export enum Roles {
  CUSTOMER_LIST = 'ROLE_CUSTOMER_LIST',
  CUSTOMER_CREATE = 'ROLE_CUSTOMER_CREATE',
  CUSTOMER_READ = 'ROLE_CUSTOMER_READ',
  CUSTOMER_UPDATE = 'ROLE_CUSTOMER_UPDATE',
  CUSTOMER_DELETE = 'ROLE_CUSTOMER_DELETE',
  CONTACT_LIST = 'ROLE_CONTACT_LIST',
  CONTACT_READ = 'ROLE_CONTACT_READ',
  CONTACT_DELETE = 'ROLE_CONTACT_DELETE',
  CONTACT_UPDATE = 'ROLE_CONTACT_UPDATE',
  CONTACT_CREATE = 'ROLE_CONTACT_CREATE',
  SURVEY_LIST = 'ROLE_SURVEY_LIST',
  SURVEY_CREATE = 'ROLE_SURVEY_CREATE',
  SURVEY_READ = 'ROLE_SURVEY_READ',
  SURVEY_UPDATE = 'ROLE_SURVEY_UPDATE',
  SURVEY_DELETE = 'ROLE_SURVEY_DELETE',
  RESPONSE_LIST = 'ROLE_RESPONSE_LIST',
  RESPONSE_CREATE = 'ROLE_RESPONSE_CREATE',
  RESPONSE_READ = 'ROLE_RESPONSE_READ',
  RESPONSE_UPDATE = 'ROLE_RESPONSE_UPDATE',
  RESPONSE_DELETE = 'ROLE_RESPONSE_DELETE',
  RESPONSE_REPLY = 'ROLE_RESPONSE_REPLY',
  RESPONSE_EXPORT = 'ROLE_EXPORT_RESPONSES',
  EMAIL_INVITE_CREATE = 'ROLE_EMAIL_INVITE_CREATE',
  EMAIL_INVITE_READ = 'ROLE_EMAIL_INVITE_READ',
  EMAIL_INVITE_BATCH_CREATE = 'ROLE_EMAIL_INVITE_BATCH_CREATE',
  DASHBOARD_LIST = 'ROLE_DASHBOARD_LIST',
  DASHBOARD_READ = 'ROLE_DASHBOARD_READ',
  DASHBOARD_CREATE = 'ROLE_DASHBOARD_CREATE',
  DASHBOARD_UPDATE = 'ROLE_DASHBOARD_UPDATE',
  DASHBOARD_DELETE = 'ROLE_DASHBOARD_DELETE',
  SCHEDULED_TASK_READ = 'ROLE_SCHEDULED_TASK_READ',
  SCHEDULED_TASK_CREATE = 'ROLE_SCHEDULED_TASK_CREATE',
  SCHEDULED_TASK_UPDATE = 'ROLE_SCHEDULED_TASK_UPDATE',
  SCHEDULED_TASK_DELETE = 'ROLE_SCHEDULED_TASK_DELETE',
  USER_EXPERIENCE_LIST = 'ROLE_JOURNEY_USER_EXPERIENCE_LIST',
  USER_EXPERIENCE_CREATE = 'ROLE_JOURNEY_USER_EXPERIENCE_CREATE',
  USER_EXPERIENCE_READ = 'ROLE_JOURNEY_USER_EXPERIENCE_READ',
  USER_EXPERIENCE_UPDATE = 'ROLE_JOURNEY_USER_EXPERIENCE_UPDATE',
  USER_EXPERIENCE_DELETE = 'ROLE_JOURNEY_USER_EXPERIENCE_DELETE',
  BRAND_EXPERIENCE_LIST = 'ROLE_JOURNEY_BRAND_EXPERIENCE_LIST',
  BRAND_EXPERIENCE_CREATE = 'ROLE_JOURNEY_BRAND_EXPERIENCE_CREATE',
  BRAND_EXPERIENCE_READ = 'ROLE_JOURNEY_BRAND_EXPERIENCE_READ',
  BRAND_EXPERIENCE_UPDATE = 'ROLE_JOURNEY_BRAND_EXPERIENCE_UPDATE',
  BRAND_EXPERIENCE_DELETE = 'ROLE_JOURNEY_BRAND_EXPERIENCE_DELETE',
  CUSTOMER_EXPERIENCE_LIST = 'ROLE_JOURNEY_CUSTOMER_EXPERIENCE_LIST',
  CUSTOMER_EXPERIENCE_CREATE = 'ROLE_JOURNEY_CUSTOMER_EXPERIENCE_CREATE',
  CUSTOMER_EXPERIENCE_READ = 'ROLE_JOURNEY_CUSTOMER_EXPERIENCE_READ',
  CUSTOMER_EXPERIENCE_UPDATE = 'ROLE_JOURNEY_CUSTOMER_EXPERIENCE_UPDATE',
  CUSTOMER_EXPERIENCE_DELETE = 'ROLE_JOURNEY_CUSTOMER_EXPERIENCE_DELETE',
  SERVICE_EXPERIENCE_LIST = 'ROLE_JOURNEY_SERVICE_EXPERIENCE_LIST',
  SERVICE_EXPERIENCE_CREATE = 'ROLE_JOURNEY_SERVICE_EXPERIENCE_CREATE',
  SERVICE_EXPERIENCE_READ = 'ROLE_JOURNEY_SERVICE_EXPERIENCE_READ',
  SERVICE_EXPERIENCE_UPDATE = 'ROLE_JOURNEY_SERVICE_EXPERIENCE_UPDATE',
  SERVICE_EXPERIENCE_DELETE = 'ROLE_JOURNEY_SERVICE_EXPERIENCE_DELETE',
  EMPLOYEE_EXPERIENCE_LIST = 'ROLE_JOURNEY_EMPLOYEE_EXPERIENCE_LIST',
  EMPLOYEE_EXPERIENCE_CREATE = 'ROLE_JOURNEY_EMPLOYEE_EXPERIENCE_CREATE',
  EMPLOYEE_EXPERIENCE_READ = 'ROLE_JOURNEY_EMPLOYEE_EXPERIENCE_READ',
  EMPLOYEE_EXPERIENCE_UPDATE = 'ROLE_JOURNEY_EMPLOYEE_EXPERIENCE_UPDATE',
  EMPLOYEE_EXPERIENCE_DELETE = 'ROLE_JOURNEY_EMPLOYEE_EXPERIENCE_DELETE',
  CLFR_LIST = 'ROLE_CLFR_LIST',
  CLFR_CREATE = 'ROLE_CLFR_CREATE',
  CLFR_READ = 'ROLE_CLFR_READ',
  CLFR_UPDATE = 'ROLE_CLFR_UPDATE',
  CLFR_DELETE = 'ROLE_CLFR_DELETE',
  NARROWCASTING_LIST = 'ROLE_NARROWCASTING_LIST',
  NARROWCASTING_CREATE = 'ROLE_NARROWCASTING_CREATE',
  NARROWCASTING_READ = 'ROLE_NARROWCASTING_READ',
  NARROWCASTING_UPDATE = 'ROLE_NARROWCASTING_UPDATE',
  NARROWCASTING_DELETE = 'ROLE_NARROWCASTING_DELETE',
  RULE_LIST = 'ROLE_RULE_LIST',
  RULE_CREATE = 'ROLE_RULE_CREATE',
  RULE_UPDATE = 'ROLE_RULE_UPDATE',
  RULE_DELETE = 'ROLE_RULE_DELETE',
  ROLE_NLP_MODEL_LIST = 'ROLE_NLP_MODEL_LIST',
  ROLE_NLP_MODEL_CREATE = 'ROLE_NLP_MODEL_CREATE',
  ROLE_NLP_MODEL_READ = 'ROLE_NLP_MODEL_READ',
  ROLE_NLP_MODEL_UPDATE = 'ROLE_NLP_MODEL_UPDATE',
  ROLE_NLP_MODEL_DELETE = 'ROLE_NLP_MODEL_DELETE',
  ROLE_REPORTING_USER = 'ROLE_REPORTING_USER',
  USER_LIST = 'ROLE_USER_LIST',
  USER_CREATE = 'ROLE_USER_CREATE',
  USER_READ = 'ROLE_USER_READ',
  USER_UPDATE = 'ROLE_USER_UPDATE',
  USER_DELETE = 'ROLE_USER_DELETE',
}

export enum UserRoles {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_USER = 'ROLE_USER',
  ROLE_EXPERT = 'ROLE_EXPERT_USER',
  ROLE_CSM = 'ROLE_CSM',
  ROLE_SALES = 'ROLE_SALES',
}

export enum UserProductRoles {
  read = 'read',
  write = 'write',
  admin = 'admin',
}
