import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { InsSizeL, InsSizeM, InsSizeS, InsSizeXL } from '@app/shared/types/size';

@Component({
  selector: 'button[ins-button], button[ins-button-icon], a[ins-button], a[ins-button-icon]',
  templateUrl: 'button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['button.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.btn]': `true`,
    '[class.ins-btn]': `true`,
    '[class.btn-primary]': `styling === 'primary'`,
    '[class.btn-secondary]': `styling === 'secondary'`,
    '[class.btn-link]': `styling === 'link'`,
    '[class.btn-secondary-inverted]': `styling === 'secondary-inverted'`,
    '[class.btn-rounded]': `shape === 'rounded'`,
    '[class.is-active]': 'isActive',
    '[class.is-btn-icon]': 'isIconButton',
    '[attr.disabled]': `disabled || null`,
    '(click)': 'haltDisabledEvents($event)',
  },
})
export class InsButtonComponent implements AfterViewInit {
  @ViewChild('spanElement')
  spanElement: ElementRef;

  @Input()
  content: TemplateRef<any> | null = null;

  private _isActive: boolean;
  @Input()
  set isActive(value) {
    if (this._isActive !== value) {
      this._isActive = value;
      this.cd.markForCheck();
    }
  }
  get isActive() {
    return this._isActive;
  }

  private _shape: string;
  @Input()
  @HostBinding('attr.data-ins-host-shape')
  set shape(value) {
    this._shape = value;
    this.cd.markForCheck();
  }
  get shape() {
    return this._shape;
  }
  private _size: InsSizeS | InsSizeM | InsSizeL | InsSizeXL = 'm';
  @Input()
  @HostBinding('attr.data-ins-host-size')
  set size(value: InsSizeS | InsSizeM | InsSizeL | InsSizeXL) {
    this._size = value;
    this.cd.markForCheck();
  }
  get size() {
    return this._size;
  }

  private _loading = false;
  @Input()
  get loading(): boolean {
    return this._loading;
  }

  set loading(value: boolean) {
    if (value !== this._loading) {
      this._loading = value;
      this._disabled = value;
      this.cd.markForCheck();
    }
  }

  private _disabled = false;
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    if (newValue !== this._disabled) {
      this._disabled = newValue;
      this.cd.markForCheck();
    }
  }

  private _icon: string;
  @Input()
  get icon(): string {
    return this._icon;
  }

  set icon(iconClass: string) {
    if (this._icon !== iconClass) {
      this._icon = iconClass;
      this.cd.markForCheck();
    }
  }

  private _iconAfter: string;
  @Input()
  get iconAfter(): string {
    return this._iconAfter;
  }

  set iconAfter(iconClass: string) {
    if (this._iconAfter !== iconClass) {
      this._iconAfter = iconClass;
      this.cd.markForCheck();
    }
  }

  private _style = 'primary';
  @Input()
  get styling(): string {
    return this._style;
  }

  set styling(styleClass: string) {
    if (this._style !== styleClass) {
      this._style = styleClass;
      this.cd.markForCheck();
    }
  }

  _isIconButton = false;
  get isIconButton(): boolean {
    return this._isIconButton;
  }

  constructor(private cd: ChangeDetectorRef) {}

  haltDisabledEvents(event: Event): void {
    if (this.disabled) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }

  checkIsIconButton(): void {
    this._isIconButton = !this.spanElement.nativeElement.innerText && !!this._icon;
    this.cd.markForCheck();
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.checkIsIconButton());
  }
}
