import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { NotificationService } from '@app/core/toastr/notification.service';
import { TranslocoService } from '@ngneat/transloco';
import { combineLatest, interval } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { announcements } from '@app/core/configs/feature-announcement.config';
import { NewFeatureAnnouncementComponent } from '@app/core/layout/modal/new-feature-announcement/new-feature-announcement.component';
import { DialogService } from '@ngneat/dialog';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {
  private readonly INTERVAL_IN_MINUTES = 3;
  private readonly ANNOUNCEMENTS_STORAGE_KEY = 'ai';

  constructor(
    private appRef: ApplicationRef,
    private swUpdate: SwUpdate,
    private notificationService: NotificationService,
    private translocoService: TranslocoService,
    private dialogService: DialogService
) {
    const everyNMinutes$ = interval(this.INTERVAL_IN_MINUTES * 60 * 1000);
    if (swUpdate.isEnabled) {
      everyNMinutes$.subscribe(() => swUpdate.checkForUpdate());
    }
  }

  initCheckingForUpdates(): void {
    this.swUpdate.versionUpdates
      .pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        tap(evt =>
          console.log(
            'Version read: \n' + `Current: ${evt.currentVersion.hash} \n` + `Previous: ${evt.latestVersion.hash}`
          )
        )
      )
      .subscribe(() => this.showDialog());
  }

  showFeatureAnnouncement(): void {
    const announcement = announcements[announcements.length - 1];

    if (!announcement || new Date() >= announcement.endDate) {
      return;
    }

    const announcementSession = localStorage.getItem(this.ANNOUNCEMENTS_STORAGE_KEY);
    const encodedNamespace = btoa(announcement.namespace);

    if (encodedNamespace !== announcementSession) {
      this.dialogService.open(NewFeatureAnnouncementComponent, {
        data: announcement,
        width: '380px'
      });

      localStorage.setItem(this.ANNOUNCEMENTS_STORAGE_KEY, encodedNamespace);
    }
  }

  private showDialog(): void {
    combineLatest([
      this.translocoService.selectTranslate<string>('common.update.title'),
      this.translocoService.selectTranslate<string>('common.update.description')
    ])
      .pipe(
        take(1),
        switchMap(
          ([title, description]: [string, string]) =>
            this.notificationService.info(title, description, 'reload', 'redo-alt', true).onAction
        ),
        take(1)
      )
      .subscribe(() => this.swUpdate.activateUpdate().then(() => document.location.reload()));
  }
}
