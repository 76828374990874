<div class="score-slider__inputs">
  <!-- TODO: add styling -->
  <input
    class="form-control"
    type="number"
    [min]="min"
    [max]="max"
    [value]="value[0]"
    (change)="setScore('min', $event.target.value)"
  />
  <span>{{ 'publication-options.modal.slider.separator' | transloco }}</span>
  <!-- TODO: add styling -->
  <input
    class="form-control"
    type="number"
    [min]="min"
    [max]="max"
    [value]="value[1]"
    (change)="setScore('max', $event.target.value)"
  />
</div>
<nz-slider nzRange nzTooltipVisible="never" [nzMin]="min" [nzMax]="max" [(ngModel)]="value"></nz-slider>
