<div class="controls" [class.loading]="loading">
  <label class="switch-control">
    <span
      class="switch switch--bold switch--icon"
      [class.standalone]="!switchLabelElm?.nativeElement?.textContent"
      [class.switch--loading]="loading"
      [class.switch--checked]="checked"
    >
      <label>
        <input type="checkbox" #input [checked]="checked" [disabled]="disabled" (click)="_onInputClick($event)" />
        <span [class.with-custom-icon]="customIcon">
          <span class="custom-icon" [class.active]="checked" *ngIf="customIcon">{{ customIcon }}</span>
          <span class="loader-icon" *ngIf="loading"></span>
        </span>
      </label>
    </span>
    <span #switchLabel>
      <ng-content></ng-content>
    </span>
  </label>
</div>
