import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ConnectedPosition } from '@angular/cdk/overlay';

// todo: refactor this mess
@Component({
  selector: 'app-cog-dropdown',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './cog-dropdown.component.html',
  styleUrls: ['./cog-dropdown.component.scss']
})
export class CogDropdownComponent implements OnInit {
  private _isOpen = false;
  private _isMulti: boolean;
  private _dots: boolean;
  private _hasSelection = true;
  private _isDisabled: boolean;

  positions: ConnectedPosition[];

  @Input()
  get isMulti(): boolean {
    return this._isMulti;
  }
  set isMulti(value: boolean) {
    const newValue = coerceBooleanProperty(value);

    if (newValue !== this._isMulti) {
      this._isMulti = newValue;
    }
  }

  @Input()
  get dots(): boolean {
    return this._dots;
  }
  set dots(value: boolean) {
    const newValue = coerceBooleanProperty(value);

    if (newValue !== this._dots) {
      this._dots = newValue;
    }
  }

  @Input()
  get hasSelection(): boolean {
    return this._hasSelection;
  }

  set hasSelection(newValue: boolean) {
    if (newValue !== this._hasSelection) {
      this._hasSelection = newValue;
    }
  }

  @Input()
  get isDisabled(): boolean {
    return this._isDisabled;
  }
  set isDisabled(newValue: boolean) {
    if (newValue !== this._isDisabled) {
      this._isDisabled = newValue;
    }
  }

  get isOpen(): boolean {
    return this._isOpen;
  }

  @Input()
  offsets: number[];

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.positions = [
      {
        originX: 'end',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: 'top',
        offsetX: this.offsets?.[0] ?? 10,
        offsetY: this.offsets?.[1] ?? 0
      }
    ];
  }

  toggleOpen(): void {
    !this._isOpen ? this.open() : this.close();
  }

  close(): void {
    this._isOpen = false;
    this.cdRef.markForCheck();
  }

  open(): void {
    this._isOpen = true;
    this.cdRef.markForCheck();
  }
}
