import { Injectable } from '@angular/core';
import { DateUtils } from '@app/shared/utils/date.utils';
import { QueryParamBuilder, QueryParamGroup } from '@ngqp/core';

@Injectable({
  providedIn: 'root',
})
export class ResponseListService {
  serviceParamGroup: QueryParamGroup;

  constructor(private qpb: QueryParamBuilder) {
    this.serviceParamGroup = qpb.group({
      q: qpb.stringParam('q', { debounceTime: 300, emptyOn: '' }),
      locale: qpb.stringParam('locale'),
      surveyid: qpb.stringParam('surveyid'),
      labelid: qpb.stringParam('labelid'),
      status: qpb.stringParam('status'),
      responseStatus: qpb.stringParam('responseStatus'),
      page: qpb.numberParam('page', { emptyOn: 1 }),
      date: qpb.partition<Date[], unknown>(
        // @ts-ignore
        [qpb.stringParam('datecreatedmin', { emptyOn: null }), qpb.stringParam('datecreatedmax', { emptyOn: null })],
        {
          partition: (dates: Date[]): string[] => (DateUtils.formatDates(dates) as string[]) ?? [],
          reduce: (values: string[]): Date[] => (DateUtils.formatLocaleDates(values) as Date[]) ?? [],
        }
      ),
      score: qpb.partition(
        [
          qpb.numberParam('scoremin', { emptyOn: 1, debounceTime: 200 }),
          qpb.numberParam('scoremax', { emptyOn: 10, debounceTime: 200 }),
        ],
        {
          partition: (scoreRange) => scoreRange || [null, null],
          reduce: (values) => values,
        }
      ),
      metadataidvaluepairs: qpb.param<string[]>('metadataidvaluepairs', {
        serialize: (values: string[]) => (values?.length ? values?.join(',') : null),
        deserialize: (value: string) => (value ? value?.split(',') : null),
      }),
    });
  }
}
