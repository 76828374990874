import { NgModule } from '@angular/core';

import { ActionSelectComponent } from './action-select.component';
import { ActionSelectItemComponent } from './action-select-item.component';
import { CommonModule } from '@angular/common';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

const COMPONENT = [ActionSelectComponent, ActionSelectItemComponent];

@NgModule({
  imports: [CommonModule, BsDropdownModule],
  exports: [...COMPONENT],
  declarations: [...COMPONENT]
})
export class ActionSelectModule {}
