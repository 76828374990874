import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { CommonConstants } from '@app/core/constants/common';
import { ScoreColorSchemeEnum } from '@app/shared/enums/score-color-scheme.enum';

@Directive({
  selector: '[appScoreColor]',
})
export class ScoreColorDirective implements OnInit, OnChanges {
  readonly COLOR_SCHEME = CommonConstants.SCORE_COLOR_SCHEME;
  defaultColor = 'rgb(84, 181, 235)';

  @Input()
  appScoreColor: number;

  @Input()
  colorOverride: string;

  constructor(public element: ElementRef, private renderer: Renderer2, private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.setColorOnElementRef();
  }

  setColorOnElementRef(): void {
    let currentColor = this.colorOverride || this.defaultColor;
    if (typeof this.appScoreColor !== 'undefined' && !this.colorOverride) {
      currentColor = this.getColor(Number(this.appScoreColor));
    }
    this.renderer.setStyle(this.element.nativeElement, 'background-color', currentColor);
    this.cdRef.markForCheck();
  }

  private getColor(score: number): string {
    if (score < 5) {
      return this.COLOR_SCHEME[ScoreColorSchemeEnum.NEGATIVE];
    } else if (score >= 5 && score < 7) {
      return this.COLOR_SCHEME[ScoreColorSchemeEnum.NEUTRAL];
    } else if (score >= 7) {
      return this.COLOR_SCHEME[ScoreColorSchemeEnum.POSITIVE];
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && !changes.appScoreColor.firstChange) {
      this.setColorOnElementRef();
    }
  }
}
