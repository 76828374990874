import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getStubResponse } from '@app/core/mocks/mocks.mapping';
import { Observable, of } from 'rxjs';

@Injectable()
export class MocksInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const stubResponse: object | ((...args) => object) = getStubResponse(request.method, request.url, request.body);
    if (stubResponse) {
      return of(
        new HttpResponse({
          status: 200,
          body: stubResponse
        })
      );
    }
    return next.handle(request);
  }
}
