/* eslint-disable */
import { coerceBooleanProperty } from '@angular/cdk/coercion';

export function CoerceBooleanProperty(): (target: any, propName: string) => void {
  return function(target: object, propertyKey: string, descriptor?: TypedPropertyDescriptor<any>): any {
    const privatePropName = `$$__insPropDecorator__${propertyKey}`;

    Object.defineProperty(target, privatePropName, {
      configurable: true,
      writable: true
    });

    return {
      get(): string {
        return descriptor && descriptor.get ? descriptor.get.bind(this)() : this[privatePropName];
      },
      set(value: any): void {
        const newValue = coerceBooleanProperty(value);
        if (descriptor && descriptor.set) {
          descriptor.set.bind(this)(newValue);
        }
        this[privatePropName] = newValue;
      }
    };
  };
}
