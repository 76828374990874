import { createAction, props } from '@ngrx/store';
import { ITokenModel } from './auth.models';

export const redirectToLogin = createAction('[Auth] Redirect User To Login', props<{ state: any }>());

export const getToken = createAction('[Auth] Get Token');

export const renewToken = createAction('[Auth] Start token renewal');

export const tokenRenewalSuccess = createAction('[Auth] Token renewal successful', props<{ token: ITokenModel }>());

export const tokenRenewalFailed = createAction('[Auth] Token renewal failed', props<{ error: any }>());

export const getTokenSuccess = createAction('[Auth] Get Token Success', props<{ token: ITokenModel }>());

export const getTokenFailure = createAction('[Auth] Get Token Failure', props<{ error: any }>());

export const checkSession = createAction('[Auth] Check Session');
export const checkSessionSuccess = createAction('[Auth] Check Session Success', props<{ token: ITokenModel }>());
export const checkSessionFailure = createAction('[Auth] Check Session Failure', props<{ err: any }>());

export const serverRejectedRequest = createAction('[Auth/Error] Server rejected request, logging out');

export const logOut = createAction('[Auth] Log Out');
