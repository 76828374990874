<div class="header header--fixed">
  <div class="header__logo">
    <img src="/assets/images/brand/insocial_black.svg" />
  </div>

  <div class="header__topbar">
    <div class="header__topbar-item">
      <button (click)="leaveFeedback()" class="btn btn-primary btn-feedback" id="surveyLoader">{{ "header.give-feedback" | transloco }}</button>
    </div>
    <app-user-header
      (onLogout)="logOut()"
      [userData]="userData$ | async"
      [hideScopeItem]="isAutoSelected$ | async"
      (onChangeLang)="changeLanguage($event)"
      [scopeInUrl]="!!(scopeId$ | async)"
    ></app-user-header>
  </div>
</div>
