import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'ins-badge',
  template: `
    <ng-content></ng-content>
  `,
  styleUrls: ['badge.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BadgeComponent {
  @HostBinding('class')
  _badge = 'badge-primary';
  @Input()
  set badge(value) {
    this._badge = 'badge-' + value || 'primary';
    this.cd.markForCheck();
  }

  @HostBinding('class.badge')
  @HostBinding('class.rounded-pill')
  @HostBinding('class.badge-center')
  defaultClasses = true;

  constructor(private cd: ChangeDetectorRef) {}
}
