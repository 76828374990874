import { Component, HostBinding, Input } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'ins-card-content',
  template: `
    <ng-content></ng-content>
  `
})
export class InsCardContentComponent {
  @HostBinding('class')
  componentClass = 'ins-card__body';

  @HostBinding('class.is-fluid')
  _isFluid = false;

  // todo: this is for old code where inner styling has padding
  @Input()
  set fluid(value) {
    this._isFluid = coerceBooleanProperty(value);
  }
}
