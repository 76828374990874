import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NOTIFICATIONS_IMPORTANCE_ENUM } from '@app/dashboard/notifications/core/models/notifications.enums';
import { ColorScheme } from '@app/shared/enums/color-scheme.enum';
import { NotificationRuleImportanceEnum } from '@app/dashboard/notification-rules/core/enums/notification-rule-importance.enum';

const labelImportanceColor = {
  [NOTIFICATIONS_IMPORTANCE_ENUM.LOW]: ColorScheme.GREEN,
  [NOTIFICATIONS_IMPORTANCE_ENUM.MEDIUM]: ColorScheme.YELLOW,
  [NOTIFICATIONS_IMPORTANCE_ENUM.HIGH]: ColorScheme.RED,
};

@Component({
  selector: 'app-notifications-status-label',
  templateUrl: './notifications-status-label.component.html',
  styleUrls: ['./notifications-status-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsStatusLabelComponent implements OnInit {
  backgroundColor: string;

  @Input()
  notificationImportance: NotificationRuleImportanceEnum;

  constructor() {}

  ngOnInit() {
    this.backgroundColor =
      typeof this.notificationImportance === 'string'
        ? labelImportanceColor[this.notificationImportance]
        : NOTIFICATIONS_IMPORTANCE_ENUM.LOW;
  }
}
