<ng-scrollbar
  pointerEventsMethod="scrollbar"
  appearance="compact"
  [style.height.px]="listItems.length > maxItemLength ? maxItemLength * itemSize : listItems.length * itemSize + 1"
  [style.max-height.px]="maxItemLength * itemSize"
>
  <cdk-virtual-scroll-viewport
    [itemSize]="itemSize"
    [maxBufferPx]="itemSize * maxItemLength"
    [minBufferPx]="itemSize * maxItemLength"
    (scrolledIndexChange)="scrolledIndexChange($event)"
    scrollViewport
  >
    <ng-template
      cdkVirtualFor
      [cdkVirtualForOf]="listItems"
      [cdkVirtualForTrackBy]="trackValue"
      [cdkVirtualForTemplateCacheSize]="0"
      let-item
    >
      <ng-container [ngSwitch]="item.type">
        <app-option-group-item *ngSwitchCase="'group'">{{ item.groupLabel }}</app-option-group-item>
        <app-option-item *ngSwitchCase="'item'" [item]="item"></app-option-item>
      </ng-container>
    </ng-template>
  </cdk-virtual-scroll-viewport>
</ng-scrollbar>
