import { NgModule } from '@angular/core';

import { BadgeComponent } from './badge.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule],
  exports: [BadgeComponent],
  declarations: [BadgeComponent],
  providers: []
})
export class BadgeModule {}
