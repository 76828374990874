import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { CustomDialogComponent } from './custom-dialog.component';
import { ButtonModule } from '@app/shared/components/button/button.module';
import { TranslocoModule } from '@ngneat/transloco';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
  imports: [ButtonModule, TranslocoModule, CommonModule, SharedModule],
  exports: [CustomDialogComponent],
  declarations: [CustomDialogComponent],
  providers: []
})
export class CustomDialogModule {}
