import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  CdkConnectedOverlay,
  ConnectedOverlayPositionChange,
  ConnectedPosition,
  VerticalConnectionPos,
} from '@angular/cdk/overlay';

@Component({
  selector: 'app-hosted-dropdown',
  templateUrl: './hosted-dropdown.component.html',
  styleUrls: ['./hosted-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HostedDropdownComponent implements OnInit, OnChanges {
  private requestId: number = null;
  triggerWidth: number;

  @ViewChild(CdkConnectedOverlay, { static: true })
  overlay: CdkConnectedOverlay;

  @Input()
  content: TemplateRef<never>;
  @Input()
  offsetY: number;
  @Input()
  positionOverride: ConnectedPosition[];

  @Input()
  contentContext: Record<string, any> = {};

  _open = false;
  @Input()
  set open(value: boolean) {
    this._open = value;
    this.cdRef.markForCheck();
  }

  get open(): boolean {
    return this._open;
  }

  @Output()
  openChange = new EventEmitter<boolean>();

  _ignoreTriggerWidth = false;
  @Input()
  set ignoreTriggerWidth(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    if (newValue !== this._ignoreTriggerWidth) {
      this._ignoreTriggerWidth = newValue;
    }
  }

  _useHostWidth: boolean;
  @Input('autoWidth')
  set useHostWidth(value: number) {
    this._useHostWidth = coerceBooleanProperty(value);
  }

  _dropdownWidth: number | undefined;
  @Input()
  set dropdownWidth(value: number) {
    this._dropdownWidth = +value;
  }

  @Output()
  onClose = new EventEmitter<void>();
  @Output()
  positionChange = new EventEmitter<VerticalConnectionPos>();

  constructor(private cdRef: ChangeDetectorRef, public elementRef: ElementRef) {}

  ngOnInit(): void {
    if (this._useHostWidth || this._dropdownWidth) {
      this.overlay.width = this._dropdownWidth
        ? this._dropdownWidth
        : this.elementRef.nativeElement.getBoundingClientRect().width;
    }

    if (this.positionOverride) {
      this.overlay.positions = this.positionOverride;
    }
  }

  toggleOpen(): void {
    this.open = !this.open;
  }

  updateCdkConnectedOverlayStatus(): void {
    if (this.elementRef.nativeElement) {
      const triggerWidth = this.triggerWidth;
      cancelAnimationFrame(this.requestId);

      this.requestId = requestAnimationFrame(() => {
        this.triggerWidth = this.elementRef.nativeElement.getBoundingClientRect().width;
        if (triggerWidth !== this.triggerWidth) {
          this.cdRef.detectChanges();
        }
      });
    }
  }

  updateCdkConnectedOverlayOrigin(): void {
    this.overlay?.overlayRef?.updatePosition();
  }

  close(event?: MouseEvent): void {
    if (event) {
      // event.stopPropagation();
    }

    this.open = false;
    this.onClose.emit();
    this.openChange.emit(false);
  }

  onPositionChange($event: ConnectedOverlayPositionChange): void {
    this.positionChange.emit($event.connectionPair.originY);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this._ignoreTriggerWidth && changes.open?.previousValue !== changes.open?.currentValue) {
      this.updateCdkConnectedOverlayStatus();
    }
  }
}
