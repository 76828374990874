import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ScopeFacade } from '@app/core/root-store/scope-store/scope.facade';

@Injectable({
  providedIn: 'root'
})
export class AutoSelectScopeGuard implements CanActivate {
  constructor(private scopeFacade: ScopeFacade) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.scopeFacade.isAutoSelected$.pipe(map(isAutoSelected => !isAutoSelected));
  }
}
