export enum PublicTypesEnum {
  AVATAR = 'avatar',
  AUTHOR = 'author',
  TESTIMONIAL = 'testimonial'
}

export enum InternalPublicTypes {
  REVIEW = 'review-list'
}

export const publicTypes = Object.keys(PublicTypesEnum).map(key => key.toLocaleLowerCase());
