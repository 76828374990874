import { Component, HostBinding } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

@Component({
  templateUrl: 'info-toast.component.html',
  styleUrls: ['info-toast.component.scss']
})
export class InfoToastComponent extends Toast {
  icon = 'info';
  toastAction: boolean;

  @HostBinding('class')
  type: string;

  constructor(protected toastrService: ToastrService, public toastPackage: ToastPackage) {
    super(toastrService, toastPackage);
  }

  action(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.toastPackage.triggerAction();
  }
}
