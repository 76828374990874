import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonRoutes } from '@app/core/constants/routes/common-routes.constant';
import { ManagementRoutes } from '@app/core/constants/routes/management-routes.constant';
import { Roles } from '@app/shared/enums/roles.enum';
import { IUser } from '@app/shared/models/accounts/user.model';
import { IScope } from '@app/shared/models/common/scope.model';
import { environment } from '@env/environment';
import { UserService } from '@app/core/services/user.service';

@Component({
  selector: 'app-user-header',
  templateUrl: './user-header.component.html',
  styleUrls: ['./user-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserHeaderComponent {
  languages = environment.i18n.languages;
  managementRoutes = ManagementRoutes;
  commonRoutes = CommonRoutes;
  roles = Roles;
  user: IUser;
  scope: IScope;
  isAdmin = false;

  @Input()
  hideScopeItem: boolean;
  @Input()
  scopeInUrl: boolean;

  @Input()
  set userData([scope, user, isAdmin]) {
    this.scope = scope;
    this.user = user;
    this.isAdmin = isAdmin;
  }

  @Output()
  onLogout = new EventEmitter<boolean>();
  @Output()
  onChangeLang = new EventEmitter<{ locale: string; user_id: string }>();

  constructor(private userService: UserService) {}

  logout(): void {
    this.onLogout.emit(true);
  }

  changeLang(lang: string): void {
    this.onChangeLang.emit({ locale: lang, user_id: this.user.id });
  }

  isImpersonating(): boolean {
    return !!this.userService.getImpersonation();
  }

  exitImpersonation() {
    this.userService.stopImpersonation();
  }
}
