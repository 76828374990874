import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { ColorScheme } from '@app/shared/enums/color-scheme.enum';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AvatarComponent {
  @Input()
  text: string;

  @Input()
  bgColor: string;

  @HostBinding('style.background')
  get _bgColor() {
    return this.formatColor(this.bgColor).trim();
  }

  get computedText(): string {
    if (!this.text) {
      return '';
    }
    const words = this.text.split(' ');
    return words.length > 1 ? words[0].substring(0, 1) + words[1].substring(0, 1) : words[0].substring(0, 1);
  }

  formatColor(color: string): string {
    // todo: do this before we put this in the store
    // todo: if statement can go, fallback is insocial color
    if (color) {
      if (!color.startsWith('#')) {
        return `#`.concat(color);
      }
      return color;
    }
    return ColorScheme.SECONDARY;
  }
}
