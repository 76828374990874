import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as fromActions from '../actions/scope.actions';
import { IScope } from '@app/shared/models/common/scope.model';
import { UserProduct } from '@app/modules/product-management/core/models/customer-product.model';

export const scopeFeatureKey = 'app';

export interface State {
  scope: IScope;
  products: UserProduct[];
  error: any;
  loading: boolean;
  loaded: boolean;
  productsLoaded: boolean;
  scopeAutoSelected: boolean;
}

export const scopeState = createFeatureSelector<State>(scopeFeatureKey);

export const initialState: State = {
  scope: null,
  products: null,
  error: null,
  loaded: false,
  productsLoaded: false,
  loading: true,
  scopeAutoSelected: false,
};

export const scopeReducer = createReducer(
  initialState,
  on(fromActions.getScope, (state, action) => ({
    ...state,
    error: null,
    loaded: state.scope?.id === action.customerId,
    loading: true,
  })),
  on(fromActions.getScopeSuccess, (state, { scope, autoSelected }) => ({
    ...state,
    error: null,
    loaded: true,
    loading: false,
    scopeAutoSelected: autoSelected,
    scope,
  })),
  on(fromActions.getScopeFailed, (state, { error }) => ({
    ...state,
    error,
    loaded: false,
    loading: false,
  })),
  on(fromActions.getActivatedProductsSuccess, (state, { products }) => ({
    ...state,
    products,
    productsLoaded: true,
  })),
  on(fromActions.updateScope, (state, { scope }) => ({
    ...state,
    scope: {
      ...state.scope,
      ...scope,
    },
  })),
  on(fromActions.checkScopeSession, (state) => ({
    ...state,
    loading: true,
  })),
  on(fromActions.checkScopeSessionFailed, (state) => ({
    ...state,
    loading: false,
  })),
  on(fromActions.unsetScope, (state) => ({
    ...state,
    scope: initialState.scope,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return scopeReducer(state, action);
}

export const getCustomer = (state: State) => state.scope;

export const getScopeState = createSelector(scopeState, (state) => state.scope);

export const getProductsState = createSelector(scopeState, (state) => state.products);

export const getModelId = createSelector(scopeState, (state) => {
  if (state.scope.models?.length) {
    return state.scope.models[0].id;
  }
  return null;
});

export const scopeIsLoading = createSelector(scopeState, (state: State) => state.loading);

export const scopeIsLoaded = createSelector(scopeState, (state: State) => state.loaded);
export const productsLoaded = createSelector(scopeState, (state: State) => state.productsLoaded);

export const selectIsAutoSelected = createSelector(scopeState, (state) => state.scopeAutoSelected);

export const getContactRoles = createSelector(scopeState, (state) => {
  if (state && state.scope && state.scope.roles) {
    return { roles: state.scope.roles };
  } else {
    return { roles: [] };
  }
});
