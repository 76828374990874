import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss']
})
export class TagComponent {
  @Input()
  buttonText;

  @Output()
  tagClick: EventEmitter<any> = new EventEmitter<void>();

  onClickHandler(): void {
    this.tagClick.emit();
  }
}
