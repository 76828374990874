import { ControlValueAccessor, NgControl, NgModel } from '@angular/forms';
import { ChangeDetectorRef, Directive, EventEmitter } from '@angular/core';
import { skip } from 'rxjs/operators';
import { Subject } from 'rxjs';

const EMPTY_FUNCTION: (value?: any) => void = () => {};

@Directive()
export abstract class AbstractControl<T> implements ControlValueAccessor {
  private _value?: T | null;
  private _previousValue?: T | null;
  private _disabled?: boolean;

  private onTouched = EMPTY_FUNCTION;

  private onChange = EMPTY_FUNCTION;

  controlValueChange = new EventEmitter<T>();
  private writeValueNotifier = new Subject<T>();
  writeValueNotifier$ = this.writeValueNotifier.asObservable().pipe(skip(1));

  protected constructor(
    private readonly ngControl: NgControl | null,
    protected readonly changeDetectorRef: ChangeDetectorRef,
    protected _fallbackValue = null
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  get controlValue(): T {
    return this._value ?? this._fallbackValue;
  }

  get safeCurrentValue(): T {
    return this.rawValue();
  }

  get disabled(): boolean {
    return this.safeControlData<boolean>(({ disabled }) => disabled) || this._disabled;
  }

  get valid(): boolean {
    return this.safeControlData<boolean>(({ valid }) => valid);
  }

  get invalid(): boolean {
    return this.safeControlData<boolean>(({ invalid }) => invalid);
  }

  private rawValue(): T | undefined {
    const { ngControl } = this;

    if (ngControl === null) {
      return undefined;
    }

    return ngControl instanceof NgModel && this._value === undefined ? ngControl.viewModel : ngControl.value;
  }

  protected updateFocused(focused: boolean) {
    if (!focused) {
      this.controlMarkAsTouched();
    }
  }

  protected updateTouched() {
    this.controlMarkAsTouched();
  }

  checkControlUpdate(): void {
    this.changeDetectorRef.markForCheck();
  }

  registerOnChange(onChange: (value: T) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState(value: boolean): void {
    this._disabled = value;
    this.checkControlUpdate();
  }

  writeValue(value: T | null): void {
    this.setLocalValue(value);
  }

  private safeControlData<G>(extractor: (ngControl: NgControl) => G | null | undefined): G {
    return this.ngControl && extractor(this.ngControl);
  }

  protected updateValue(value: T): void {
    if (this.disabled) {
      return;
    }

    this._value = value;
    this.controlSetValue(value);
  }

  private controlMarkAsTouched(): void {
    this.onTouched();
    this.checkControlUpdate();
  }

  private controlSetValue(value: T): void {
    this.onChange(value);
    this.controlValueChange.emit(value);
    this.checkControlUpdate();
  }

  private setLocalValue(value: T | null): void {
    this._previousValue = this._value;
    this._value = value;
    this.writeValueNotifier.next(null);

    this.checkControlUpdate();
  }
}
