import { OverlayModule } from '@angular/cdk/overlay';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AccordionModule } from '@app/shared/components/accordion/accordion.module';
import { ActionSelectModule } from '@app/shared/components/action-select/action-select.module';
import { AppLoaderComponent } from '@app/shared/components/app-loader/app-loader.component';
import { AvatarComponent } from '@app/shared/components/avatar/avatar.component';
import { NoResultsComponent } from '@app/shared/components/no-results/no-results.component';
import { BadgeModule } from '@app/shared/components/badge/badge.module';
import { BreadcrumbModule } from '@app/shared/components/breadcrumb/breadcrumb.module';
import { ButtonModule } from '@app/shared/components/button/button.module';
import { InsCardModule } from '@app/shared/components/card/ins-card.module';
import { CheckboxModule } from '@app/shared/components/checkbox/checkbox.module';
import { CogDropdownComponent } from '@app/shared/components/cog-dropdown/cog-dropdown.component';
import { CogItemComponent } from '@app/shared/components/cog-dropdown/cog-item.component';
import { DatepickerModule } from '@app/shared/components/datepicker/datepicker.module';
import { DetailRowComponent } from '@app/shared/components/detail/detail-row.component';
import { DetailComponent } from '@app/shared/components/detail/detail.component';
import { EditorModule } from '@app/shared/components/editor/editor.module';
import { FlexTableModule } from '@app/shared/components/flex-table/flex-table.module';
import { FormGroupModule } from '@app/shared/components/form-group/form-group.module';
import { HoverableBlockComponent } from '@app/shared/components/hoverable-block/hoverable-block.component';
import { InstantSearchModule } from '@app/shared/components/live-search/instant-search.module';
import { LoaderModule } from '@app/shared/components/loader/loader.module';
import { ModalBaseComponent } from '@app/shared/components/modal/modal-base.component';
import { ModalComponent } from '@app/shared/components/modal/modal.component';
import { PageHeaderModule } from '@app/shared/components/page-header/page-header.module';
import { ProgressModule } from '@app/shared/components/progress/progress.module';
import { RadioModule } from '@app/shared/components/radio/radio.module';
import { SelectModule } from '@app/shared/components/select/select.module';
import { SubheaderComponent } from '@app/shared/components/subheader/subheader.component';
import { SwitchComponent } from '@app/shared/components/switch/switch.component';
import { InsTabsModule } from '@app/shared/components/tabs/ins-tabs.module';
import { BindQueryParamsDirective } from '@app/shared/directives/bind-query-params.directive';
import { FocusDirective } from '@app/shared/directives/focus.directive';
import { HasRolesDirective } from '@app/shared/directives/has-roles.directive';
import { OverflowedHighlightDirective } from '@app/shared/directives/overflowed-highlight.directive';
import { ScoreColorDirective } from '@app/shared/directives/score-color.directive';
import { SelectInputContentDirective } from '@app/shared/directives/select-input-content.directive';
import { EllipsisPipe } from '@app/shared/pipes/ellipsis.pipe';
import { FilterPipe } from '@app/shared/pipes/filter.pipe';
import { I18nFlagPipe } from '@app/shared/pipes/i18n-flags/i18n-flag.pipe';
import { LocalizedDatePipe } from '@app/shared/pipes/localized-date.pipe';
import { PaginationPipe } from '@app/shared/pipes/pagination.pipe';
import { SafePipe } from '@app/shared/pipes/core/safe.pipe';
import { SegmentsPipe } from '@app/shared/pipes/segments.pipe';
import { SplitPipe } from '@app/shared/pipes/split.pipe';
import { popperVariation, TippyModule, tooltipVariation } from '@ngneat/helipopper';
import { TranslocoModule } from '@ngneat/transloco';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzSliderModule } from 'ng-zorro-antd/slider';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { DecodePipe } from './pipes/decode.pipe';
import { FallbackPipe } from './pipes/fallback.pipe';
import { MustacheReplacePipe } from './pipes/mustache-replace.pipe';
import { HoverDirective } from './directives/hover.directive';
import { ScorePipe } from '@app/shared/pipes/score.pipe';
import { ComponentTemplateHostDirective } from '@app/shared/directives/component-template-host.directive';
import { KeysPipe } from '@app/shared/pipes/keys.pipe';
import { SelectionGroupModule } from '@app/shared/modules/selection-group/selection-group.module';
import { FindByPipe } from '@app/shared/pipes/find-by.pipe';
import { PluckPipe } from '@app/shared/pipes/core/pluck.pipe';
import { JoinPipe } from './pipes/core/join.pipe';
import { IsDefinedPipe } from './pipes/is-defined.pipe';
import { HideByIdDirective } from './directives/hide-by-id.directive';
import { CountComponent } from '@app/shared/components/count/count.component';
import { NotificationsStatusLabelComponent } from '@app/shared/components/notifications-status-label/notifications-status-label.component';
import { MapperPipe } from './pipes/core/mapper.pipe';
import { InsAliasDirective } from './directives/alias/alias.directive';
import { InsRepeatDirective } from './directives/ins-repeat.directive';
import { AliasModule } from '@app/shared/directives/alias/alias.module';
import { TagComponent } from './components/tag/tag.component';
import { AddButtonComponent } from '@app/shared/components/add-button/add-button.component';
import { IconActionButtonComponent } from '@app/shared/components/icon-action-button/icon-action-button.component';
import { AmpersandPipe } from '@app/shared/pipes/ampersand.pipe';
import { CronParserPipe } from '@app/shared/pipes/cron-parser.pipe';
import { ButtonGroupModule } from '@app/shared/components/button-group/button-group.module';
import { HostedDropdownModule } from '@app/shared/modules/hosted-dropdown/hosted-dropdown.module';
import { BlobPreviewModule } from '@app/shared/components/ins-blob/blob-preview.module';
import { PortletCtaComponent } from './components/portlet-cta/portlet-cta.component';
import { WizardBodyContentComponent } from './components/wizard-body-content/wizard-body-content.component';
import { ElementInitDirective } from './directives/element-init/element-init.directive';
import { NumberArray } from './pipes/number-array';
import { InsFlagComponent } from './components/ins-flag/ins-flag.component';
import { LanguagePickerComponent } from './components/language-picker/language-picker.component';
import { LogoUploadPreviewComponent } from '@app/shared/components/logo-upload-preview/logo-upload-preview.component';
import { FormatArrayPipe } from './pipes/format-array.pipe';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ChipsModule } from '@app/shared/components/chips/chips.module';
import { ValuesPipe } from '@app/shared/pipes/values.pipe';
import { CapitalizePipe } from '@app/shared/pipes/capitalize.pipe';
import { HideByRoleDirective } from '@app/shared/directives/hide-by-role.directive';
import { ExportingModalComponent } from '@app/shared/components/exporting-modal/exporting-modal.component';
import { ScoreSliderComponent } from '@app/shared/components/exporting-modal/score-slider/score-slider.component';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { ScriptGeneratorComponent } from '@app/shared/components/exporting-modal/script-generator/script-generator.component';
import { FileUploaderModule } from '@app/shared/modules/file-uploader/file-uploader.module';
import { ColorInputModule } from '@app/shared/modules/color-row/color-input.module';
import { ShowByRolesDirective } from '@app/shared/directives/show-by-roles.directive';
import { DatepickerV2Component } from '@app/shared/components/datepickerV2/datepickerV2.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {};

const EXTERNAL_UI_MODULES = [
  CdkTableModule,
  CdkTreeModule,
  NzBadgeModule,
  NzSliderModule,
  TippyModule.forRoot({
    defaultVariation: 'tooltip',
    variations: {
      tooltip: tooltipVariation,
      popper: popperVariation,
    },
  }),
];

const UI_MODULES = [
  FileUploaderModule,
  AccordionModule,
  ActionSelectModule,
  BadgeModule,
  BreadcrumbModule,
  ButtonModule,
  ButtonGroupModule,
  InsCardModule,
  CheckboxModule,
  DatepickerModule,
  EditorModule,
  FlexTableModule,
  FormGroupModule,
  InstantSearchModule,
  LoaderModule,
  PageHeaderModule,
  ProgressModule,
  RadioModule,
  SelectModule,
  InsTabsModule,
  SelectionGroupModule,
  AliasModule,
  BlobPreviewModule,
];

const COMPONENTS = [
  ExportingModalComponent,
  ScoreSliderComponent,
  ScriptGeneratorComponent,
  AppLoaderComponent,
  AddButtonComponent,
  IconActionButtonComponent,
  AvatarComponent,
  CogDropdownComponent,
  CogItemComponent,
  DetailComponent,
  DetailRowComponent,
  HoverableBlockComponent,
  ModalBaseComponent,
  ModalComponent,
  SubheaderComponent,
  SwitchComponent,
  NoResultsComponent,
  CountComponent,
  NotificationsStatusLabelComponent,
  TagComponent,
  LogoUploadPreviewComponent,
  DatepickerV2Component,
];

const PIPES = [
  DecodePipe,
  CapitalizePipe,
  CronParserPipe,
  AmpersandPipe,
  EllipsisPipe,
  FilterPipe,
  I18nFlagPipe,
  LocalizedDatePipe,
  PaginationPipe,
  SafePipe,
  SegmentsPipe,
  SplitPipe,
  FallbackPipe,
  MustacheReplacePipe,
  ScorePipe,
  FallbackPipe,
  KeysPipe,
  ValuesPipe,
  PluckPipe,
  FindByPipe,
  JoinPipe,
  IsDefinedPipe,
];

const DIRECTIVES = [
  BindQueryParamsDirective,
  FocusDirective,
  HasRolesDirective,
  HideByRoleDirective,
  OverflowedHighlightDirective,
  SelectInputContentDirective,
  ScoreColorDirective,
  HoverDirective,
  ComponentTemplateHostDirective,
  ShowByRolesDirective,
];

@NgModule({
  declarations: [
    COMPONENTS,
    PIPES,
    DIRECTIVES,
    HideByIdDirective,
    MapperPipe,
    InsRepeatDirective,
    PortletCtaComponent,
    WizardBodyContentComponent,
    ElementInitDirective,
    NumberArray,
    InsFlagComponent,
    LanguagePickerComponent,
    FormatArrayPipe,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslocoModule,
    FlexLayoutModule,
    PaginationModule.forRoot(),
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    OverlayModule,
    EXTERNAL_UI_MODULES,
    UI_MODULES,
    HostedDropdownModule,
    NgScrollbarModule,
    ChipsModule,
    NgJsonEditorModule,
    ColorInputModule,
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslocoModule,
    FlexLayoutModule,
    BsDatepickerModule,
    EXTERNAL_UI_MODULES,
    UI_MODULES,
    COMPONENTS,
    PIPES,
    DIRECTIVES,
    IsDefinedPipe,
    HideByIdDirective,
    MapperPipe,
    InsAliasDirective,
    InsRepeatDirective,
    PortletCtaComponent,
    WizardBodyContentComponent,
    ElementInitDirective,
    NumberArray,
    InsFlagComponent,
    LanguagePickerComponent,
    FormatArrayPipe,
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ],
})
export class SharedModule {}
