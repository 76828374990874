<app-modal [headerColor]="headerColor" [enableClose]="false">
  <ng-container modal-title-image>
    <img alt="Logo" src="assets/images/brand/insocial_white.svg" />
  </ng-container>
  <ng-container modal-content [formGroup]="form">
    <div class="modal-wrapper">
      <h1>
        {{ 'collect-modal.title-type' | transloco }}
      </h1>
      <p>
        {{ 'collect-modal.description-type' | transloco }}
      </p>
      <app-select [placeholder]="'collect-modal.placeholder-type' | transloco" formControlName="marketingType">
        <app-option *ngFor="let type of marketingTypes" [value]="type">
          {{ marketingTypeLabels[type] }}
        </app-option>
      </app-select>
      <h1>
        {{ 'collect-modal.title-newsletter' | transloco }}
      </h1>
      <p>
        {{ 'collect-modal.description-newsletter' | transloco }}
      </p>
      <app-select [placeholder]="'collect-modal.placeholder-newsletter' | transloco"
                  formControlName="receiveMarketingUpdates">
        <app-option *ngFor="let choice of choices" [value]="choice.value">
          {{ choice.label }}
        </app-option>
      </app-select>
    </div>
  </ng-container>
  <ng-container modal-actions>
    <img src="/assets/images/powered_by_insocial_black.svg" alt="Powered by Insocial" />
    <ins-button [disabled]="!this.form.valid" class="ms-2" (onClick)="submit()" [loading]="posting">
      {{ 'collect-modal.submit' | transloco }}
    </ins-button>
  </ng-container>
</app-modal>
