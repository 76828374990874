import { Component, OnInit } from '@angular/core';
import { AuthFacade } from 'ins-auth';

@Component({
  template: `
    <app-loader></app-loader>
  `,
  styleUrls: ['./auth-redirect.component.scss']
})
export class AuthRedirectComponent implements OnInit {
  constructor(private authFacade: AuthFacade) {}

  ngOnInit(): void {
    this.authFacade.redirectToLogin();
  }
}
