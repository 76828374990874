import { Component, HostBinding, ViewEncapsulation } from '@angular/core';
import { CommonRoutes } from '@app/core/constants/routes/common-routes.constant';

@Component({
  selector: 'sidebar-brand',
  templateUrl: './sidebar-brand.component.html',
  encapsulation: ViewEncapsulation.None
})
export class SidebarBrandComponent {
  readonly COMMON_ROUTES: typeof CommonRoutes = CommonRoutes;
  @HostBinding('class.aside__brand')
  componentClass = true;
}
