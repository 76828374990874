import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'findBy'
})
export class FindByPipe implements PipeTransform {
  transform<T>(input: T[], key: any, value: any): T {
    if (!input) {
      return null;
    }

    return input.find(item => item[key] === value);
  }
}
