import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

export const CHECKBOX_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SwitchComponent),
  multi: true,
};

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  providers: [CHECKBOX_CONTROL_VALUE_ACCESSOR],
})
export class SwitchComponent implements ControlValueAccessor {
  @ViewChild('input') _inputElement: ElementRef<HTMLInputElement>;
  @ViewChild('switchLabel') switchLabelElm: ElementRef<HTMLElement>;

  /**
   * @deprecated use valueChange
   */
  @Output() readonly onClick: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() readonly valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  private _checked = false;
  @Input()
  set checked(value: boolean) {
    if (value !== this._checked) {
      this._checked = value;
      this.cdRef.detectChanges();
    }
  }
  get checked(): boolean {
    return this._checked;
  }

  @Input()
  customIcon: string;

  private _disabled = false;
  @Input()
  get disabled() {
    return this._disabled;
  }
  set disabled(value: boolean) {
    const newValue = coerceBooleanProperty(value);

    if (newValue !== this._disabled) {
      this._disabled = newValue;
    }
  }

  private _loading = false;
  @Input()
  set loading(value: boolean) {
    const newValue = coerceBooleanProperty(value);

    if (newValue !== this._loading) {
      this._loading = newValue;
      this.disabled = newValue;
    }
  }

  get loading() {
    return this._loading;
  }

  private _control = false;
  @Input()
  set control(value: boolean) {
    const newValue = coerceBooleanProperty(value);

    if (newValue !== this._control) {
      this._control = newValue;
    }
  }

  get control() {
    return this._control;
  }

  @HostListener('click', ['$event'])
  public onHostClick(event: any): void {
    event.stopPropagation();
  }

  setDisabledState(isDisabled: boolean): void {}

  private onTouch: any = () => {};
  private onChange: any = () => {};

  constructor(private cdRef: ChangeDetectorRef) {}

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  registerOnChange(fn: () => {}) {
    this.onChange = fn;
  }

  writeValue(value: any) {
    this.checked = !!value;
  }

  toggle() {
    this.checked = !this.checked;
  }

  _onInputClick(event: any) {
    event.stopPropagation();
    if (!this._control) {
      this.toggle();
    }
    this.onChange(event.target.checked);
    this.onClick.emit(event.target.checked);
    this.valueChange.emit(event.target.checked);
  }
}
