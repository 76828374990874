import { NgModule } from '@angular/core';

import { RadioComponent, RadioGroupComponent } from './radio-group.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [
    RadioGroupComponent,
    RadioComponent
  ],
  declarations: [
    RadioGroupComponent,
    RadioComponent
  ],
  providers: [],
})
export class RadioModule {
}
