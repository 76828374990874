import { Component, OnDestroy, OnInit } from '@angular/core';
import { DialogRef } from '@ngneat/dialog';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { InteractionsRoutes } from '@app/core/constants/routes/interactions-routes.constant';
import { IUser } from '@app/shared/models/accounts/user.model';
import { MarketingTypeEnum, MarketingTypeEnumLabels } from '@app/shared/models/accounts/marketing-type.enum';
import { TranslocoService } from '@ngneat/transloco';
import { ColorScheme } from '@app/shared/enums/color-scheme.enum';

@Component({
  selector: 'app-collect-info',
  templateUrl: './collect-info.component.html',
  styleUrls: ['./collect-info.component.scss'],
})
export class CollectInfoComponent implements OnDestroy, OnInit {
  readonly interactionsRoutes: typeof InteractionsRoutes = InteractionsRoutes;
  readonly marketingTypes = Object.values(MarketingTypeEnum);
  readonly marketingTypeLabels = MarketingTypeEnumLabels;
  readonly headerColor = ColorScheme.PRIMARY;
  choices = [];

  posting = false;
  ref: DialogRef;
  user: IUser;

  form = this.fb.group({
    receiveMarketingUpdates: [null, Validators.required],
    marketingType: [null, Validators.required],
  });

  constructor(
    private dialogRef: DialogRef,
    private fb: UntypedFormBuilder,
    private translocoService: TranslocoService
  ) {
    this.ref = dialogRef;
    this.user = this.ref.data.user;
  }

  ngOnInit() {
    if (this.user?.locale === 'en_GB') {
      this.choices = [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ];
    } else {
      this.choices = [
        { label: 'Ja', value: true },
        { label: 'Nee', value: false },
      ];
    }
  }

  submit() {
    this.posting = true;
    const updatedUser = { ...this.user, ...this.form.value };
    this.ref.close(updatedUser);
  }

  ngOnDestroy() {
    this.posting = false;
  }
}
