import { Component, HostBinding } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

@Component({
  template: `
    <div class="toastr" [style.display]="state.value === 'inactive' ? 'none' : ''">
      <div class="toastr-icon">
        <span class="toastr-icon-circle">
          <i class="la la-check toastr-icon-check"></i>
        </span>
      </div>
      <div class="toastr-content">
        <div class="toastr-title" *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
          {{ title }}
        </div>
        <div
          *ngIf="message && options.enableHtml"
          role="alert"
          aria-live="polite"
          [class]="options.messageClass"
          [innerHTML]="message"
        ></div>
        <div
          *ngIf="message && !options.enableHtml"
          role="alert"
          class="toastr-message"
          aria-live="polite"
          [class]="options.messageClass"
          [attr.aria-label]="message"
        >
          {{ message }}
        </div>
      </div>
      <div class="toastr-close">
        <a *ngIf="options.closeButton" (click)="remove()">
          <i class="la la-times"></i>
        </a>
      </div>
    </div>
    <div class="toast-progress-container" *ngIf="options.progressBar">
      <div class="toast-progress" [style.width]="width + '%'"></div>
    </div>
  `,
  styleUrls: ['../ins-toast.scss']
})
export class ErrorToastComponent extends Toast {
  options: any;
  message: any;
  width: any;

  @HostBinding('class.error') componentClass = true;

  constructor(protected toastrService: ToastrService, public toastPackage: ToastPackage) {
    super(toastrService, toastPackage);
  }

  action(event: Event): boolean {
    event.stopPropagation();
    this.toastPackage.triggerAction();
    return false;
  }
}
