import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { environment } from '@env/environment';
import { BehaviorSubject } from 'rxjs';
import { HashMap } from '@ngneat/transloco/lib/types';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private _currentLang$ = new BehaviorSubject<string>(null);

  availableLanguages = environment.i18n.languages;
  defaultLanguage = environment.i18n.defaultTranslation;

  constructor(private translateService: TranslocoService) {}

  useLanguage(chosenLanguage: string): void {
    const lang = this.checkIfLangExists(chosenLanguage);
    this.translateService.setActiveLang(lang);
    this._currentLang$.next(lang);
  }

  checkIfLangExists(language): string {
    return this.appLanguages.includes(language) ? language : this.defaultLanguage;
  }

  get appLanguages(): string[] {
    return this.translateService.getAvailableLangs() as string[];
  }

  get currentLang(): string {
    return this.translateService.getActiveLang();
  }

  get currentLang$() {
    return this._currentLang$.asObservable();
  }

  init(): void {
    this.translateService.setAvailableLangs(this.availableLanguages);
    this.useLanguage('nl_NL');
    this._currentLang$.next('nl_NL');
  }

  get(key: string, params?: HashMap): string {
    return this.translateService.translate(key, params);
  }
}
