import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IContactRoles } from '@app/shared/models/customer/contacts-roles.model';
import { map } from 'rxjs/operators';
import { IMeta } from '@app/shared/models/accounts/user.model';
import { environment } from '@env/environment';
import { queryPaginated } from '@app/core/services/helpers/queryPaginated';
import { ICustomerFeatureToggle } from '@app/dashboard/customer/core/models/CustomerFeatureToggle';

export interface ConnectPayload {
  customer: {
    id: string;
  };
  user: {
    id: string;
  };
  roles: string[];
}

@Injectable({ providedIn: 'root' })
export class ContactService {
  private readonly _apiUrl = environment.api;

  constructor(private http: HttpClient) {}

  getContactRoles(customerId: string): Observable<IContactRoles> {
    return this.http.get<IContactRoles>(`${this._apiUrl}/customers/${customerId}/permissions`).pipe(
      map((contact) => ({
        id: contact.id,
        roles: contact.roles,
        customer: contact.customer,
        lastLogin: contact.lastLogin,
        userType: contact.userType,
      }))
    );
  }

  getContact(contactId: string): Observable<IContactRoles> {
    return this.http.get<IContactRoles>(`${this._apiUrl}/contacts/${contactId}`);
  }

  putContact(contactId: string, body: object): Observable<any> {
    return this.http.put(`${this._apiUrl}/contacts/${contactId}`, body);
  }

  getCustomerContacts(customerId: string, params?: any): Observable<{ data: IContactRoles[]; meta: IMeta }> {
    return queryPaginated<{ data: IContactRoles[]; meta: IMeta }>(
      this.http,
      `${this._apiUrl}/customers/${customerId}/contacts`,
      params
    );
  }

  deleteCustomerContacts(contactId: string): Observable<any> {
    return this.http.delete<any>(`${this._apiUrl}/contacts/${contactId}`);
  }

  createContact(body: ConnectPayload): Observable<IContactRoles> {
    return this.http.post<IContactRoles>(`${this._apiUrl}/contacts`, body);
  }

  getInvitePreviewsFeature(): Observable<ICustomerFeatureToggle> {
    return this.http.get<ICustomerFeatureToggle>(`${this._apiUrl}/customers/{customerId}/features/preview-invites`);
  }

  enableInvitePreviewsFeature(id: string): Observable<void> {
    return this.http.post<void>(`${this._apiUrl}/customers/{customerId}/features/${id}`, null);
  }

  disableInvitePreviewsFeature(id: string): Observable<void> {
    return this.http.delete<void>(`${this._apiUrl}/customers/{customerId}/features/${id}`);
  }
}
