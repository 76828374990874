import { Directive, Input, OnInit, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { take, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({ selector: '[bindQueryParam]' })
export class BindQueryParamsDirective implements OnInit {
  @Input()
  emit = true;
  @Input('bindQueryParam')
  paramKey: string | string[];
  @Input()
  bindQueryParamMapper: (item, ...args: any[]) => any = item => item;

  constructor(@Optional() private ngControl: NgControl | null, private activatedRoute: ActivatedRoute) {
    if (this.ngControl === null) {
      console.error('[bindQueryParam]: NgControl not available but is required');
    }
  }

  ngOnInit() {
    this.activatedRoute.queryParams
      .pipe(
        untilDestroyed(this),
        tap(qp => {
          const queryParams = new URLSearchParams(qp);
          if (this.ngControl && this.paramKey) {
            let value;

            if (typeof this.paramKey === 'string') {
              if (queryParams.has(this.paramKey)) {
                value = queryParams.get(this.paramKey);
              }
            } else {
              value = this.paramKey.map(key => (queryParams.has(key) ? queryParams.get(key) : null));
            }
            const mapperValue = this.bindQueryParamMapper(value);
            // todo: check on length for array value
            if (mapperValue && mapperValue !== this.ngControl?.value) {
              this.ngControl.control.setValue(mapperValue, { emitEvent: this.emit });
              this.emit = false;
            }
          }
        }),
        take(1)
      )
      .subscribe();
  }
}
