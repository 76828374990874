import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-modal',
  template: `
    <div class="modal-header" *ngIf="showHeader" [style.background-color]="headerColor">
      <ng-content select="[modal-title-image]" class="modal-title pull-left"></ng-content>
      <h5 *ngIf="title; else contentTitle" class="modal-title pull-left">{{ title }}</h5>
      <ng-template #contentTitle>
        <h5 class="modal-title pull-left">
          <ng-content select="[modal-title]"></ng-content>
        </h5>
      </ng-template>
      <button *ngIf="enableClose" type="button" class="btn-close pull-right" aria-label="Close" (click)="close()">
        <span aria-hidden="true"><i class="la la-times"></i></span>
      </button>
    </div>
    <div class="modal-body">
      <ng-content select="[modal-content], [modal-body]"></ng-content>
    </div>
    <div class="modal-footer" *ngIf="showFooter">
      <div class="modal-actions">
        <ng-content select="[modal-actions]"></ng-content>
      </div>
    </div>
  `,
  styles: [
    `
      app-modal {
        display: flex;
        flex: 1;
        flex-direction: column;
      }

      .modal-header .la-times::before {
        content: none;
      }

      .modal-footer {
        padding: 20px;
        border-top: 1px solid #e4e7ec;
      }

      .modal-header {
        border-bottom: 1px solid #e4e7ec;
      }

      .modal-actions {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      .modal-body {
        display: flex;
        flex-direction: column;
        flex: 1;
        height: 100%;
        padding: 20px;
      }

      [modal-title] {
        margin-bottom: 0;
        font-size: 18px;
      }

      .modal-button-group > * {
        margin-left: 10px;
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ModalComponent {
  @Input()
  headerColor = '#fff';

  @Input()
  enableClose = true;

  @Input()
  title: string;

  @Input()
  cancelButton: string;

  @Input()
  showHeader = true;

  @Input()
  showFooter = true;

  @Output()
  onClose: EventEmitter<void> = new EventEmitter<void>();

  close() {
    this.onClose.emit();
  }
}
