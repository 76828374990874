import { createSelector, MemoizedSelector, MemoizedSelectorWithProps } from '@ngrx/store';

import { ProfileStoreSelectors } from './profile-store';
import * as fromScope from './scope-store/reducers/scope.reducer';

export const selectLoaded = () =>
  createSelector(
    fromScope.scopeIsLoaded,
    fromScope.productsLoaded,
    ProfileStoreSelectors.userStoreLoaded,
    (scopeLoaded: boolean, userLoaded: boolean) => scopeLoaded && userLoaded
  );
