import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CallbackComponent } from '@app/core/auth/auth-callback/callback.component';
import { AuthRedirectComponent } from '@app/core/auth/auth-redirect/auth-redirect.component';
import { CommonRoutes } from '@app/core/constants/routes/common-routes.constant';
import { AutoSelectScopeGuard } from '@app/core/guards/auto-select-scope.guard';
import { CustomerGuard } from '@app/core/guards/customer.guard';
import { AppCustomPreloader } from '@app/core/strategies/pre-load.strategy';
import { AuthGuard } from 'ins-auth';

const routes: Routes = [
  {
    path: CommonRoutes.SCOPE,
    loadChildren: () => import('./scope/scope.module').then(m => m.ScopeModule),
    canActivate: [AuthGuard, AutoSelectScopeGuard],
    data: { showHeader: true, showSidebar: false, showLoader: false }
  },
  {
    path: CommonRoutes.AUTH,
    children: [
      { path: CommonRoutes.SIGN_IN, component: AuthRedirectComponent },
      { path: `${CommonRoutes.SIGN_IN}/${CommonRoutes.CALLBACK}`, component: CallbackComponent }
    ],
    data: { showHeader: false, showSidebar: false, showLoader: false }
  },
  {
    path: CommonRoutes.UNSUBSCRIBING,
    loadChildren: () =>
      import('./modules/unsubscribing-info/unsubscribing-info.module').then(m => m.UnsubscribingInfoModule),
    data: { showHeader: false, showSidebar: false, showLoader: false, fullscreen: true }
  },
  {
    path: ':scopeId',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard, CustomerGuard]
  },
  {
    path: '**',
    redirectTo: CommonRoutes.SCOPE
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      useHash: true,
      onSameUrlNavigation: 'reload',
      preloadingStrategy: AppCustomPreloader
    })
  ],
  providers: [AppCustomPreloader],
  exports: [RouterModule]
})
export class AppRoutingModule {}
