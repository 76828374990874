export enum NOTIFICATIONS_IMPORTANCE_ENUM {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
}

export enum THRESHOLD_ENUM {
  FIVE = '5',
  TEN = '10',
  TWENTY = '20',
  THIRTY = '30',
  FORTY = '40',
  FIFTY = '50',
}

export enum DURATION_ENUM {
  TWENTY_FOUR = '24',
  TWENTY_EIGHT = '28',
  SEVENTY_TWO = '72',
}

export enum RESPONSES_COUNT_ENUM {
  TWO_RESPONSES = '2',
  FOUR_RESPONSES = '4',
  SIX_RESPONSES = '6',
  EIGHT_RESPONSES = '8',
  TEN_RESPONSES = '10',
  MORE_THAN_TEN_RESPONSES = 'more-than-10',
}
