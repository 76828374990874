import { ScoreColorSchemeEnum } from '@app/shared/enums/score-color-scheme.enum';

export class CommonConstants {
  static readonly START_DATE_STRING = '2010-01-01';
  static readonly SCORE_COLOR_SCHEME = {
    [ScoreColorSchemeEnum.POSITIVE]: 'rgb(88, 185, 157)',
    [ScoreColorSchemeEnum.NEUTRAL]: 'rgb(242, 181, 24)',
    [ScoreColorSchemeEnum.NEGATIVE]: 'rgb(232, 128, 92)',
  };

  static readonly PRODUCTION_METABASE_URL = 'https://dash.insocial.nl';
  static readonly DEVELOPMENT_METABASE_URL = 'https://dev-dashboard.insocial.nl';
}
