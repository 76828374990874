import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '@app/shared/utils/utils';

@Pipe({
  name: 'mustacheReplace'
})
export class MustacheReplacePipe implements PipeTransform {
  readonly MOUSTACHE_REGEX = /{{\s*[\w\:.]+\s*}}/g;

  transform(value: string, keyvalue: { [key: string]: string } = {}): string {
    if (keyvalue && !Utils.isEmpty(keyvalue)) {
      const foundTemplateKeys = value?.match(this.MOUSTACHE_REGEX);

      if (foundTemplateKeys && foundTemplateKeys?.length) {
        foundTemplateKeys.forEach(templateKey => {
          const strippedValue = templateKey.match(/[\w\:.]+/)[0];
          value = keyvalue[strippedValue] ? value.replace(templateKey, keyvalue[strippedValue]) : value;
        });
      }
    }

    return value;
  }
}
