import { createReducer, on } from '@ngrx/store';
import * as fromActions from '../actions';
import { initialState, State } from '@app/core/root-store/profile-store/state/profile-state';

export const reducer = createReducer(
  initialState,
  on(fromActions.loadUserInfo, (state) => ({
    ...state,
    loading: true,
  })),
  on(fromActions.loadUserInfoSuccess, (state, { user }) => ({
    ...state,
    loading: false,
    loaded: true,
    user,
  })),
  on(fromActions.updateSelfSuccess, (state, { user }) => ({
    ...state,
    user: {
      ...state.user,
      ...user,
    },
  })),
  on(fromActions.changeUserLanguageSuccess, (state, { locale }) => ({
    ...state,
    user: {
      ...state.user,
      locale,
    },
  })),
  on(fromActions.enableTwoFactorAuthSuccess, (state) => ({
    ...state,
    user: {
      ...state.user,
      twoFactorAuth: true,
    },
  })),
  on(fromActions.disableTwoFactorAuthSuccess, (state) => ({
    ...state,
    user: {
      ...state.user,
      twoFactorAuth: false,
    },
  })),
  on(fromActions.checkTwoFactorAuthSuccess, (state, { enabled }) => ({
    ...state,
    user: {
      ...state.user,
      twoFactorAuth: enabled,
    },
  })),
  on(fromActions.loadUserInfoFailed, () => initialState)
);

// todo: rename
export const getCustomer = (state: State) => state.user;
export const getLoading = (state: State) => state.loading;
export const getLoaded = (state: State) => state.loaded;
