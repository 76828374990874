<app-hosted-dropdown
  [open]="panelOpen"
  [content]="dropdownContent"
  (onClose)="close()"
  (positionChange)="updatePosition($event)"
>
  <div
    class="select"
    [class.dropdown-position-top]="dropdownPosition === 'top'"
    [class.is-active]="panelOpen"
    #trigger
  >
    <div class="select__button-group" (click)="open()">
      <div class="select-trigger">
        <ng-container *ngIf="empty; else hasValue">
          <div class="select__value select__value--placeholder" *ngIf="!typeCreate || createDisabled; else typeCreateValue">
            {{ placeholder || '' }}
          </div>
          <ng-template #typeCreateValue>
            <input
              class="form-control select__value select__value--create"
              (keyup)="onSearchInputChange($event)"
              [value]="searchValue"
              type="text"
              role="searchbox"
              [placeholder]="placeholder || ''"
            />
          </ng-template>
        </ng-container>
        <ng-template #hasValue>
          <div class="select__value" insElementObserver #observer="insElementObserver">
            <ng-container *ngIf="!viewTemplate; else customViewTemplate">
              <ng-container *insAlias="amountOverflowActivated && controlValue.length > 1 as overflowCriteriaMet">
                <span *ngIf="overflowCriteriaMet">
                  {{ controlValue.length }} {{ 'components.select.options-selected-overflow' | transloco }}
                </span>
                <span
                  [style.max-width.px]="(observer.boundingClientRect$ | async).width - 20"
                  [class.is-overflowed]="overflowCriteriaMet"
                  #selectValueWrapper
                >
                  {{ optionViewValue }}
                </span>
              </ng-container>
            </ng-container>
            <ng-template #customViewTemplate>
              <ng-container *ngTemplateOutlet="viewTemplate; context: { $implicit: controlValue }"></ng-container>
            </ng-template>
          </div>
        </ng-template>
      </div>
      <div *ngIf="(!empty && clearButton) || loading" (click)="!loading && clear($event)" class="clear-input">
        <i *ngIf="!loading" class="la la-times"></i>
        <loader *ngIf="loading" size="s"></loader>
      </div>
    </div>
    <button tabindex="-1" type="button" class="btn select__toggle" *ngIf="!typeCreate; else typeCreateBtn" (click)="open()">
      <i class="la la-angle-down trigger-arrow" [class.la-rotate-180]="panelOpen"></i>
    </button>
    <ng-template #typeCreateBtn>
      <button tabindex="-1" type="button" class="btn select__toggle select__toggle--create" [disabled]="createDisabled" (click)="createOption()">
        <i class="la la-plus trigger-arrow"></i>
      </button>
    </ng-template>
  </div>
</app-hosted-dropdown>

<ng-template #dropdownContent>
  <div
    class="ins-dropdown select__dropdown-menu"
    [class.dropdown-position-top]="dropdownPosition === 'top'"
    [class]="'size-' + size"
    [style.width.px]="+trigger.getBoundingClientRect().width"
  >
    <ng-container *ngIf="!loading">
      <div *ngIf="typeSelect" class="data-list-search">
        <i class="la la-search search__icon"></i>
        <input
          (keyup)="onSearchInputChange($event)"
          [value]="searchValue"
          class="form-control data-list-search-input"
          type="text"
          role="searchbox"
          [placeholder]="'common.filter.label.search' | transloco"
        />
        <loader *ngIf="searchLoading" size="s"></loader>
      </div>
      <ng-container *ngIf="empty$ | async">
        <span class="select-no-result">
          <i class="la la-question-circle"></i>
          {{ emptyContent$ | async }}
        </span>
      </ng-container>
      <app-option-container [listItems]="itemList$ | async" [activeValue]="currentValue"></app-option-container>
    </ng-container>
  </div>
</ng-template>
