export enum MarketingTypeEnum {
  USER_MARKETING_TYPE_DEVELOPER = 'marketing_type_developer',
  USER_MARKETING_TYPE_REPORTER = 'marketing_type_reporter',
  USER_MARKETING_TYPE_ADMINISTRATOR = 'marketing_type_administrator',
  USER_MARKETING_TYPE_MODERATOR = 'marketing_type_moderator',
}

export const MarketingTypeEnumLabels = {
  [MarketingTypeEnum.USER_MARKETING_TYPE_DEVELOPER]: 'Developer',
  [MarketingTypeEnum.USER_MARKETING_TYPE_REPORTER]: 'Reporter',
  [MarketingTypeEnum.USER_MARKETING_TYPE_ADMINISTRATOR]: 'Administrator',
  [MarketingTypeEnum.USER_MARKETING_TYPE_MODERATOR]: 'Moderator',
};
