import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { queryPaginated } from '@app/core/services/helpers/queryPaginated';
import {
  ResponseReplyCreateEvent,
  ResponseReplyUpdateEvent,
} from '@app/dashboard/response-detail/core/interfaces/response-reply-events';
import { IMeta } from '@app/shared/models/common/metadata.model';
import { ILogs } from '@app/shared/models/common/logs.model';
import {
  IResponse,
  IResponseListCounters,
  ResponseQuestionAnswer,
  ResponseReply,
} from '@app/shared/models/responses/responses.model';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, retry, take } from 'rxjs/operators';
import { PublicTypesEnum } from '@app/dashboard/survey-builder/core/constants/public-types';
import { ResponseLabelInterface } from '@app/dashboard/response-detail/core/interfaces/response-label.interface';
import { ApiService } from '@app/core/services/_api.service';
import { ResponsesExcelRequestPayload } from '@app/dashboard/response-detail/core/interfaces/responses-excel-payload.interface';

export interface IResponseCollection {
  data: IResponse[];
  meta: IMeta;
}

@Injectable({
  providedIn: 'root',
})
export class ResponseService {
  private readonly apiUrl = environment.api;
  private readonly path = 'responses';
  private readonly apiUrlResponses = `${environment.api}/${this.path}`;

  private _showTranslations$ = new BehaviorSubject<boolean>(true);

  constructor(private http: HttpClient, private apiService: ApiService) {}

  getResponses(customerId: string, params?: object): Observable<IResponseCollection> {
    const url = `${this.apiUrl}/customers/${customerId}/${this.path}`;
    return queryPaginated<IResponseCollection>(this.http, url, params).pipe(take(1));
  }

  getResponse(responseId, withoutTestimonialQuestions = true): Observable<IResponse> {
    return this.http.get<IResponse>(`${this.apiUrlResponses}/${responseId}`).pipe(
      map((response) => {
        const { responseQuestionAnswers, ...responseData } = response;
        const blockList = [PublicTypesEnum.TESTIMONIAL];
        const questionAnswers = withoutTestimonialQuestions
          ? responseQuestionAnswers.filter((question) => !blockList.includes(question?.question?.publicType))
          : responseQuestionAnswers;

        return {
          ...responseData,
          responseQuestionAnswers: this.mergeDuplicateByRef(questionAnswers),
          testimonialQuestionAnswer:
            responseQuestionAnswers.find(
              (questionAnswer) => questionAnswer?.question?.publicType === PublicTypesEnum.TESTIMONIAL
            ) || null,
        };
      })
    );
  }

  getResponsesCounters(scopeId: string): Observable<IResponseListCounters> {
    const url = [this.apiUrl, 'customers', scopeId, 'responses', 'overview'].join('/');
    return this.http.get<IResponseListCounters>(url);
  }

  getResponseLogs(responseId): Observable<ILogs> {
    return this.http.get<ILogs>(`${this.apiUrlResponses}/${responseId}/logs`);
  }

  updateResponse(id: string, response: any): Observable<IResponse> {
    return this.http.put<IResponse>(`${this.apiUrlResponses}/${id}`, response).pipe(retry(1));
  }

  deleteResponse(responseId: string): Observable<any> {
    return this.http.delete<any>(`${this.apiUrlResponses}/${responseId}`);
  }

  responseReply(id: string, payload: ResponseReplyCreateEvent): Observable<ResponseReply> {
    return this.apiService.post(`/customers/{customerId}/responses/${id}/replies`, payload);
  }

  updateResponseReply(
    responseId: string,
    replyId: string,
    payload: ResponseReplyUpdateEvent
  ): Observable<ResponseReply> {
    return this.apiService.put(`/customers/{customerId}/responses/${responseId}/replies/${replyId}`, payload);
  }

  deleteReply(responseId: string, replyId: string): Observable<any> {
    return this.apiService.delete(`/customers/{customerId}/responses/${responseId}/replies/${replyId}`);
  }

  getCustomerLabels(): Observable<ResponseLabelInterface[]> {
    return this.apiService.get(`/customers/{customerId}/labels`);
  }

  getResponseLabels(responseId: string, customerId: string): Observable<ResponseLabelInterface[]> {
    return this.apiService.get(`/customers/${customerId}/responses/${responseId}/labels`);
  }

  getResponsesExcelReport(customerId: string, payload: Partial<ResponsesExcelRequestPayload> = {}): Observable<string> {
    return this.apiService.post<string>(`/customers/${customerId}/surveys/responses/export/csv`, payload);
  }

  createLabelForResponse(
    responseId: string,
    labelPayload: Partial<ResponseLabelInterface>,
    customerId: string
  ): Observable<ResponseLabelInterface> {
    return this.apiService.post(`/customers/${customerId}/responses/${responseId}/labels`, labelPayload);
  }

  deleteResponseLabelById(responseId: string, labelId: string, customerId: string): Observable<void> {
    return this.apiService.delete(`/customers/${customerId}/responses/${responseId}/labels/${labelId}`);
  }

  showTranslations(show: boolean): void {
    this._showTranslations$.next(show);
  }

  get showTranslations$(): Observable<boolean> {
    return this._showTranslations$.asObservable();
  }

  private mergeDuplicateByRef(questionAnswers: ResponseQuestionAnswer[]): ResponseQuestionAnswer[] {
    const output: ResponseQuestionAnswer[] = [];
    questionAnswers.forEach((questionAnswer) => {
      const index = output.findIndex((outputItem) => outputItem.question.ref === questionAnswer.question.ref);
      if (index !== -1 && questionAnswer.answer && questionAnswer.question.ref) {
        const foundLabel = output[index].answer?.label;
        const answerLabel = questionAnswer.answer?.label as string;
        if (output[index].answer) {
          output[index].answer.label = this.addOrCreateArray(foundLabel, answerLabel);
        }
      } else {
        output.push(questionAnswer);
      }
    });
    return output;
  }

  private addOrCreateArray(foundLabel: string | string[], labelToAdd: string): string[] {
    if (Array.isArray(foundLabel) && typeof labelToAdd === 'string') {
      return [...new Set([...foundLabel, labelToAdd])];
    } else if (typeof foundLabel === 'string') {
      return [...new Set([foundLabel, labelToAdd])];
    }
  }
}
