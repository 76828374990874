import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromActions from '../actions';
import { AuthStoreActions } from 'ins-auth';
import { catchError, exhaustMap, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { UserService } from '@app/core/services/user.service';
import { IUser } from '@app/shared/models/accounts/user.model';
import { of } from 'rxjs';
import { LanguageService } from '@app/core/services/language.service';
import { ProfileFacade } from '@app/core/root-store/profile-store/profile.facade';
import * as Sentry from '@sentry/browser';
import { DialogService } from '@ngneat/dialog';
import { CollectInfoComponent } from '@app/dashboard/accounts/modals/collect-info/collect-info.component';

@Injectable()
export class UserEffects {
  loadUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromActions.loadUserInfo,
        fromActions.updateSelfSuccess,
        AuthStoreActions.getTokenSuccess,
        AuthStoreActions.checkSessionSuccess,
        fromActions.changeUserLanguageSuccess
      ),
      exhaustMap(() =>
        this.userService.getProfile().pipe(
          take(1),
          map((user: IUser) => {
            this.languageService.useLanguage(user.locale);
            Sentry.setUser({ username: user.label });
            return fromActions.checkIfUserIsComplete({ user });
          }),
          catchError((error) => of(fromActions.loadUserInfoFailed(error)))
        )
      )
    )
  );

  changeAppLanguage = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.changeUserLanguage),
      withLatestFrom(this.profileFacade.getIsAdmin$),
      switchMap(([{ locale, user_id }, isAdmin]) => {
        if (isAdmin && locale === 'nl_LOC') {
          return of(fromActions.changeAdminLanguageSuccess({ locale }));
        }
        return this.userService.updateProfile({ locale }, user_id).pipe(
          map((callback) => fromActions.changeUserLanguageSuccess({ locale: callback.locale })),
          catchError((err) => of(fromActions.changeUserLanguageFailed({ err })))
        );
      })
    )
  );

  appLanguageChange = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.changeUserLanguageSuccess, fromActions.changeAdminLanguageSuccess),
        tap(({ locale }) => this.languageService.useLanguage(locale))
      ),
    { dispatch: false }
  );

  updateUser = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateUser, fromActions.updateSelf),
      switchMap(({ user, type }) =>
        this.userService.updateProfile(user, user.id).pipe(
          map((updatedProfile) => {
            if (type === fromActions.updateSelf.type) {
              return fromActions.updateSelfSuccess({ user: updatedProfile });
            }
            return fromActions.updateUserSuccess();
          }),
          catchError(() => of(fromActions.updateUserFailed()))
        )
      )
    )
  );

  enableTwoFactorAuth = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.enableTwoFactorAuth),
      withLatestFrom(this.profileFacade.getUser$),
      switchMap(([, user]) =>
        this.userService.toggleTwoFactorAuthentication(user.id, true).pipe(
          map(() => fromActions.enableTwoFactorAuthSuccess()),
          catchError((err) => of(fromActions.enableTwoFactorAuthFailed({ err })))
        )
      )
    )
  );

  disableTwoFactorAuth = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.disableTwoFactorAuth),
      withLatestFrom(this.profileFacade.getUser$),
      switchMap(([, user]) =>
        this.userService.toggleTwoFactorAuthentication(user.id, false).pipe(
          map(() => fromActions.disableTwoFactorAuthSuccess()),
          catchError((err) => of(fromActions.disableTwoFactorAuthFailed({ err })))
        )
      )
    )
  );

  checkTwoFactorAuth = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.checkTwoFactorAuth),
      withLatestFrom(this.profileFacade.getUser$),
      switchMap(([, user]) =>
        this.userService.checkTwoFactorAuthentication(user.id).pipe(
          map((resp) => fromActions.checkTwoFactorAuthSuccess({ enabled: resp.enabled })),
          catchError((err) => of(fromActions.checkTwoFactorAuthFailed({ err })))
        )
      )
    )
  );

  handleNotUpdatedUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.checkIfUserIsComplete),
      switchMap(({ user }) => {
        if (
          user.receiveMarketingUpdates === null ||
          user.receiveMarketingUpdates === undefined ||
          !user.marketingType
        ) {
          const dialogRef = this.dialog.open(CollectInfoComponent, {
            closeButton: false,
            enableClose: false,
            size: 'sm',
            data: {
              user,
            },
          });
          dialogRef.afterClosed$.pipe(take(1)).subscribe((updatedUser) => {
            if (updatedUser) {
              this.profileFacade.updateSelf(updatedUser);
            }
          });
        }
        return of(fromActions.loadUserInfoSuccess({ user }));
      })
    )
  );

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private languageService: LanguageService,
    private profileFacade: ProfileFacade,
    private dialog: DialogService
  ) {}
}
