import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/services/_api.service';
import { forkJoin, Observable, switchMap } from 'rxjs';
import { Product } from '@app/modules/product-management/core/models/product.model';
import { CustomerProduct, UserProduct } from '@app/modules/product-management/core/models/customer-product.model';
import { map } from 'rxjs/operators';
import { ProfileFacade } from '@app/core/root-store/profile-store/profile.facade';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  constructor(private apiService: ApiService, private profileFacade: ProfileFacade) {}

  getProducts(): Observable<Product[]> {
    return this.apiService.get<Product[]>('/products').pipe(
      map((products) =>
        products.map((product) => {
          if (product.ref === 'customer' || product.name.toLowerCase() === 'management') {
            return {
              ...product,
              isActive: true,
            };
          }

          return product;
        })
      )
    );
  }

  getCustomerProducts(customerId: string = '{customerId}'): Observable<CustomerProduct[]> {
    return this.apiService.get<CustomerProduct[]>(`/customers/${customerId}/products`);
  }

  getUserProducts(customerId: string = '{customerId}', userId: string): Observable<UserProduct[]> {
    return this.apiService.get<UserProduct[]>(`/customers/${customerId}/users/${userId}/products`);
  }

  postProduct(payload: any): Observable<Product[]> {
    return this.apiService.post<Product[]>('/products', payload);
  }

  putProduct(payload: any): Observable<Product[]> {
    return this.apiService.put<Product[]>(`/products/${payload.id}`, payload);
  }

  deleteProduct(id: string): Observable<any> {
    return this.apiService.delete(`/products/${id}`);
  }

  deleteCustomerProduct(customerId: string, id: string): Observable<any> {
    return this.apiService.delete(`/customers/${customerId}/products/${id}`);
  }

  putCustomerProduct(customerId: string, id: string, payload: any): Observable<any> {
    return this.apiService.put(`/customers/${customerId}/products/${id}`, payload);
  }

  // todo: add typing for payload
  activateProduct(customerId: string, productId: string, payload: any): Observable<Product> {
    return this.apiService.post(`/customers/${customerId}/products/${productId}`, payload);
  }

  getProductsOfCustomer(customerId: string): Observable<Product[]> {
    return this.profileFacade.getIsAdmin$.pipe(
      switchMap((isAdmin) => {
        if (isAdmin) {
          return this.getProductsWithCustomer(customerId);
        } else {
          return this.getCustomerProducts(customerId).pipe(
            map((customerProducts: CustomerProduct[]) =>
              customerProducts.map((customerProduct) => {
                const product = customerProduct.product;
                return {
                  ...product,
                  isActive: customerProduct.isActive,
                  isDemo: customerProduct.isDemo,
                  price: customerProduct.product.price,
                  user: {
                    startedAt: customerProduct.startedAt,
                    expiresAt: customerProduct.expiresAt,
                  },
                };
              })
            )
          );
        }
      })
    );
  }

  getProductsWithCustomer(customerId?: string): Observable<Product[]> {
    return forkJoin([this.getProducts(), this.getCustomerProducts(customerId)]).pipe(
      map(([products, activatedCustomerProducts]: [Product[], CustomerProduct[]]) =>
        products.map((product) => {
          const correspondingProduct = activatedCustomerProducts.find(
            (customerProduct) => customerProduct.product.id === product.id
          );

          if (correspondingProduct) {
            return {
              ...product,
              isActive: correspondingProduct.isActive,
              isDemo: correspondingProduct.isDemo,
              price: correspondingProduct.product.price,
              user: {
                startedAt: correspondingProduct.startedAt,
                expiresAt: correspondingProduct.expiresAt,
              },
            };
          }
          return product;
        })
      )
    );
  }
}
