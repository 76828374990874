import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { reducers } from '@app/core/root-store/profile-store/reducers';
import { EffectsModule } from '@ngrx/effects';
import { UserEffects } from '@app/core/root-store/profile-store/effects/user.effects';
import { ProfileFacade } from '@app/core/root-store/profile-store/profile.facade';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('user', reducers),
    EffectsModule.forFeature([UserEffects, ProfileFacade])
  ]
})
export class ProfileStoreModule { }
