import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SELECT_SCROLL_STRATEGY_PROVIDER } from '@app/core/strategies/block-scroll';
import { CheckboxModule } from '@app/shared/components/checkbox/checkbox.module';
import { SelectComponent } from '@app/shared/components/select/select.component';
import { ViewSelectPipe } from './view-select.pipe';
import { LoaderModule } from '@app/shared/components/loader/loader.module';
import { MultiSelectGroupComponent } from './multi-select/multi-select-group.component';
import { AliasModule } from '@app/shared/directives/alias/alias.module';
import { OptionComponent } from '@app/shared/components/select/option/option.component';
import { OptgroupDirective } from '@app/shared/components/select/optgroup.directive';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { HostedDropdownModule } from '@app/shared/modules/hosted-dropdown/hosted-dropdown.module';
import { TranslocoModule } from '@ngneat/transloco';
import { OptionGroupItemComponent } from './option-group-item/option-group-item.component';
import { OptionItemComponent } from './option-item/option-item.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { OptionContainerComponent } from './option-container/option-container.component';
import { ElementObserverModule } from '@app/shared/directives/element-observer/element-observer.module';
import { ButtonModule } from '@app/shared/components/button/button.module';

@NgModule({
  imports: [
    CommonModule,
    NgScrollbarModule,
    OverlayModule,
    ReactiveFormsModule,
    CheckboxModule,
    LoaderModule,
    AliasModule,
    HostedDropdownModule,
    TranslocoModule,
    ScrollingModule,
    ElementObserverModule,
    ButtonModule
  ],
  declarations: [
    SelectComponent,
    ViewSelectPipe,
    OptionComponent,
    MultiSelectGroupComponent,
    OptgroupDirective,
    OptionGroupItemComponent,
    OptionItemComponent,
    OptionContainerComponent
  ],
  exports: [SelectComponent, ViewSelectPipe, OptionComponent, MultiSelectGroupComponent, OptgroupDirective],
  providers: [SELECT_SCROLL_STRATEGY_PROVIDER]
})
export class SelectModule {}
