<app-sidebar *ngIf="showSidebar"></app-sidebar>
<app-header *ngIf="showHeader"></app-header>
<div class="app" *ngIf="(appLoaded$ | async) || hideLoader; else loading" [class.fullscreen]="fullscreen">
  <div class="app__content">
    <router-outlet></router-outlet>
  </div>
</div>

<ng-template #loading>
  <app-loader rootLoader></app-loader>
</ng-template>
