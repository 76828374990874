import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { InsSizeM, InsSizeS } from '@app/shared/types/size';

@Component({
  selector: 'loader',
  template: `
    <div class="small-loader">
      <div class="spinner-border" [style.color]="color" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <span class="text-center" *ngIf="label">{{ label }}</span>
    </div>
  `,
  styleUrls: ['loader.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent {
  @Input()
  color = '#54B5EB';
  @Input()
  label: string;
  @Input()
  @HostBinding('attr.loader-size')
  size: InsSizeS | InsSizeM = 'm';
}
