import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { environment } from '@env/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ProfileStoreModule } from '@app/core/root-store/profile-store/profile-store.module';
import { ScopeStoreModule } from '@app/core/root-store/scope-store/scope-store.module';
import { routerReducer, RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';

@NgModule({
  declarations: [],
  imports: [
    ProfileStoreModule,
    ScopeStoreModule,
    StoreModule.forRoot({
      router: routerReducer
    }),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot({
      routerState: RouterState.Minimal
    }),
    !environment.production ? StoreDevtoolsModule.instrument({ name: 'Insocial Customer App', maxAge: 30 }) : []
  ],
  providers: []
})
export class RootStoreModule {}
