import { ChangeDetectorRef, Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Roles } from '@app/shared/enums/roles.enum';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthUtils } from '@app/shared/utils/auth.utils';
import { ScopeFacade } from '@app/core/root-store/scope-store/scope.facade';
import { ProfileFacade } from '@app/core/root-store/profile-store/profile.facade';

@Directive({
  selector: '[appHideByRole]',
})
export class HideByRoleDirective implements OnInit {
  @Input('appHideByRole')
  appHideByRole: string[] = [];

  isVisible = false;

  stop$ = new Subject();
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private scopeFacade: ScopeFacade,
    private readonly profileFacade: ProfileFacade,
    private readonly cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.scopeFacade.getContactRoles$.pipe(takeUntil(this.stop$)).subscribe((state) => {
      const { roles } = state;

      if (Array.isArray(this.appHideByRole) && roles.some((role) => this.appHideByRole?.includes(role))) {
        this.isVisible = false;

        this.viewContainer.clear();
      } else {
        this.renderTemplate();
      }

      this.cdRef.markForCheck();
    });
  }

  private renderTemplate(): void {
    if (!this.isVisible) {
      this.isVisible = true;
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

  ngOnDestroy(): void {
    this.stop$.next(null);
    this.stop$.complete();
  }
}
