import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '@app/shared/utils/utils';
import { filesIcons } from '../constants/file-icons';

@Pipe({
  name: 'fileIcon'
})
export class FileIconPipe implements PipeTransform {
  transform(filename: string): string {
    const fileExtension = Utils.getFileExtension(filename);
    return filesIcons[fileExtension] ?? 'file';
  }
}
