import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnChanges,
  Optional,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { SelectItem } from '@app/shared/components/select/select-item.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EMPTY, merge } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { Utils } from '@app/shared/utils/utils';
import { DATA_LIST_HOST } from '@app/core/tokens/data-list-host.token';
import { DataListHost } from '@app/core/interfaces/data-list-host.interface';
import { NgControl } from '@angular/forms';

@Component({
  selector: 'app-option-item',
  templateUrl: './option-item.component.html',
  styleUrls: ['../styles/_option.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.item-active]': 'activated && !disabled'
  }
})
@UntilDestroy()
export class OptionItemComponent<T = unknown> implements AfterContentInit, OnChanges {
  activated = false;

  selected$ = merge(this.ngControl?.valueChanges ?? EMPTY, this.host.controlValueChange ?? EMPTY).pipe(
    startWith(true),
    map(() => this.selected),
    distinctUntilChanged()
  );

  @Input()
  item: SelectItem<T>;
  @Input()
  activeItem: any;

  private _selected = false;
  @HostBinding('class.selected')
  get hostSelected() {
    return this._selected;
  }

  @HostBinding('class.is-multi')
  get multi(): boolean {
    return this.host.multi;
  }

  @HostBinding('class.is-disabled')
  get disabled(): boolean {
    return this.item.disabled;
  }

  @HostListener('click')
  onClick(): void {
    if (!this.disabled && this.host) {
      this.host.handleOption(this.item.value);
    }
  }

  constructor(
    @Inject(DATA_LIST_HOST) private host: DataListHost<T>,
    @Optional() private ngControl: NgControl,
    private elm: ElementRef,
    private cdRef: ChangeDetectorRef
  ) {}

  get selected() {
    return Utils.isPresent(this.getHostValue()) && Utils.isPresent(this.item.value) && this.findSelected();
  }

  private findSelected(): boolean {
    let present: boolean;
    const controlValue = this.getHostValue();
    if (this.host?.multi) {
      present = !!controlValue.find(value => this.host.compareWith(value, this.item.value));
    } else {
      present = this.host.compareWith(controlValue, this.item.value);
    }
    return present;
  }

  focus(): void {
    this.elm.nativeElement.focus();
  }

  getHostValue(): any {
    return this.ngControl?.value || this.host.controlValue;
  }

  ngAfterContentInit(): void {
    this.selected$.pipe(untilDestroyed(this)).subscribe(selected => {
      if (this._selected !== selected) {
        this._selected = selected;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { item, activeItem } = changes;

    if (item || activeItem) {
      this.activated = this.host.compareWith(this.activeItem, this.item.value);
    }
  }

  clickOptionAction(event: any): void {
    this.item.action.next();
    event.preventDefault();
    event.stopPropagation();
    this.host?.close();
  }
}
