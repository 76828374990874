import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'app-loader',
  template: `
    <div class="overlay up" [class.overlay--bg]="rootLoader">
      <div class="d-flex justify-content-center">
        <div class="loader">
          <div class="loader__spinner">
            <img class="loader__image" src="assets/images/radial_gr_spinnyboi.svg" />
            <img class="loader__rhino" src="assets/images/rhinox.svg" />
          </div>
          <p class="loader__text">The Insocial rhino is working to collect your data!</p>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./app-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppLoaderComponent {
  private _rootLoader = false;
  @Input()
  set rootLoader(value: boolean) {
    const newValue = coerceBooleanProperty(value);

    if (value !== newValue) {
      this._rootLoader = true;
    }
  }

  get rootLoader() {
    return this._rootLoader;
  }
}
