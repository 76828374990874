export interface UploadEvent {
  progress: number;
  result: object;
  status: UploadStatus;
}

export enum UploadStatus {
  IN_PROGRESS = 'inProgress',
  ERROR = 'error',
  OK = 'ok',
}
