<app-hosted-dropdown
  [open]="isOpen"
  [content]="content"
  [positionOverride]="positions"
  ignoreTriggerWidth=""
  (onClose)="close()"
>
  <div class="cog__selector">
    <button
      (click)="toggleOpen()"
      [icon]="isMulti ? 'ellipsis-h' : 'cog'"
      cdkOverlayOrigin
      ins-button-icon
      [shape]="!dots ? 'rounded' : null"
      size="s"
      [styling]="!dots ? 'secondary' : 'link'"
    ></button>
  </div>
</app-hosted-dropdown>
<ng-template #content>
  <span class="menu-arrow menu-arrow--backdrop"></span>
  <span class="menu-arrow"></span>
  <ul class="dropdown-menu" role="menu" aria-labelledby="button-basic">
    <ng-container *ngIf="hasSelection; else noSelection">
      <ng-content> </ng-content>
    </ng-container>
    <ng-template #noSelection>
      <ng-content select="[noSelection]"> </ng-content>
    </ng-template>
  </ul>
</ng-template>
