<div class="toastr" (click)="toastAction && action($event)" [style.display]="state.value === 'inactive' ? 'none' : ''">
  <div class="toastr-icon" [ngClass]="type">
    <span class="toastr-icon-circle"> <i class="la la-{{ icon }}"></i> </span>
  </div>
  <div class="toastr-content">
    <div class="toastr-title" *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
      {{ title }}
    </div>
    <div
      *ngIf="message && options.enableHtml"
      role="alert"
      aria-live="polite"
      [class]="options.messageClass"
      [innerHTML]="message"
      class="toastr-message"
    ></div>
    <div
      *ngIf="message && !options.enableHtml"
      role="alert"
      class="toastr-message"
      aria-live="polite"
      [class]="options.messageClass"
      [attr.aria-label]="message"
    >
      {{ message }}
    </div>
  </div>
</div>
