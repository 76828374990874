import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  template: ``
})

export class ModalBaseComponent {
  private _deleting = false;
  constructor(
    public cdRef: ChangeDetectorRef
  ) {
  }

  @Input()
  title: string;

  @Input()
  get deleting() { return this._deleting; }
  set deleting(value: boolean) {
    if (value !== this._deleting) {
      this._deleting = value;
      this.cdRef.markForCheck();
    }
  }

  @Output()
  close: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  confirm: EventEmitter<any> = new EventEmitter<any>();
}
