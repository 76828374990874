import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fallback'
})
export class FallbackPipe implements PipeTransform {
  transform<T>(value: T, fallback: any): any {
    const type = typeof value;

    if (Array.isArray(value)) {
      return value.length ? value : fallback;
    }

    if (type === 'string') {
      return value || fallback;
    }

    return value ?? fallback;
  }
}
