import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { catchError, switchMap, take } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { AuthFacade } from 'ins-auth';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authFacade: AuthFacade) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authFacade.getJwtToken$.pipe(
      take(1),
      switchMap(token => {
        const tokenRequest = !!token
          ? request.clone({
              setHeaders: { Authorization: 'Bearer ' + token }
            })
          : request;
        return next.handle(tokenRequest);
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.authFacade.logOut();
        }

        return throwError(error);
      })
    );
  }
}
