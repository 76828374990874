import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-action-select',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
      <div class="action-select" dropdown>
          <div class="action-select__button-group" dropdownToggle>
              <div class="action-select__value">
                  <ng-container *ngIf="label; else useContent">
                      {{label}}
                  </ng-container>
                  <ng-template #useContent>
                      <ng-content select="[label]"></ng-content>
                  </ng-template>
              </div>
              <button type="button" class="btn action-select__toggle">
                  <i class="la la-angle-down"></i>
              </button>
          </div>
          <ul class="dropdown-menu action-select__dropdown-menu">
              <ng-content></ng-content>
          </ul>
      </div>
  `,
  styleUrls: ['action-select.component.scss']
})
export class ActionSelectComponent {
  @Input()
  label: string;
}
