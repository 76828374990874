import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { disableDebugTools } from '@angular/platform-browser';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-action-select-item',
  template: `
      <li class="menuitem-icon"
          (click)="clickEmit()"
          [ngClass]="{'disabled': disabled}"
          role="menuitem">
          <a class="dropdown-item">
              <i *ngIf="iconClass" class="la" [ngClass]="iconClass"></i>
              <ng-content></ng-content>
          </a>
      </li>
  `,
  styles: [`
      :host {
          border-bottom: 1px solid #eeeeee;
      }
  `]
})

export class ActionSelectItemComponent {
  @Input()
  icon: string;

  // Todo: add click eventemitter to catch the disabled state
  @Input()
  disabled = false;

  @Output()
  onClick: EventEmitter<any> = new EventEmitter<any>();

  get iconClass(): string {
    return this.icon;
  }

  clickEmit() {
    if (this.disabled) { return; }
    this.onClick.emit();
  }
}
