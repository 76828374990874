import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { UserHeaderComponent } from './components/user-header/user-header.component';
import { HeaderComponent } from './header.component';

@NgModule({
  imports: [SharedModule, BsDropdownModule],
  exports: [HeaderComponent],
  declarations: [HeaderComponent, UserHeaderComponent],
  providers: []
})
export class HeaderModule {}
