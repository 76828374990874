import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { IUser } from '@app/shared/models/accounts/user.model';
import { ApiService } from '@app/core/services/_api.service';
import { map, take } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CommonConstants } from '@app/core/constants/common';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  apiUrl = environment.api;
  private readonly impersonateKey = 'imp';

  constructor(private apiService: ApiService, private http: HttpClient) {}

  getProfile(): Observable<IUser> {
    return this.apiService.get<IUser>('/users/profile');
  }

  updateProfile(body: any, userId: string): Observable<IUser> {
    return this.apiService.put<IUser>(`/users/${userId}`, body);
  }

  toggleTwoFactorAuthentication(userId: string, enable: boolean) {
    return this.apiService.get(`/users/${userId}/twofactor`, { isenabled: enable ? 1 : 0 });
  }

  checkTwoFactorAuthentication(userId: string) {
    return this.apiService.get<{ enabled: boolean }>(`/users/${userId}/twofactor`);
  }

  getGoogleQrCode() {
    return this.apiService.get<{ qr_code: string }>('/google/qr');
  }

  getUserList(params?: any): Observable<IUser[]> {
    return this.http
      .get<IUser[]>(`${this.apiUrl}/users`, {
        headers: this.headers,
        params: this.toHttpParams(params),
      })
      .pipe(
        take(1),
        map((res: any) => res.data)
      );
  }

  get headers(): HttpHeaders {
    const headersConfig = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };

    return new HttpHeaders(headersConfig);
  }

  startImpersonation(userId) {
    localStorage.setItem(this.impersonateKey, userId);
    window.location.reload();
  }

  getImpersonation(): string | null {
    return localStorage.getItem(this.impersonateKey);
  }

  stopImpersonation() {
    localStorage.removeItem(this.impersonateKey);
    window.location.reload();
  }

  private exitImpersonationMetabase() {
    const baseUrl = environment.production
      ? CommonConstants.PRODUCTION_METABASE_URL
      : CommonConstants.DEVELOPMENT_METABASE_URL;

    return this.http.delete(`${baseUrl}/session`);
  }

  private toHttpParams(params) {
    if (params) {
      const validKeys = Object.keys(params).filter((key) => !!params[key]);
      const filteredParams = {};
      validKeys.forEach((key) => {
        filteredParams[key] = params[key];
      });
      return new HttpParams({ fromObject: filteredParams });
    }

    return {};
  }
}
