import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ellipsis',
})
export class EllipsisPipe implements PipeTransform {
  transform(value: string, maxLength = 24): string {
    if (value) {
      const newValue = value.trim();
      return newValue.length > maxLength ? newValue.slice(0, maxLength - 1) + '...' : newValue;
    }
  }
}
