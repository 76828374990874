import { Observable } from 'rxjs';
import { ResizeObserver } from '@juggle/resize-observer';

export function resizeObserverStrategy(target: HTMLElement): Observable<boolean> {
  return new Observable(subscriber => {
    const observer = new ResizeObserver(() => subscriber.next(true));
    observer.observe(target);
    return () => observer.disconnect();
  });
}
