import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize',
})
export class CapitalizePipe implements PipeTransform {
  transform(value: string): string {
    if (value && value.length >= 1) {
      return value.charAt(0).toUpperCase() + value.toLowerCase().slice(1);
    } else {
      return value;
    }
  }
}
