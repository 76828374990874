import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { environment } from '@env/environment';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {
    Sentry.init({
      dsn: 'https://96390fa07ac44b7a87f47b4a85baf596@sentry.io/1838485'
    });
  }

  handleError(error) {
    if (environment.production) {
      Sentry.captureException(error.originalError || error);
    }
    return new ErrorHandler();
  }
}
