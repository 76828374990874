import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CommonRoutes } from '@app/core/constants/routes/common-routes.constant';
import { UserStoreActions } from '@app/core/root-store/profile-store';
import { ProfileFacade } from '@app/core/root-store/profile-store/profile.facade';
import { ScopeFacade } from '@app/core/root-store/scope-store/scope.facade';
import { CustomerService } from '@app/core/services/customer/customer.service';
import { ScopeService } from '@app/core/services/scope.service';
import * as fromScopeActions from '../actions/scope.actions';
import { UserRoles } from '@app/shared/enums/roles.enum';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ProductsService } from '@app/modules/product-management/core/services/products.service';
import { UserProduct } from '@app/modules/product-management/core/models/customer-product.model';
import * as Sentry from '@sentry/browser';
import { UpdateService } from '@app/core/services/update.service';

@Injectable()
export class ScopeEffects {
  constructor(
    private customerService: CustomerService,
    private scopeService: ScopeService,
    private actions$: Actions,
    private profileFacade: ProfileFacade,
    private scopeFacade: ScopeFacade,
    private productService: ProductsService,
    private router: Router,
    private updateService: UpdateService
  ) {}

  getScope = createEffect(() =>
    this.actions$.pipe(
      ofType(fromScopeActions.getScope),
      withLatestFrom(this.profileFacade.getUser$, ({ customerId, autoSelected }, user) => ({
        customerId,
        user,
        autoSelected,
      })),
      filter(({ user }) => !!user),
      mergeMap(({ customerId, user, autoSelected }) => {
        if (user.roles.includes(UserRoles.ROLE_ADMIN)) {
          return this.customerService.getCustomer(customerId).pipe(
            map((scope) => {
              this.scopeService.rememberLocalScope(customerId, autoSelected);
              return fromScopeActions.getScopeSuccess({
                scope: {
                  id: scope.id,
                  label: scope.label,
                  hasChildren: scope.hasChildren,
                  isActive: scope.isActive,
                  logoPath: scope.styling ? scope.styling.logoPath : null,
                  color: scope.styling ? scope.styling.primaryBrandColor : null,
                  invitesConfiguration: scope.invitesConfiguration,
                  models: scope.models,
                  usingNewDomain: scope.usingNewDomain,
                  website: scope.website,
                },
                autoSelected,
              });
            }),
            catchError((error) => {
              this.scopeService.clearLocalScope();
              return of(fromScopeActions.getScopeFailed({ error }));
            })
          );
        }
        return this.scopeService.getScope(customerId).pipe(
          map((scope) => {
            this.scopeService.rememberLocalScope(customerId, autoSelected);
            return fromScopeActions.getScopeSuccess({ scope, autoSelected });
          }),
          catchError((error) => {
            this.scopeService.clearLocalScope();
            return of(fromScopeActions.getScopeFailed({ error }));
          })
        );
      })
    )
  );

  scopeSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromScopeActions.getScopeSuccess),
        tap(({ scope }) => {
          Sentry.setUser({ environment: scope.label });
          this.updateService.showFeatureAnnouncement();
        })
      ),
    { dispatch: false }
  );

  getActivatedProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromScopeActions.getScopeSuccess, fromScopeActions.getActivatedProducts),
      withLatestFrom(this.scopeFacade.getScope$, this.profileFacade.getUser$),
      filter(([_, __, user]) => !!user.roles),
      switchMap(([_, scope, user]) =>
        (user.roles.includes(UserRoles.ROLE_ADMIN)
          ? this.productService.getCustomerProducts(scope.id)
          : this.productService.getUserProducts(scope.id, user.id)
        ).pipe(
          map((customerProducts: UserProduct[]) =>
            fromScopeActions.getActivatedProductsSuccess({ products: customerProducts })
          )
        )
      )
    )
  );

  checkScopeSessionDirector$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserStoreActions.loadUserInfoSuccess),
      exhaustMap(() =>
        this.scopeService.localScopeId$.pipe(
          switchMap((scopeInfo) => {
            if (scopeInfo.autoSelected === null) {
              return this.customerService.getCustomers().pipe(map((customers) => ({ ...scopeInfo, customers })));
            }
            return of({ ...scopeInfo, customers: null });
          }),
          map(
            (enrichedScopeInfo: {
              customers: ReadonlyArray<any> | null;
              customerId: string;
              autoSelected: boolean | null;
            }) => {
              const isAutoSelected = enrichedScopeInfo.customers
                ? enrichedScopeInfo.customers.length === 1
                : enrichedScopeInfo.autoSelected;

              return fromScopeActions.getScope({
                customerId: enrichedScopeInfo.customerId,
                autoSelected: isAutoSelected,
              });
            }
          ),
          catchError(() => of(fromScopeActions.checkScopeSessionFailed()))
        )
      )
    )
  );

  routeToScope$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromScopeActions.getScopeFailed, fromScopeActions.unsetScope),
        switchMap(() =>
          this.customerService.getCustomers({ includeParents: true }).pipe(
            tap(({ data }) => {
              this.scopeService.clearLocalScope();
              if (data.length === 0) {
                this.router.navigate([CommonRoutes.ABSOLUTE_SCOPE_NOT_FOUND]);
              } else {
                this.router.navigate([CommonRoutes.ABSOLUTE_SCOPE]);
              }
            })
          )
        )
      ),
    { dispatch: false }
  );
}
