import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'ins-card-footer',
  template: `
    <ng-content></ng-content>
  `
})
export class InsCardFooterComponent {
  @HostBinding('class.ins-card__footer')
  componentClass = true;
}
