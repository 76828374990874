<img class="announcement-image" [src]="'./assets/images/announcements/' + data.imageUrl" alt="Announcement image" />

<h2 class="announcement-title">
  {{ 'announcement.' + data.namespace + '.title' | transloco }}
</h2>

<p class="announcement-description">
  {{ 'announcement.' + data.namespace + '.description' | transloco }}
</p>

<button ins-button (click)="dialogRef.close()">
  {{ 'announcement.dismiss' | transloco }}
</button>
