import { NgModule } from '@angular/core';
import { InstantSearchItemComponent } from '@app/shared/components/live-search/instant-search-item.component';
import { InstantSearchComponent } from '@app/shared/components/live-search/instant-search.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { HostedDropdownModule } from '@app/shared/modules/hosted-dropdown/hosted-dropdown.module';
import { ElementObserverModule } from '@app/shared/directives/element-observer/element-observer.module';
import { LoaderModule } from '@app/shared/components/loader/loader.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    PerfectScrollbarModule,
    HostedDropdownModule,
    ElementObserverModule,
    LoaderModule
  ],
  declarations: [InstantSearchItemComponent, InstantSearchComponent],
  exports: [InstantSearchItemComponent, InstantSearchComponent]
})
export class InstantSearchModule {}
