export class InsightsRoutes {
  static readonly INSIGHTS = 'insights';

  static readonly DEEPDIVE = 'deepdive';
  static readonly DEEPLEARNING_TEXT_ANALYSE = 'text-analyses';
  static readonly TOPICS = 'topics';
  static readonly TRAINER = 'trainer';
  static readonly FREEDIVE = 'freedive';
  static readonly BRAND_EXPERIENCE = 'brand-experience';
  static readonly CUSTOMER_EXPERIENCE = 'customer-experience';
  static readonly SERVICE_EXPERIENCE = 'service-experience';
  static readonly USER_EXPERIENCE = 'user-experience';
  static readonly EMPLOYEE_EXPERIENCE = 'employee-experience-experience';
  static readonly OPEN_FEEDBACK = 'open-feedback';
  static readonly JOURNEY_MAP = 'journey';
  static readonly ONBOARDING_FLOW = 'onboarding-flow';
  static readonly DRILLDOWN = 'drilldown';

  static readonly SERVICE_JOURNEY = 'journey';
  static readonly SERVICE_SETUP_ROADMAP = 'setup-roadmap';
  static readonly SERVICE_PRIORITY = 'priority';
  static readonly COMPARISON_TOOL = 'comparison';
  static readonly PHASES_DEEPDIVE = 'phases-deepdive';

  static readonly CUSTOMER_PRIORITY = 'priority';
  static readonly BRAND_PRIORITY = 'priority';
  static readonly EMPLOYEE_PRIORITY = 'priority';

  static readonly REPORTING = 'reporting';
  static readonly PDF_SCHEDULER = 'schedule-report';

  static readonly SOCIAL = 'social';
  static readonly NARROWCASTING = 'narrowcasting';
  static readonly SETUP = 'setup';

  static readonly ABSOLUTE_NLP = `/${InsightsRoutes.INSIGHTS}/${InsightsRoutes.DEEPLEARNING_TEXT_ANALYSE}`;
  static readonly ABSOLUTE_NLP_TRAINER = `${InsightsRoutes.ABSOLUTE_NLP}/${InsightsRoutes.TRAINER}`;
  static readonly ABSOLUTE_NLP_DETAILED_REPORT = `${InsightsRoutes.ABSOLUTE_NLP}/${InsightsRoutes.TOPICS}`;
  static readonly ABSOLUTE_NARROWCASTING_LIST = `/${InsightsRoutes.SOCIAL}/${InsightsRoutes.NARROWCASTING}`;
  static readonly ABSOLUTE_NARROWCASTING = `/${InsightsRoutes.INSIGHTS}/${InsightsRoutes.NARROWCASTING}`;
  static readonly ABSOLUTE_SERVICE = `/${InsightsRoutes.INSIGHTS}/${InsightsRoutes.SERVICE_EXPERIENCE}`;
  static readonly ABSOLUTE_SERVICE_JOURNEY = `/${InsightsRoutes.ABSOLUTE_SERVICE}/${InsightsRoutes.SERVICE_JOURNEY}`;
  static readonly ABSOLUTE_SERVICE_PRIORITY = `/${InsightsRoutes.ABSOLUTE_SERVICE}/${InsightsRoutes.SERVICE_JOURNEY}`;
  static readonly ABSOLUTE_SERVICE_FEEDBACK = `/${InsightsRoutes.ABSOLUTE_SERVICE}/${InsightsRoutes.OPEN_FEEDBACK}`;
  static readonly ABSOLUTE_CUSTOMER = `/${InsightsRoutes.INSIGHTS}/${InsightsRoutes.CUSTOMER_EXPERIENCE}`;
  static readonly ABSOLUTE_CUSTOMER_PRIORITY = `/${InsightsRoutes.ABSOLUTE_CUSTOMER}/${InsightsRoutes.SERVICE_PRIORITY}`;
  static readonly ABSOLUTE_CUSTOMER_FEEDBACK = `/${InsightsRoutes.ABSOLUTE_CUSTOMER}/${InsightsRoutes.OPEN_FEEDBACK}`;

  static readonly ABSOLUTE_REPORTING = `/${InsightsRoutes.REPORTING}`;
  static readonly ABSOLUTE_PDF_SCHEDULER = `/${InsightsRoutes.ABSOLUTE_REPORTING}/${InsightsRoutes.PDF_SCHEDULER}`;
}
