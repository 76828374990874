import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromActions from './actions/scope.actions';
import * as fromSelectors from './reducers/scope.reducer';
import { State } from '@app/core/root-store/scope-store/reducers/scope.reducer';
import { IScope } from '@app/shared/models/common/scope.model';
import { filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class ScopeFacade {
  getScope$ = this.store.pipe(select(fromSelectors.getScopeState));
  getProducts$ = this.store.pipe(select(fromSelectors.getProductsState));
  getModelId$ = this.store.pipe(select(fromSelectors.getModelId));
  scopeStoreLoaded$ = this.store.pipe(select(fromSelectors.scopeIsLoaded));
  productsLoaded$ = this.store.pipe(select(fromSelectors.productsLoaded));
  getContactRoles$ = this.store.pipe(select(fromSelectors.getContactRoles));
  isAutoSelected$ = this.store.select(fromSelectors.selectIsAutoSelected);

  // todo: add take(1)
  scopeId$: Observable<string> = this.getScope$.pipe(
    filter(scope => !!scope?.id),
    map(scope => scope.id)
  );

  constructor(private store: Store<State>) {}

  getScope(customerId: string, autoSelected = false): void {
    this.store.dispatch(fromActions.getScope({ customerId, autoSelected }));
  }

  updateScope(scope: IScope): void {
    this.store.dispatch(fromActions.updateScope({ scope }));
  }

  getCustomerProducts(scope: IScope): void {
    this.store.dispatch(fromActions.getActivatedProducts({ scope }));
  }

  unsetScope(): void {
    this.store.dispatch(fromActions.unsetScope());
  }
}
