import { coerceElement } from '@angular/cdk/coercion';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Inject,
  Input,
  NgZone,
  OnInit,
  Optional,
  Output,
  QueryList,
  Self,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { BehaviorSubject, EMPTY, merge, Observable } from 'rxjs';
import { auditTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { itemQueryList$ } from '@app/shared/observables/item-query-list';
import { NOT_FOUND_MESSAGE } from '@app/core/tokens/i18n';
import { IdentityMatcher } from '@app/shared/types/matcher';
import { OptionComponent } from './option/option.component';
import { InsSizeES, InsSizeL, InsSizeM, InsSizeS } from '@app/shared/types/size';
import { DATA_LIST_HOST } from '@app/core/tokens/data-list-host.token';
import { AbstractControl } from '@app/core/bases/control';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OptgroupDirective } from '@app/shared/components/select/optgroup.directive';
import { RelatedItem, SelectItem } from '@app/shared/components/select/select-item.interface';
import { SELECT_REFRESH_LIST_HOOK } from '@app/core/tokens/select-refresh-list-hook.token';
import { resizeObserverStrategy } from '@app/shared/observables/resize-observer';
import { HostedDropdownComponent } from '@app/shared/modules/hosted-dropdown/hosted-dropdown.component';
import { CoerceBooleanProperty } from '@app/core/decorators/coerce-boolean-property';
import { VerticalConnectionPos } from '@angular/cdk/overlay';
import { KeyCodes } from '@app/shared/enums/key-codes.enum';

const defaultFilterOption = (searchValue: string, item: SelectItem<any>): boolean => {
  if (item && item.label) {
    return item.label.toString().toLowerCase().indexOf(searchValue.toLowerCase()) > -1;
  } else {
    return false;
  }
};

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['select.component.scss'],
  host: {
    '(blur)': 'onTouch()',
    tabIndex: '0',
  },
  providers: [
    {
      provide: DATA_LIST_HOST,
      useExisting: forwardRef(() => SelectComponent),
    },
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class SelectComponent extends AbstractControl<any> implements OnInit, AfterContentInit {
  @Input()
  autoselect: boolean;
  @Input()
  enableDefaultSearch = true;
  @Input()
  placeholder: string;
  @Input()
  searchLoading = false;
  @Input()
  viewTemplate: TemplateRef<any>;
  @Input()
  clearButton = true;

  @Input()
  @HostBinding('attr.data-ins-select-size')
  size: InsSizeES | InsSizeS | InsSizeM | InsSizeL = 'm';

  _multi: boolean;
  @Input()
  @CoerceBooleanProperty()
  set multi(value: boolean) {
    if (this._multi !== value) {
      this._fallbackValue = [];
      this._multi = value;
    }
  }

  get multi() {
    return this._multi;
  }

  @Input()
  set value(value) {
    this.updateValue(value);
    this.registerComponentByValue(value);
  }

  private _loading = false;
  @Input()
  set loading(value: boolean) {
    if (this._loading !== value) {
      this._loading = value;
      this.setDisabledState(value);
    }
  }

  get loading(): boolean {
    return this._loading;
  }

  @Input()
  @CoerceBooleanProperty()
  typeSelect: boolean;

  @Input()
  @CoerceBooleanProperty()
  typeCreate: boolean;

  @Input()
  @CoerceBooleanProperty()
  createDisabled: boolean;

  @Output()
  create = new EventEmitter<string>();

  @HostBinding('class.is-disabled')
  get isDisabled(): boolean {
    return this.disabled;
  }

  _textfield: ElementRef;

  @ViewChild('textfield', { read: ElementRef })
  set textField(element: ElementRef) {
    if (element && this.typeSelect) {
      this._textfield = element;
      this._textfield.nativeElement.focus();
    }
  }

  optionViewValueElement?: ElementRef;

  @ViewChild('selectValueWrapper', { read: ElementRef })
  set viewValueElement(e: ElementRef) {
    if (!this.optionViewValueElement && this.multi) {
      this.optionViewValueElement = e;
      this.checkAnswersOutOfBound();
    }
  }

  @ViewChild(HostedDropdownComponent, { static: true })
  hostedDropdown: HostedDropdownComponent;

  @ContentChildren(OptionComponent, { descendants: true })
  options: QueryList<OptionComponent<any>>;
  @ContentChildren(OptgroupDirective, { descendants: true })
  optionGroups: QueryList<OptgroupDirective>;

  get empty$(): Observable<boolean> {
    return itemQueryList$(this.options).pipe(map(({ length }) => !length));
  }

  currentValue: any;
  searchValue = '';
  dropdownPosition: VerticalConnectionPos = 'bottom';
  relatedComponents: RelatedItem<any>[] = [];
  amountOverflowActivated = false;
  itemList$ = new BehaviorSubject<SelectItem<any>[]>([]);
  selectItemList$ = new BehaviorSubject<SelectItem<any>[]>([]);

  focused = false;
  panelOpen = false;

  get empty(): boolean {
    return this.multi ? !this.controlValue?.length : this.controlValue === null || this.controlValue === undefined;
  }

  get optionViewValue(): string {
    if (this.empty) {
      return '';
    }
    return this.relatedComponents.map((option) => option?.label).join(', ');
  }

  @Output()
  readonly valueChange = new EventEmitter<any>();
  @Output()
  readonly searchChange = new EventEmitter<string>();
  @Output()
  readonly onSelect = new EventEmitter<any>();

  private _compareWith: IdentityMatcher<any> = (o1: any, o2: any) => o1 === o2;
  @Input()
  get compareWith() {
    return this._compareWith;
  }

  set compareWith(fn: (o1: any, o2: any) => boolean) {
    if (fn === null) {
      return;
    }
    if (typeof fn !== 'function') {
      throw Error('Select: compareWith is not a function');
    }
    this._compareWith = (o1: any, o2: any): boolean => {
      try {
        return fn(o1, o2);
      } catch (e) {
        return false;
      }
    };
    // A different comparator means the selection could change.
    if (this.options) {
      this.initRelatedComponents();
    }
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    const items = this.itemList$.value.filter((item) => item.type === 'item').filter((item) => !item.disabled);
    const activatedIndex = items.findIndex((item) => this.compareWith(item.value, this.currentValue));

    if (items?.length) {
      switch (event.code) {
        case KeyCodes.ARROW_UP:
          event.preventDefault();
          if (this.panelOpen) {
            const previousIndex = activatedIndex > 0 ? activatedIndex - 1 : items.length - 1;
            this.currentValue = items[previousIndex].value;
          } else {
            this.open();
          }
          break;
        case KeyCodes.ARROW_DOWN:
          event.preventDefault();
          if (this.panelOpen) {
            const nextIndex = activatedIndex < items.length - 1 ? activatedIndex + 1 : 0;
            this.currentValue = items[nextIndex].value;
          } else {
            this.open();
          }
          break;
        case KeyCodes.ENTER:
          event.preventDefault();
          if (this.panelOpen) {
            this.handleOption(this.currentValue);
          } else {
            this.open();
          }
          break;
      }
    }
  }

  constructor(
    private cdRef: ChangeDetectorRef,
    private ngZone: NgZone,
    @Self() @Optional() ngControl: NgControl,
    @Inject(NOT_FOUND_MESSAGE) public emptyContent$: Observable<string>,
    @Optional() @Inject(SELECT_REFRESH_LIST_HOOK) private refreshListHook: Observable<any>
  ) {
    super(ngControl, cdRef);
  }

  ngOnInit(): void {
    this.selectItemList$.pipe(untilDestroyed(this)).subscribe(() => this.updateListOfItems());
    this.writeValueNotifier$.pipe(untilDestroyed(this)).subscribe(() => this.initRelatedComponents());
  }

  onTouch(): void {
    this.updateTouched();
  }

  ngAfterContentInit(): void {
    merge(this.options.changes, this.optionGroups.changes, this.refreshListHook ?? EMPTY)
      .pipe(
        startWith(true),
        switchMap(() =>
          merge(
            this.options.changes,
            this.optionGroups.changes,
            this.refreshListHook ?? EMPTY,
            ...this.options.map((option) => option.changes)
          ).pipe(startWith(true))
        ),
        untilDestroyed(this)
      )
      .subscribe(() => {
        const items = this.options.toArray().map((option, index) => {
          const { value, template, disabled, groupLabel, context, label, actionIcon, action} = option;

          return {
            value,
            label: option.viewValue || label,
            template,
            disabled,
            groupLabel,
            type: 'item',
            context,
            key: value,
            actionIcon,
            action,
          };
        });

        this.selectItemList$.next(items);
        this.initRelatedComponents();
      });

    if (this.autoselect && this.options.length === 1) {
      this.handleOption(this.options.toArray()[0].value);
    }
  }

  updateListOfItems(): void {
    const templateItems = this.selectItemList$.value.filter((item) => {
      if (this.searchValue && this.enableDefaultSearch) {
        return defaultFilterOption(this.searchValue, item);
      } else {
        return true;
      }
    });
    let groupItems = [];
    if (this.optionGroups) {
      groupItems = this.optionGroups.map((group) => group.label);
    }
    groupItems.forEach((groupItemLabel) => {
      const index = templateItems.findIndex((item) => item.groupLabel === groupItemLabel);

      if (index !== -1) {
        const groupItem = { groupLabel: groupItemLabel, type: 'group', key: groupItemLabel } as SelectItem<any>;
        templateItems.splice(index, 0, groupItem);
      }
    });

    this.itemList$.next(templateItems);
    this.updateCdkPosition();
    this.cdRef.markForCheck();
  }

  updateCdkPosition(): void {
    window.requestAnimationFrame(() => {
      this.hostedDropdown.updateCdkConnectedOverlayOrigin();
    });
  }

  close(): void {
    if (this.panelOpen) {
      this.focused = false;
      this.panelOpen = false;
      this.cdRef.markForCheck();
    }
  }

  private checkAnswersOutOfBound(): void {
    if (this.optionViewValueElement) {
      this.overflowChanges(this.optionViewValueElement.nativeElement)
        .pipe(distinctUntilChanged(), untilDestroyed(this))
        .subscribe((isOverflowed) => {
          this.amountOverflowActivated = isOverflowed;
          this.cdRef.markForCheck();
        });
    }
  }

  overflowChanges(host: any): Observable<boolean> {
    const element = coerceElement(host);

    return merge(resizeObserverStrategy(element), this.controlValueChange).pipe(
      auditTime(100),
      map(() => this.isElementOverflow(element))
    );
  }

  isElementOverflow(host: HTMLElement): boolean {
    // Don't access the `offsetWidth` multiple times since it triggers layout updates.
    const hostOffsetWidth = host.offsetWidth;
    return hostOffsetWidth > host.parentElement.offsetWidth || hostOffsetWidth < host.scrollWidth;
  }

  clear(event): void {
    event.stopPropagation();

    if (this.multi) {
      this.updateValue([]);
    } else {
      this.updateValue(null);
    }
    this.relatedComponents = [];
    this.setSelected();
  }

  open(): void {
    if (!this.panelOpen && !this.isDisabled) {
      this.panelOpen = true;
      this.focused = true;
      this.cdRef.markForCheck();
    }
  }

  private setSelected(): void {
    let valueToEmit: any = this.controlValue;
    if (this.multi) {
      valueToEmit = this.controlValue.map((option) => option);
    }

    this.valueChange.emit(valueToEmit);
    this.onSelect.emit(valueToEmit);
    this.cdRef.markForCheck();
  }

  compareValues(optionValue: any, controlValueItem: any): boolean {
    try {
      return controlValueItem != null && this._compareWith(optionValue, controlValueItem);
    } catch (error) {
      console.error('Error in comparator function');
      return false;
    }
  }

  private selectByValue(optionValue: any): void {
    if (optionValue !== null && optionValue !== undefined) {
      if (this.multi) {
        const index = this.controlValue.findIndex((controlValueItem) =>
          this.compareValues(optionValue, controlValueItem)
        );
        this.updateValue(
          index === -1 ? [...this.controlValue, optionValue] : this.controlValue.filter((_, i) => i !== index)
        );
      } else {
        this.updateValue(optionValue);
      }
      this.registerComponentByValue(optionValue);
    }
  }

  initRelatedComponents(): void {
    this.relatedComponents = [];
    if (this.multi) {
      this.controlValue.forEach((value) => this.registerComponentByValue(value));
    } else {
      this.registerComponentByValue(this.controlValue);
    }
  }

  registerComponentByValue(key: string): void {
    const index = this.relatedComponents.findIndex(
      (item) => item !== null && item !== undefined && this.compareValues(item.value, key)
    );

    const relatedOption = this.selectItemList$.value.find((item) => this.compareValues(item.value, key));

    if (this.multi) {
      if (index === -1 && relatedOption) {
        this.updateRelatedComponents([...this.relatedComponents, relatedOption]);
      } else {
        if (!this.controlValue.includes(key)) {
          this.updateRelatedComponents(this.relatedComponents.filter((_, i) => index !== i));
        }
      }
    } else {
      this.relatedComponents = [relatedOption];
      if (!relatedOption) {
        this.relatedComponents = [{
          value: key,
          label: key,
          key,
        }];
      }
    }

    this.cdRef.markForCheck();
  }

  updateRelatedComponents(value: RelatedItem<any>[]): void {
    this.relatedComponents = value;
  }

  handleOption(value: any): void {
    if (!this.disabled) {
      this.selectByValue(value);
      this.setSelected();

      if (!this.multi) {
        this.close();
      }
    }
  }

  createOption(): void {
    const label = this.searchValue;

    if (label.length) {
      const relatedOption = this.selectItemList$.value.find((item) => this.compareValues(item.label, label));
      if (relatedOption) {
        this.handleOption(relatedOption.value);
      } else {
        this.create.next(this.searchValue);
        this.handleOption(this.searchValue);
      }

      this.searchValue = '';
      this.searchChange.next('');
    }
  }

  onSearchInputChange($event: any): void {
    const value: string = $event.target.value;
    this.searchChange.next(value);
    this.searchValue = value;
    this.updateListOfItems();
  }

  updatePosition($event: VerticalConnectionPos): void {
    this.dropdownPosition = $event;
  }
}
