import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { enGbLocale, nlLocale } from 'ngx-bootstrap/locale';
import { ButtonModule } from '../button/button.module';
import { SelectModule } from '../select/select.module';
import { JoinDatesPipe } from './core/pipes/join-dates.pipe';
import { LocalizedDateRangePipe } from './core/pipes/localized-date-range.pipe';
import { DatepickerComponent } from './datepicker.component';

defineLocale('nl-nl', nlLocale);
defineLocale('en-gb', enGbLocale);

@NgModule({
  imports: [
    CommonModule,
    BsDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    OverlayModule,
    TranslocoModule,
    ButtonModule,
    SelectModule
  ],
  exports: [DatepickerComponent],
  declarations: [DatepickerComponent, LocalizedDateRangePipe, JoinDatesPipe],
  providers: []
})
export class DatepickerModule {}
