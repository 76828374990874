import { Injectable } from '@angular/core';

@Injectable()
export class NavigationService {
  private readonly REDIRECT_URL = 'redirectUrl';

  getRedirectUrl(): string {
    return sessionStorage.getItem(this.REDIRECT_URL);
  }

  setRedirectUrl(redirectUrl: string): void {
    sessionStorage.setItem(this.REDIRECT_URL, redirectUrl);
  }

  removeItems(): void {
    sessionStorage.removeItem(this.REDIRECT_URL);
  }
}
