import cronParser from 'cron-parser';
import { Pipe, PipeTransform } from '@angular/core';

type dateTypes = 'hours' | 'days' | 'weeks' | 'months';
enum DateTypeEnum {
  hours = 'hours'
}

@Pipe({
  name: 'cronParser'
})
export class CronParserPipe implements PipeTransform {
  transform(value: string, dateType: dateTypes): string {
    const interval = cronParser.parseExpression(value);

    if (dateType) {
      switch (dateType) {
        case DateTypeEnum.hours:
          return interval.fields.hour[0] < 10
            ? `0${interval.fields.hour[0].toString()}:00`
            : `${interval.fields.hour[0].toString()}:00`;
      }
    } else {
      return interval.next().toString();
    }
  }
}
