import { Directive, Input, OnInit, Optional, TemplateRef, ViewContainerRef } from '@angular/core';

class InsAliasContext<T> {
  public insAlias: T = null;
  public $implicit: T = null;
}

@Directive({
  selector: '[insAlias]'
})
export class InsAliasDirective<T = unknown> implements OnInit {
  private context = new InsAliasContext();

  constructor(
    private readonly viewContainer: ViewContainerRef,
    @Optional() private readonly templateRef: TemplateRef<unknown>
  ) {
    if (!templateRef) {
      throw new Error('[insAlias] can only be used as a structural directive or on an ng-template.');
    }
  }

  ngOnInit(): void {
    this.viewContainer.createEmbeddedView(this.templateRef, this.context);
  }

  @Input()
  set insAlias(value: T) {
    this.context.$implicit = this.context.insAlias = value;
  }

  static ngTemplateContextGuard(dir: InsAliasDirective, ctx: unknown): ctx is InsAliasDirective {
    return true;
  }
}
