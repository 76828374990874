import { ChangeDetectionStrategy, Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { ProfileFacade } from '@app/core/root-store/profile-store/profile.facade';
import { ScopeFacade } from '@app/core/root-store/scope-store/scope.facade';
import { AuthFacade } from 'ins-auth';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, take } from 'rxjs/operators';
import { SCOPE_ID } from '@app/core/providers/current-scope-id.provider';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { TranslocoService } from '@ngneat/transloco';

declare let surveyLoader: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  userData$ = combineLatest([
    this.scopeFacade.getScope$,
    this.profileFacade.getUser$,
    this.profileFacade.getIsAdmin$,
  ]).pipe(distinctUntilChanged());
  isAutoSelected$: Observable<boolean>;

  private get pathUrl(): string {
    let travel = this.router.routerState.root;
    while (travel.firstChild !== null) {
      travel = travel.firstChild;
    }
    return travel.pathFromRoot
      .filter((route) => route.routeConfig?.path)
      .map((route) => route.routeConfig?.path)
      .reduce((fullPath, path) => (fullPath += '/' + path));
  }

  constructor(
    private authFacade: AuthFacade,
    private profileFacade: ProfileFacade,
    private scopeFacade: ScopeFacade,
    private renderer: Renderer2,
    private router: Router,
    private translocoService: TranslocoService,
    @Inject(SCOPE_ID) public scopeId$: Observable<string>
  ) {}

  ngOnInit(): void {
    this.isAutoSelected$ = this.scopeFacade.isAutoSelected$;
    this.setUpSurveyLoader();
  }

  logOut(): void {
    this.authFacade.logOut();
  }

  changeLanguage(event: { locale: string; user_id: string }): void {
    this.profileFacade.changeLanguage(event);
  }

  private setUpSurveyLoader(): void {
    const script = this.renderer.createElement('script');
    script.src = environment.script_generator.version;
    script.integrity = environment.script_generator.integrity;
    script.async = 'true';
    script.crossOrigin = 'anonymous';

    script.addEventListener('load', () => {
      surveyLoader.init({
        surveyId: '4381c6d1-27a2-11ee-ab96-068a4dd4d266',
        trigger: ['manually'],
        tracking: false,
        triggerConfig: {
          visible: false,
        },
        customBtnSelector: '#surveyLoader',
        metadata: {},
      });
    });
    this.renderer.appendChild(document.head, script);
  }

  leaveFeedback() {
    const metadata: any = {
      page: this.pathUrl,
      lang: this.translocoService.getActiveLang(),
    };

    this.scopeFacade.getScope$.pipe(take(1)).subscribe((data) => {
      metadata.customerId = data.id;
      metadata.customerLabel = data.label;
    });

    this.profileFacade.getUser$.pipe(take(1)).subscribe((data) => {
      metadata.userId = data.id;
      metadata.voornaam = data.firstName;
      metadata.achternaam = data.lastName;
    });

    surveyLoader.setMetadata(metadata);
  }
}
