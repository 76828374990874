import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'ins-card-description',
  template: `
    <ng-content></ng-content>
  `
})
export class InsCardDescriptionComponent {
  @HostBinding('class')
  componentClass = 'ins-card__head-description';
}
