import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'ins-button',
  template: `
    <button
      [disabled]="disabled"
      class="btn ins-btn btn-{{ styling }}"
      [class.is-active]="isActive"
      [class.btn-circle]="shape === 'circle'"
      (click)="onBtnClick()"
    >
      <i *ngIf="icon" class="la la-{{ icon }} btn-primary__icon"></i>
      <ng-content></ng-content>
      <span
        *ngIf="loading"
        class="spinner-border spinner-border-sm btn__spinner"
        role="status"
        aria-hidden="true"
      ></span>
    </button>
  `,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['button.component.scss']
})
export class ButtonComponent implements OnInit {
  private _isActive: boolean;
  @Input()
  set isActive(value) {
    if (this._isActive !== value) {
      this._isActive = value;
      this.cd.markForCheck();
    }
  }
  get isActive() {
    return this._isActive;
  }

  private _shape: 'circle';
  @Input()
  set shape(value: 'circle') {
    this._shape = value;
    this.cd.markForCheck();
  }
  get shape() {
    return this._shape;
  }

  private _loading = false;
  @Input()
  get loading(): boolean {
    return this._loading;
  }

  set loading(value: boolean) {
    if (value !== this._loading) {
      this._loading = value;
      this._disabled = value;
      this.cd.markForCheck();
    }
  }

  private _disabled = false;
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    const newValue = coerceBooleanProperty(value);
    if (newValue !== this._disabled) {
      this._disabled = newValue;
      this.cd.markForCheck();
    }
  }

  private _icon: string;
  @Input()
  get icon(): string {
    return this._icon;
  }

  set icon(iconClass: string) {
    if (this._icon !== iconClass) {
      this._icon = iconClass;
      this.cd.markForCheck();
    }
  }

  private _style = 'primary';
  @Input()
  get styling(): string {
    return this._style;
  }

  set styling(styleClass: string) {
    if (this._style !== styleClass) {
      this._style = styleClass;
      this.cd.markForCheck();
    }
  }

  @Output()
  onClick = new EventEmitter<void>();

  constructor(private cd: ChangeDetectorRef) {}

  onBtnClick() {
    if (!this._disabled) {
      this.onClick.emit();
    }
  }

  ngOnInit() {}
}
