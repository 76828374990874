import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  RouterStateSnapshot,
  UrlSegment
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { AuthFacade } from '../+state/auth.facade';
import { LocalStorageLoggedInService } from '../services/local-storage-logged-in.service';
import { NavigationService } from '../services/navigation.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private authFacade: AuthFacade, private localStorageIsLoggedIn: LocalStorageLoggedInService) {}

  checkStoreAuthentication(): Observable<boolean> {
    return this.authFacade.isLoggedIn$;
  }

  checkApiAuthentication(): Observable<boolean> {
    return this.localStorageIsLoggedIn.getItem();
  }

  isAuthenticated(url?: string): Observable<boolean> {
    return this.checkStoreAuthentication().pipe(
      mergeMap(storeAuth => {
        if (storeAuth) {
          return of(true);
        }
        return this.checkApiAuthentication();
      }),
      map(storeOrApiAuth => {
        if (!storeOrApiAuth) {
          this.authFacade.redirectToLogin({ url });
          return false;
        }
        return true;
      }),
      take(1)
    );
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.isAuthenticated(state?.url);
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this.isAuthenticated();
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(childRoute, state);
  }
}
