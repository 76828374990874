import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HostedDropdownComponent } from './hosted-dropdown.component';
import { OverlayModule } from '@angular/cdk/overlay';

@NgModule({
  declarations: [HostedDropdownComponent],
  exports: [HostedDropdownComponent],
  imports: [CommonModule, OverlayModule]
})
export class HostedDropdownModule {}
