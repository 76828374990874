import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-option-group-item',
  templateUrl: './option-group-item.component.html',
  styleUrls: ['./option-group-item.component.scss']
})
export class OptionGroupItemComponent {
  // todo: add select logic
}
