import * as fromRouter from '@ngrx/router-store';
import { createFeatureSelector } from '@ngrx/store';

export const routerFeatureKey = 'router';

export interface State {
  [routerFeatureKey]: fromRouter.RouterReducerState;
}

export const selectRouter = createFeatureSelector< fromRouter.RouterReducerState<any>>('router');

export const {
  selectQueryParams,
  selectQueryParam,
  selectRouteParams,
  selectRouteParam,
  selectRouteData,
  selectUrl
} = fromRouter.getSelectors(selectRouter);
