import { NgModule } from '@angular/core';

import { EditorComponent } from './editor.component';
import { CommonModule } from '@angular/common';
import { QuillModule } from 'ngx-quill';
import { ReactiveFormsModule } from '@angular/forms';

const quillConfig = {
  modules: {
    blotFormatter: {},
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      ['blockquote'],
      [{ header: 1 }, { header: 2 }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      ['clean'],
      ['link', 'image']
    ]
  }
};

@NgModule({
  imports: [CommonModule, QuillModule.forRoot(quillConfig), ReactiveFormsModule],
  exports: [EditorComponent],
  declarations: [EditorComponent],
  providers: []
})
export class EditorModule {}
