<div class="content">
  <ng-container *ngTemplateOutlet="content || iconTemplate; context: { $implicit: icon }"></ng-container>
  <span #spanElement><ng-content></ng-content></span>
  <i *ngIf="iconAfter" class="la la-{{ iconAfter }} btn-primary__icon-right"></i>
  <span *ngIf="loading" class="spinner-border spinner-border-sm btn__spinner" role="status" aria-hidden="true"></span>
</div>

<ng-template #iconTemplate let-value>
  <i *ngIf="value && !loading" class="la la-{{ value }} btn-primary__icon"></i>
</ng-template>
