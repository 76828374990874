import { ModuleWithProviders, NgModule } from '@angular/core';
import { UtilsConfigService } from './services/config.service';
import { UtilsConfig } from './models/models';
import { StaticInjectorService } from './services/injector/static-injector.service';

@NgModule()
export class UtilsModule {

  constructor(private staticInjector: StaticInjectorService) {
  }

  static forRoot(config: any): ModuleWithProviders<UtilsModule> {

    return {
      ngModule: UtilsModule,
      providers: [
        { provide: UtilsConfigService, useValue: config }
      ]
    };

  }

}
