import { nanoid } from 'nanoid';
import { Params } from '@angular/router';

export class Utils {
  static copy<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj));
  }

  static shortId(length: number = 6): string {
    return nanoid(length);
  }

  static isEmpty(obj: object): boolean {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  static isDefined(prop: any): boolean {
    return !(typeof prop === 'undefined' || prop === null);
  }

  static convertCsv(content: string): { [key: string]: string }[] {
    const rows: string[] = content.split('\n');
    const attributes: string[] = rows[0].split(/[,;]/gm);
    const dataArray: { [key: string]: string }[] = [];
    for (let i = 1; i < rows.length; i++) {
      const data = {};
      const rowCells: string[] = rows[i].split(/[,;]/gm);
      for (let j = 0; j < rowCells.length; j++) {
        const key = attributes[j]?.replace(/"/g, '')?.trim();
        const value = rowCells[j]?.replace(/"/g, '')?.trim();
        if (key) {
          data[key] = value;
        }
      }
      dataArray.push(data);
    }
    return dataArray;
  }

  static convertExcel(rows: any[][]): { [key: string]: string }[] {
    const attributes: any[] = rows[0];
    const dataArray: { [key: string]: string }[] = [];
    for (let i = 1; i < rows.length; i++) {
      const data = {};
      const rowCells: string[] = rows[i];
      for (let j = 0; j < rowCells.length; j++) {
        const key = attributes[j]?.toString()?.replace(/"/g, '')?.trim();
        const value = rowCells[j]?.toString()?.replace(/"/g, '')?.trim();
        if (key) {
          data[key] = value;
        }
      }
      dataArray.push(data);
    }
    return dataArray;
  }

  static getFileExtension(fileName: string): string {
    const fileNameArray = fileName.split('.');
    const fileExtension = fileNameArray[fileNameArray.length - 1];
    return fileExtension ? fileExtension : '';
  }

  static groupBy<T>(list: T[], keyGetter: (value: T) => string): { [key: string]: T[] } {
    const map: { [key: string]: T[] } = {};
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map[key];
      if (!collection) {
        map[key] = [item];
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  static indexOf(collection: any, node: any): number {
    return Array.prototype.indexOf.call(collection, node);
  }

  static calculatePercentage(value: number, total: number, precision: number = 0): number {
    let percents = (value / total) * 100;

    if (isNaN(percents) || percents === Infinity) {
      percents = 0;
    }

    if (precision && precision >= 0) {
      percents = Number(percents.toFixed(precision));
    }

    return percents;
  }

  static removeEmptyProps(obj: { [key: string]: any }): { [key: string]: any } {
    return Object.fromEntries(
      Object.entries(obj)
        .filter(([_, v]) => v != null || !!v)
        .map(([key, value]) => [key, value === Object ? Utils.removeEmptyProps(value) : value])
    );
  }

  // todo: make a better function for this, probably doesnt cover all the bases
  static isUUID(value: string): boolean {
    const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return regex.test(value);
  }

  static randomColor(): string {
    return Math.floor(Math.random() * 16777215).toString(16);
  }

  static isObject(o: any): boolean {
    return !!o && typeof o === 'object';
  }

  static objectDifference<T = object>(curr: T, prev: T): T {
    if (!prev || !this.isObject(prev)) {
      return curr;
    }
    const diffs: T = {} as T;

    Object.keys(curr).forEach((property) => {
      if (curr[property] !== prev[property]) {
        diffs[property] = curr[property];
      }
    });

    return diffs;
  }

  static isFunction(func: any): func is Function {
    return typeof func === 'function';
  }

  static isPresent<T>(value?: T | null): value is T {
    return value !== null && value !== undefined;
  }

  static b64_to_utf8(str = '') {
    return decodeURIComponent(escape(window.atob(str)));
  }

  static parseQueryStringToObject(queryString: string): Record<string, string | number | any[]> {
    // parse query string
    const params = new URLSearchParams(queryString) as any;

    const obj = {};

    // iterate over all keys
    for (const key of params.keys()) {
      if (params.getAll(key).length > 1) {
        obj[key] = params.getAll(key);
      } else {
        obj[key] = params.get(key);
      }
    }

    return obj;
  }

  static parseObjectToQueryParams(params: Params): string {
    if (!params) {
      return '';
    }
    return new URLSearchParams(params).toString();
  }

  static capitalizeFirstLetter(str: string): string {
    if (typeof str === 'string') {
      return str.charAt(0).toUpperCase() + str.slice(1);
    } else {
      return;
    }
  }

  static fileListToArray(files: FileList): File[] {
    const fileArray: File[] = [];
    for (let i = 0; i < files.length; i++) {
      fileArray.push(files.item(i));
    }

    return fileArray;
  }

  static navigateExternally(url: string): void {
    window.open(url, '_blank');
  }

  static removeOne<T = any>(arr: T[], element: T): T[] {
    const index = arr.indexOf(element);
    return arr.filter((_, i) => i !== index);
  }
}
