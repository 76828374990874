export enum Ranges {
  CUSTOM = 'custom',
  ALL_TIME = 'all-time',
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  THIS_WEEK = 'this-week',
  LAST_WEEK = 'last-week',
  THIS_MONTH = 'this-month',
  LAST_MONTH = 'last-month',
  THIS_YEAR = 'this-year',
  LAST_YEAR = 'last-year',
}

export enum DateIndex {
  FROM,
  TO,
}
