import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Customer } from '@app/shared/models/customer/customer.model';
import { take } from 'rxjs/operators';
import { IMeta } from '@app/shared/models/accounts/user.model';
import { ICustomerLogs } from '@app/shared/models/customer/customer-logs.model';
import { queryPaginated } from '@app/core/services/helpers/queryPaginated';
import { InvitesConfiguration } from '@app/shared/models/customer/invites-configuration.model';
import { CustomerBilling } from '@app/shared/models/customer/customer-billing.model';
import { CustomerContactInfo } from '@app/shared/models/customer/customer-contact-info.model';
import { EnvironmentActivity } from '@app/shared/models/customer/customer-environment-activity.model';
import { ModulesActivity } from '@app/shared/models/customer/customer-modules-activity.model';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  private readonly apiUrl = environment.api;

  constructor(private http: HttpClient) {}

  getCustomers(params?: any): Observable<{ data: Customer[]; meta: IMeta }> {
    return this.http
      .get<{ data: Customer[]; meta: IMeta }>(`${this.apiUrl}/customers`, {
        headers: this.headers,
        params: this.toHttpParams(params),
      })
      .pipe(take(1));
  }

  getCustomersQuery(params?: any): Observable<{ data: Customer[]; meta: IMeta }> {
    return queryPaginated<{ data: Customer[]; meta: IMeta }>(this.http, `${this.apiUrl}/customers`, params).pipe(
      take(1)
    );
  }

  getCustomerChildren(parentId: string, params?: any): Observable<{ data: Customer[]; meta: IMeta }> {
    return this.http
      .get<{ data: Customer[]; meta: IMeta }>(`${this.apiUrl}/customers/${parentId}/customers`, {
        headers: this.headers,
        params: this.toHttpParams(params),
      })
      .pipe(take(1));
  }

  getCustomer(customerId: string): Observable<Customer> {
    return this.http.get<Customer>(this.apiUrl + '/customers/' + customerId).pipe(take(1));
  }

  createCustomer(customer: Customer): Observable<Customer> {
    return this.http.post<Customer>(`${this.apiUrl}/customers`, customer);
  }

  getCustomerLogs(customerId: string): Observable<ICustomerLogs> {
    return this.http.get<ICustomerLogs>(`${this.apiUrl}/customers/${customerId}/logs`);
  }

  getCustomerBilling(customerId: string): Observable<CustomerBilling> {
    return this.http.get<CustomerBilling>(`${this.apiUrl}/customers/${customerId}/billingDetails`);
  }

  updateCustomerBilling(customerId: string, payload: Partial<CustomerBilling>): Observable<CustomerBilling> {
    return this.http.put<CustomerBilling>(`${this.apiUrl}/customers/${customerId}/billingDetails`, payload);
  }

  getCustomerInfo(customerId: string): Observable<CustomerContactInfo> {
    return this.http.get<CustomerContactInfo>(`${this.apiUrl}/customers/${customerId}/contactInfo`);
  }

  updateCustomerInfo(customerId: string, payload: Partial<CustomerContactInfo>): Observable<CustomerContactInfo> {
    return this.http.put<CustomerContactInfo>(`${this.apiUrl}/customers/${customerId}/contactInfo`, payload);
  }

  updateCustomer(customerId: string, body: any): Observable<Customer> {
    return this.http.put<Customer>(`${this.apiUrl}/customers/${customerId}`, body);
  }

  deleteCustomer(customerId: string): Observable<any> {
    return this.http.delete<any>(`${this.apiUrl}/customers/${customerId}`);
  }

  putInvitesConfig(customerId: string, payload: InvitesConfiguration): Observable<Customer> {
    return this.http.put<Customer>(`${this.apiUrl}/customers/${customerId}/invites/configuration`, payload);
  }

  getEnvironmentActivity(customerId: string): Observable<EnvironmentActivity> {
    return this.http.get<EnvironmentActivity>(`${this.apiUrl}/customers/${customerId}/environmentActivity`);
  }

  getModulesActivity(customerId: string): Observable<ModulesActivity> {
    return this.http.get<ModulesActivity>(`${this.apiUrl}/customers/${customerId}/modulesActivity`);
  }

  get headers(): HttpHeaders {
    const headersConfig = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };

    return new HttpHeaders(headersConfig);
  }

  private toHttpParams(params) {
    if (params) {
      const validKeys = Object.keys(params).filter((key) => !!params[key]);
      const filteredParams = {};
      validKeys.forEach((key) => {
        filteredParams[key] = params[key];
      });
      return new HttpParams({ fromObject: filteredParams });
    }

    return {};
  }
}
