import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-score-slider',
  templateUrl: './score-slider.component.html',
  styleUrls: ['./score-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ScoreSliderComponent), multi: true }]
})
export class ScoreSliderComponent implements ControlValueAccessor {
  @Input()
  min: number;
  @Input()
  max: number;

  private _value = [];

  onChange = (value: number[]) => {};
  onTouch = (value: number[]) => {};

  set value(value: number[]) {
    this._value = value;
    this.onChange(value);
    this.onTouch(value);
  }

  get value(): number[] {
    return this._value;
  }

  writeValue(value: number[]): void {
    this.value = value;
  }

  registerOnChange(fn: (value: number[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  setScore(scoreType: 'min' | 'max', value: number): void {
    const [minScore, maxScore] = this.value as number[];
    if (scoreType === 'min') {
      this.value = [value, maxScore];
    }
    if (scoreType === 'max') {
      this.value = [minScore, value];
    }
  }
}
