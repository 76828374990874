import { createReducer, on, Action } from '@ngrx/store';

import * as AuthActions from './auth.actions';
import { ITokenModel } from './auth.models';

export interface AuthState {
  loggedIn: boolean;
  token: ITokenModel;
  loaded: boolean;
  error: any;
}

export const AUTH_FEATURE_KEY = 'auth';

export interface AuthPartialState {
  readonly [AUTH_FEATURE_KEY]: AuthState;
}

export const initialState: AuthState = {
  loggedIn: false,
  token: null,
  loaded: false,
  error: null
};

const authReducer = createReducer(
  initialState,
  on(AuthActions.getToken, state => ({
    ...state,
    loaded: false,
    error: null
  })),
  on(
    AuthActions.getTokenSuccess,
    AuthActions.tokenRenewalSuccess,
    AuthActions.checkSessionSuccess,
    (state, { token }) => ({
      ...state,
      loggedIn: true,
      loaded: true,
      token
    })
  ),
  on(AuthActions.getTokenFailure, (state, { error }) => ({
    ...state,
    error
  }))
);

export function reducer(state: AuthState | undefined, action: Action) {
  return authReducer(state, action);
}
