import { CommonRoutes } from './common-routes.constant';

export class InteractionsRoutes {
  static readonly SURVEYS = 'surveys';
  static readonly TRANSLATIONS = 'translations';
  static readonly MAIN_SURVEY = 'environments';

  static readonly INVITES_CHANNELS = 'invites-channels';
  static readonly REVIEW = 'review';

  static readonly RULING = 'ruling';
  static readonly RESPONSE_RULING = 'response-rules';
  static readonly ABSOLUTE_RESPONSE_RULING = `/${InteractionsRoutes.RESPONSE_RULING}`;

  static readonly ABSOLUTE_SURVEYS = `/${InteractionsRoutes.SURVEYS}`;
  static readonly ABSOLUTE_SURVEYS_EDIT = `${InteractionsRoutes.ABSOLUTE_SURVEYS}/${CommonRoutes.BUILDER}/${CommonRoutes.EDIT}`;
  static readonly ABSOLUTE_INVITES_CHANNELS = `/${InteractionsRoutes.INVITES_CHANNELS}`;
  static readonly ABSOLUTE_INVITES_CHANNELS_REVIEW = `${InteractionsRoutes.ABSOLUTE_INVITES_CHANNELS}/${InteractionsRoutes.REVIEW}`;
}
