import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { selectLoaded } from '@app/core/root-store/selectors';
import * as fromRoot from './root.reducer';

@Injectable({
  providedIn: 'root'
})
export class RootFacade {
  appIsLoaded$ = this.store.pipe(select(selectLoaded));
  selectUrl$ = this.store.pipe(select(fromRoot.selectUrl));
  selectRouteData$ = this.store.pipe(select(fromRoot.selectRouteData));
  selectRouteParams$ = this.store.pipe(select(fromRoot.selectRouteParams));
  selectQueryParams$ = this.store.pipe(select(fromRoot.selectQueryParams));

  constructor(private store: Store<fromRoot.State>) {}
}
