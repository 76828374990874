import { NgModule } from '@angular/core';

import { FlexTableComponent } from './flex-table.component';
import { FlexTableItemComponent } from '@app/shared/components/flex-table/flex-table-item.component';
import { CommonModule } from '@angular/common';
import { FlexTableHeaderComponent } from '@app/shared/components/flex-table/flex-table-header.component';
import { FlexTableHeaderItemComponent } from '@app/shared/components/flex-table/flex-table-header-item.component';
import { FlexTableRowComponent } from '@app/shared/components/flex-table/flex-table-row.component';
import { FlexTableFooterComponent } from '@app/shared/components/flex-table/flex-table-footer.component';
import { SortDirective } from './directives/sort.directive';
import { SortHeaderDirective } from './directives/sort-header.directive';
import { AliasModule } from '@app/shared/directives/alias/alias.module';

@NgModule({
  imports: [CommonModule, AliasModule],
  exports: [
    FlexTableComponent,
    FlexTableItemComponent,
    FlexTableHeaderComponent,
    FlexTableHeaderItemComponent,
    FlexTableRowComponent,
    FlexTableFooterComponent,
    SortHeaderDirective,
    SortDirective
  ],
  declarations: [
    FlexTableComponent,
    FlexTableItemComponent,
    FlexTableHeaderComponent,
    FlexTableHeaderItemComponent,
    FlexTableRowComponent,
    FlexTableFooterComponent,
    SortDirective,
    SortHeaderDirective
  ],
  providers: []
})
export class FlexTableModule {}
