import { NgModule } from '@angular/core';

import { PageHeaderComponent } from './page-header.component';
import { InsPageHeaderBreadcrumbDirective } from './directives/page-header-breadcrumb.directive';
import { CommonModule } from '@angular/common';
import { PageHeaderExtraDirective } from '@app/shared/components/page-header/directives/page-header-extra.directive';

@NgModule({
  imports: [CommonModule],
  exports: [PageHeaderComponent, InsPageHeaderBreadcrumbDirective, PageHeaderExtraDirective],
  declarations: [PageHeaderComponent, InsPageHeaderBreadcrumbDirective, PageHeaderExtraDirective],
  providers: []
})
export class PageHeaderModule {}
